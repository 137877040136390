import React, {useRef,useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyAlert from '../../Components/MyAlert';
import MyAlertSelServizio from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import {GetTextRischioAteco,GetToken,VerifyPDF,GetRole,GetTextZona,ccyFormat,GetTitServizi} from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import imgSearch from "../../img/search.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyConfirmDelServizio from '../../Components/MyConfirm';
import MyConfirmOnSubmit from '../../Components/MyConfirm';
import SignCanvas from 'react-signature-canvas'
import {Ruolo_Admin } from '../../Modules/Costanti';
import MyAutoComplete from "../../Components/MyAutoComplete";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
    
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));
function CreateSign(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Signed, setSigned] = React.useState(false);
    const mySign=useRef({});

    const onConfirm = () => {
        props.handleConfirm(mySign.current.getTrimmedCanvas().toDataURL("image/png"));
    }
    
    const onClear = () => {
        setSigned(false);
        mySign.current.clear();
    }

    const onSign = () => {
        setSigned(true);
    }
    
  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogContent className={classes.FormPopup} >
            <SignCanvas ref={mySign}  canvasProps={{className: 'signCanvas'}} onEnd={onSign} />
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={!Signed} readOnly={!Signed}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block btn btn-secondary">
                    Annulla
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button  onClick={onClear} className="btn-mbg1 btn-block" disabled={!Signed} readOnly={!Signed}>
                    Cancella 
                </Button>
            </div>            
        </DialogActions>
      </Dialog>
  );
}
function ElencaServizioContratto(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicTitolo, setRicTitolo] = React.useState("");
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);   
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);
    
    
//alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {  
        setRicDescrizione(event.target.value);  
        setDisableFilter(false);
    };

    const handleRicTitolo = (event) => {  
        setRicTitolo(event.target.value);  
        setDisableFilter(false);
    };

    const handleRowClick = (_id,_titolo,_descrizione,_servizioContratto_id) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { Elenco_id: 0, id: _id, Titolo: _titolo, Descrizione: _descrizione, Prezzo: 0, Quantita: 1, Sconto: 0 , Servizio_Contratto_id: _servizioContratto_id},
        ];
        setSelectedData(ArrayRis);
      };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    }; 

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
    
    React.useEffect(() => {
        setSelected("");
    }, [props.open]);
    useEffect(() => {    
        
        if (isSending && props.rischio!=="" && props.zona!==""){
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'servizicontrattolisttoassignordine'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);
                    fd.append("Rischio", props.rischio);
                    fd.append("RischioIndifferente", 1);
                    fd.append("Titolo", RicTitolo);
                    fd.append("Zona", props.zona);
                    
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })(); 
    }
        
}, [isSending,props.zona,props.rischio]); 

    
    const isSelected = (name) => selected===name;

  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
            <div className="small" style={{width:"98%"}}>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicTitolo">Titolo</Label>
                            <Input type="select" className="search" name="selRicTitolo" 
                            id="selRicTitolo" placeholder="" bsSize="sm"
                                value={RicTitolo} onChange={handleRicTitolo}>
                                    <option key="" value="">Tutti</option>
                                    {GetTitServizi().map((item) => (
                                    <option key={item.titolo_value} value={item.titolo_value}>{item.titolo_name}</option>
                                ))}
                            </Input>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>
                    
                    <Col xs={6}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                            <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                            value={RicDescrizione} onChange={handleRicDescrizione}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col> 
                    <Col xs={2}>
                        <FormGroup>
                            <Label bsSize="sm">&nbsp;</Label>
                            <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                    
                </Row>
            </div>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
                {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Titolo</TableCell>
                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                <TableCell className="grid-head" align="center">Qta</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { data.length>0? (
                                data.map((row) => {
                                    const isItemSelected = isSelected(row.Servizio_id);
                            
                                    return (
                                        
                                    <TableRow key={row.Servizio_id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.Servizio_id,row.Titolo,row.Descrizione,row.id)}>
                                        <TableCell>{row.Titolo}</TableCell>
                                        <TableCell>{row.Descrizione}</TableCell>
                                        <TableCell>{row.Quantita}</TableCell>
                                    </TableRow>
                                    );
                                })
                            ):(
                                <TableRow>
                                    <TableCell colSpan={2} style={{textAlign:"center"}}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}
  function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicTitolo, setRicTitolo] = React.useState("");
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);   
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);
    
    
//alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {  
        setRicDescrizione(event.target.value);  
        setDisableFilter(false);
    };

    const handleRicTitolo = (event) => {  
        setRicTitolo(event.target.value);  
        setDisableFilter(false);
    };

    const handleRowClick = (_id,_titolo,_descrizione,_prezzo) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { Elenco_id: 0,id: _id, Titolo: _titolo, Descrizione: _descrizione, Prezzo: _prezzo, Quantita: 1, Sconto: 0, Servizio_Contratto_id: 0},
        ];
        setSelectedData(ArrayRis);
      };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    }; 

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
    
    React.useEffect(() => {
        setSelected("");
    }, [props.open]);
    useEffect(() => {    
        
        if (isSending && props.rischio!=="" && props.zona!==""){
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'listinolist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);
                    fd.append("Rischio", props.rischio);
                    fd.append("RischioIndifferente", 1);
                    fd.append("Titolo", RicTitolo);
                    fd.append("Zona", props.zona);
                    
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })(); 
    }
        
}, [isSending,props.zona,props.rischio]); 

    
    const isSelected = (name) => selected===name;

  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
            <div className="small" style={{width:"98%"}}>
                <Row>
                    <Col xs={4}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicTitolo">Titolo</Label>
                            <Input type="select" className="search" name="selRicTitolo" 
                            id="selRicTitolo" placeholder="" bsSize="sm"
                                value={RicTitolo} onChange={handleRicTitolo}>
                                    <option key="" value="">Tutti</option>
                                    {GetTitServizi().map((item) => (
                                    <option key={item.titolo_value} value={item.titolo_value}>{item.titolo_name}</option>
                                ))}
                            </Input>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>
                    
                    <Col xs={6}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                            <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                            value={RicDescrizione} onChange={handleRicDescrizione}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col> 
                    <Col xs={2}>
                        <FormGroup>
                            <Label bsSize="sm">&nbsp;</Label>
                            <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                    
                </Row>
            </div>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
                {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Titolo</TableCell>
                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                <TableCell className="grid-head" align="center">Prezzo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { data.length>0? (
                                data.map((row) => {
                                    const isItemSelected = isSelected(row.Servizio_id);
                            
                                    return (
                                        
                                    <TableRow key={row.Servizio_id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.Servizio_id,row.Titolo,row.Descrizione,row.Prezzo)}>
                                        <TableCell>{row.Titolo}</TableCell>
                                        <TableCell>{row.Descrizione}</TableCell>
                                        <TableCell align="right">{row.Prezzo}</TableCell>
                                    </TableRow>
                                    );
                                })
                            ):(
                                <TableRow>
                                    <TableCell colSpan={3} style={{textAlign:"center"}}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}

function CreateTableServizi(props) {
    const classes = useStyles();
    const[Rows, setRows] = React.useState([]);
    const [PercIva, setPercIva] = React.useState(0);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoIva, setCostoIva] = React.useState(0);
    const [ApplicaSconto, setApplicaSconto] = React.useState(false);
    const [VisualizzaCheckSconto, setVisualizzaCheckSconto] = React.useState(false);
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };

    const handleClickAltro  = () =>{
        setVisualizzaCheckSconto(!VisualizzaCheckSconto);
    }
    const handleApplicaSconto= (event) =>{
        //resetta lo sconto a tutte le righr
        if (ApplicaSconto){
            if (props.rows.length>0){
                props.rows.map((item) => {
                    item.Sconto=0;
                    props.onChangeSconto();
                    return true;
                });
            }
        }
        setApplicaSconto(!ApplicaSconto);
        CalcolaPrezzi(PercIva);
    };
    const handlePercSconto= (_sconto,_id) =>{

        if (parseFloat(_sconto)>100){
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
        }
        
        if (props.rows.length>0){
            props.rows.map((item) => {
                if (item.id===_id){
                    item.Sconto=_sconto;
                    props.onChangeSconto();
                }
                return true;
            });
        }
        CalcolaPrezzi(PercIva);
    };
    // const handleFocusPercSconto= (event) =>{
    //     event.target.select();
    // };
    const handlePercIva= (event) =>{
        if (parseFloat(event.target.value)>100){
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value=0;

        }
        let _PercIva=parseFloat(event.target.value);
        if (isNaN(_PercIva)) _PercIva=0;
        setPercIva(_PercIva);
        CalcolaPrezzi(_PercIva);
        props.onChangeIva(_PercIva);
        
    };
    const handleFocusPercIva= (event) =>{
        event.target.select();
    };
    const handleQuantita= (_num,_id) =>{
        if (props.rows.length>0){
            props.rows.map((item) => {
                if (item.id===_id){
                    item.Quantita=_num;
                    props.onChangeQuantita();
                }
                return true;
            });
        }
        CalcolaPrezzi(PercIva);
    };
    
    const CalcolaPrezzi = (_PercIva)=> {
        let newCostoImponibile=0;
        let newCostoTotale=0;
        let newCostoIva=0;
        if (props.rows.length>0){
            props.rows.map((item) => {
                if (item.Servizio_Contratto_id > 0 ){
                    newCostoImponibile = parseFloat(props.costoImponibile);
                    return newCostoImponibile;
                }
                else {
                    newCostoImponibile +=((parseFloat(item.Prezzo)*parseFloat(item.Quantita)) - (parseFloat(item.Prezzo)*parseFloat(item.Quantita)*parseFloat(item.Sconto)/100)); 
                }
                
                return newCostoImponibile;
            });
        }

        newCostoImponibile=parseFloat(newCostoImponibile.toFixed(2));
        newCostoIva=newCostoImponibile*(parseFloat(_PercIva)/100);
        newCostoIva=parseFloat(newCostoIva.toFixed(2));
        newCostoTotale=newCostoIva + newCostoImponibile;
        setCostoImponibile(newCostoImponibile);
        setCostoIva(newCostoIva);
        setCostoTotale(newCostoTotale);
        if (props.rows.length>0){ 
            props.onChangeCostoTotale(newCostoTotale, newCostoImponibile);
        }
    };
    React.useEffect(() => {
        setRows(props.rows);
        //alert("state rows");
        CalcolaPrezzi(PercIva);
    }, [props.rows]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercIva);
    }, [props.onRicalcola]);

    React.useEffect(() => {
        if (props.setForceSconto){
            handleClickAltro();
            setApplicaSconto(props.setForceSconto);
        }
    }, [props.setForceSconto]);

    React.useEffect(() => {
        setPercIva(props.setIva);
        CalcolaPrezzi(props.setIva);
    }, [props.setIva]);

    React.useEffect(() => {
        CalcolaPrezzi(props.setIva);
    }, [props.costoImponibile]);

    return (

        <TableContainer component={Paper}>
            <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
            <Table className={classes.table} aria-label="spanning table" size="small">
            <TableHead>            
                <TableRow>
                    <TableCell className="grid-head"></TableCell>
                    <TableCell className="grid-head">Titolo</TableCell>
                    <TableCell className="grid-head">Descrizione</TableCell>
                    <TableCell className="grid-head" align="right">Listino</TableCell>
                    <TableCell className="grid-head">Q.ta</TableCell>
                    <TableCell className="grid-head" align="right">Importo</TableCell>
                    {
                    ApplicaSconto  && (<TableCell className="grid-head">Sconto</TableCell>)
                    }
                    {
                    ApplicaSconto  && (<TableCell className="grid-head">Prezzo</TableCell>)                        
                    }
                    
                </TableRow>
            </TableHead>
            <TableBody>
                { Rows.length>0? (Rows.map((row) => {
                        return (
                        <TableRow key={row.id} className="rt-tr-group">
                            <TableCell>
                            {
                             (props.isAdmin===true || props.isFirmato===false) && (
                                    <Avatar className={classes.BtnEdit}  onClick={() => props.onDelete(row.id)}>
                                        <DeleteIcon />
                                    </Avatar>
                                )   
                            }
                            </TableCell>
                            <TableCell>{row.Titolo}</TableCell>
                            <TableCell>{row.Descrizione}</TableCell>
                            <TableCell align="right">{row.Prezzo}</TableCell>
                            <TableCell>
                            {
                                (props.isAdmin===true || props.isFirmato===false) ?
                                (<MyNumInput otherparam={row.id} value={row.Quantita} onChange={handleQuantita}/>):(row.Quantita)
                            }
                            </TableCell>
                            <TableCell align="right">{ccyFormat(parseFloat(row.Prezzo)*parseFloat(row.Quantita))}</TableCell>
                            {
                            ApplicaSconto  && (
                            <TableCell>
                            {
                                (props.isAdmin===true || props.isFirmato===false) ?
                                (<MyNumInput otherparam={row.id} value={row.Sconto} onChange={handlePercSconto}/>):(row.Sconto)
                            }
                            </TableCell>)
                            }
                            {
                            ApplicaSconto  && (
                            <TableCell align="right">{ccyFormat((parseFloat(row.Prezzo)*parseFloat(row.Quantita)) - (parseFloat(row.Prezzo)*parseFloat(row.Quantita)* parseFloat(row.Sconto)/100))}</TableCell>
                            )
                            }
                        </TableRow>
                );}
                )):(
                    <TableRow>
                        <TableCell colSpan={ApplicaSconto ? 8: 6} style={{textAlign:"center"}}><h5>- Nessun Servizio Selezionato -</h5></TableCell>
                    </TableRow>
                )}
                <TableRow className="rt-tr-group">
                    <TableCell colSpan={ApplicaSconto ? 8: 6}>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={ApplicaSconto ? 4: 3}/>
                    <TableCell colSpan={ApplicaSconto ? 3: 2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Sub-Totale</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(parseFloat(CostoImponibile))}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={ApplicaSconto ? 4: 3}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)",display:"flex"}}>
                        <span style={{paddingTop:"4px",width:"60px"}}><b>IVA</b></span> 
                        <Input type="number" name="txtIva" min="0" max="99" autoComplete="new-password"
                            onChange={handlePercIva} value={PercIva}
                            onFocus={handleFocusPercIva}
                            style={{width:"90px"}} bsSize="sm"
                        />
                        <span style={{paddingTop:"4px"}}>  <b>&nbsp;&nbsp;%</b></span> 
                    </TableCell>
                    <TableCell colSpan={ApplicaSconto ? 3: 2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(CostoIva)}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={ApplicaSconto ? 4: 3}>
                        <div style={{display:"flex"}}>
                            <Button className="btn-mbg btn-small" onClick={handleClickAltro}>Altro { VisualizzaCheckSconto ?('<<'):('>>')}</Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            { VisualizzaCheckSconto ?(
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" name="chkApplicaSconto" onChange={handleApplicaSconto} disabled={props.isFirmato && props.isAdmin===false} checked={ApplicaSconto> 0} />{' '}
                                        Applica Sconto
                                    </Label>
                                </FormGroup>
                            ):('')}  
                        </div>
                    </TableCell>
                    <TableCell colSpan={ApplicaSconto ? 3:2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Totale &euro;</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(CostoTotale)}</b></TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
    );
  }

export default function ModOrdine (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [OpenAlertSelServizio, setOpenAlertSelServizio] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
     const onCloseAlertSelServizio = (event) => {
        setOpenAlertSelServizio(false);
    };
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [ChangeServizi, setChangeServizi] = React.useState(false);
    const [NumOrdine, setNumOrdine] = React.useState("");
    const [DataOrdine, setDataOrdine] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [IdCommerciale, setIdCommerciale] = React.useState("");
    const [DesDirettore, setDesDirettore] = React.useState("");
    const [Approva, setApprova] = React.useState("");
    const [Annulla, setAnnulla] = React.useState("");
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [DesRischioAteco, setDesRischioAteco] = React.useState("");
    const [CodiceAteco, setCodiceAteco] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [ModalPag, setModalPag] = React.useState("");
    const [Iban, setIban] = React.useState("");
    const [CodiceIva, setCodiceIva] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [OpenSign, setOpenSign] = useState(false);
    const [Zona, setZona] = React.useState("");
    const [DesZona, setDesZona] = React.useState("");
    const [RischioAteco, setRischioAteco] = React.useState("");
    const [Firmato, setFirmato] = React.useState(0);
    const [CifraAcconto, setCifraAcconto] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [DeltaServiziContratto, setDeltaServiziContratto] = React.useState(0);
    const [Saldo, setSaldo] = React.useState("0");
    const [SaldoCommerciale, setSaldoCommerciale] = React.useState("");
    const [SaldoDirettore, setSaldoDirettore] = React.useState("");
    const [ServizioDeleted, setServizioDeleted] = React.useState(false);
    const [RowsServizi, setRowsServizi] = React.useState([]);
    const [Note, setNote] = React.useState("");
    const [IDAllegatoEmail, setIDAllegatoEmail] = React.useState("");
    const [IDContratto, setIDContratto] = React.useState("");
    const [DesAllegatoEmail, setDesAllegatoEmail] = React.useState("");
    const [CondEconomiche, setCondEconomiche] = React.useState("");
    const [isNext, setisNext] = React.useState(false);
    const [goNext, setgoNext] = React.useState(false);
    const [ImgSign, setImgSign] = React.useState(null);
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [OpenSelServizioContratto, setOpenSelServizioContratto] = React.useState(false);
    const [isFirmato, setisFirmato] = React.useState(false);
    //confirm object
    const [MsgConfirmDelServizio,setMsgConfirmDelServizio]=React.useState("Si desidera eliminare il servizio dalla lista dei selezionati?");
    const [OpenConfirmDelServizio, setOpenConfirmDelServizio] = React.useState(false);
    const [OpenConfirmSubmit, setOpenConfirmSubmit] = React.useState(false);
    const [IdConfirmDelServizio, setIdConfirmDelServizio] = React.useState(false);
    const [forceIva, setforceIva] = React.useState("0");
    const [forceSconto, setforceSconto] = React.useState(false);
    const [FileSostituitoErr, setFileSostituitoErr] = React.useState(false);
    const [FileSostituito, setFileSostituito] = React.useState("");
    const [ProvvCommerciale, setProvvCommerciale] = React.useState(0);
    const [ProvvDirettore, setProvvDirettore] = React.useState(0);
    const onCloseConfirmDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
    };
    const onCloseConfirmSubmit = (event) => {
        setOpenConfirmSubmit(false);
    };
    const onCloseSign = (event) => {
        setOpenSign(false);
    };
    const onConfirmSign = (_Sign) => {
        setImgSign(_Sign);
        setOpenSign(false);
    };
    const onOpenSign = (event) => {  
        setOpenSign(!OpenSign);  
    };
    const toggleDelServizio = (_Id) => {
        setIdConfirmDelServizio(_Id);
        setOpenConfirmDelServizio(true);
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    };
    const handleAllegatoEmail = (value,text) => {  
        setIDAllegatoEmail(value); 
        setDesAllegatoEmail(text); 
    };
    const handleCondEconomiche = (event) => {  
        setCondEconomiche(event.target.value);  
    };
    const handleCifraAcconto = (event) => {  
        setCifraAcconto(event.target.value);  
        ControllaValoreAcconto(CostoTotale,event.target.value);
    }; 
    const handleCommerciale = (value, text) => {
        setIdCommerciale(value);
        setDesCommerciale(text);
    };
    const onChangeQuantita = () =>{
        setChangeServizi(true);
    }

    const onChangeSconto = () =>{
        setChangeServizi(true);
    }

    const ChangeCostoTotale = (_value, _costoImpinibile) => {
        setCostoTotale(_value);
        setCostoImponibile(_costoImpinibile);
        ControllaValoreAcconto(_value,CifraAcconto);
    };

    const ControllaValoreAcconto = (_costo,_acconto) => {
        
        if (parseFloat(_acconto)>parseFloat(_costo) ){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("L'acconto supera il totale da pagare verrà azzerato!");
            setCifraAcconto(0);
            setOpenAlert(true);
        }
    }

    const handleSaldo = (event) => {  
        setSaldo(event.target.value); 
    }; 
    
    const handleSaldoCommerciale = (event) => {  
        setSaldoCommerciale(!SaldoCommerciale);
    }; 
    const handleDirettore = (_value, text) => {
        setDesDirettore(text);
    };
    const handleSaldoDirettore = (event) => {  
        setSaldoDirettore(!SaldoDirettore);
    }; 
    const onDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
        
        let newRows = RowsServizi;
        let index = -1;
        let indexSel = -1;

        if (newRows.length>0){
            newRows.map((item) => {
                index +=1;
                if (item.id===IdConfirmDelServizio){
                    indexSel=index;
                }
                return indexSel;
            });
        }
        
        if (indexSel !== -1) {
            newRows.splice(indexSel, 1);
            setRowsServizi(newRows);
            setServizioDeleted(!ServizioDeleted);
            setChangeServizi(true);
        }

    };
    const handleModalPag = (event) => {  
        setModalPag(event.target.value);  
    };
    const handleIban = (event) => {  
        setIban(event.target.value);  
    };
    const handleOpenSelServizio = (event) => {  
        setOpenSelServizio(true);  
    };

    const handleOpenSelServizioContratto = (event) => {  
        setOpenSelServizioContratto(true);  
    };

    const CloseSelServizio = (event) => {  
        setOpenSelServizio(false);  
    };

    const CloseSelServizioContratto = (event) => {  
        setOpenSelServizioContratto(false);  
    };
    
    const onConfirmSelServizio = (RisArray) => {  

        let newArray;


        let id = RisArray[0].id;
        
        let res=false;

        if (RowsServizi.length>0){
            RowsServizi.map(function(item) {
                if (item.id === id){
                    res=true;
                    return true;
                }
                return res;
            });
        }

        if (!res){
            if (RowsServizi.length>0){
                newArray= RowsServizi.concat(RisArray);
            }
            else {
                newArray= RisArray;
            }
            //newArray.push(RisArray)
            setRowsServizi(newArray);
            setChangeServizi(true);
            setOpenSelServizio(false);  
            setOpenSelServizioContratto(false);  
        }
        else {
            setMsgAlert("Servizio già selezionato, per aumentare il quantitativo incrementare il campo quantità!");
            setOpenAlertSelServizio(true);

        }
    };

    const handleAnnulla= (event) =>{
        setAnnulla(!Annulla);
    }

    const handleApprova= (event) =>{
        setApprova(!Approva);
    }

    const ChangeIva = (_value) => {
        setCodiceIva(_value); 
    };

    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    React.useEffect(() => {
        if (localStorage.getItem("idazienda")!=="" && DataOrdine !== ""){
            LoadDatiAzienda();
        }
    }, [DataOrdine]);

    React.useEffect(() => {
        if (localStorage.getItem("idordine")!==""){
            LoadDatiOrdine();
            LoadServiziOrdine();
        }
    }, []);
    const hiddenFileSostituisci = React.useRef(null);

    const LoadDatiAzienda = (event) => {
        if(localStorage.getItem("idazienda")!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    const fd = new FormData();
                    fd.append("Azienda_id", localStorage.getItem("idazienda"));
                    fd.append("DataOrdine", DataOrdine); 
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    let url=process.env.REACT_APP_API_URL+'customerbyordine';
                    result = await ax.post(url, fd, axiosConfig);
                    let _numServiziContratto = result.data[0].NumServiziContratto !== null ? result.data[0].NumServiziContratto : 0;
                    let _numServiziContrattoOrdine = result.data[0].NumServiziContrattoOrdine !== null ? result.data[0].NumServiziContrattoOrdine : 0;
                    let _deltaServiziContratto = _numServiziContratto - _numServiziContrattoOrdine;
                    setDeltaServiziContratto(_deltaServiziContratto);
                    setRagioneSociale(result.data[0].RagioneSociale);
                    setDesRischioAteco(GetTextRischioAteco(result.data[0].Rischio));
                    setCodiceAteco(result.data[0].CodiceAteco);
                    setDesZona(GetTextZona(result.data[0].Zona));
                    setZona(result.data[0].Zona);
                    setRischioAteco(result.data[0].Rischio);
                    setCellulare(result.data[0].CellulareReferente);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }

    const LoadDatiOrdine = (event) => {
        if(localStorage.getItem("idordine")!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'ordine/' + localStorage.getItem("idordine");               
                    result = await ax.post(url);
                    setModalPag(result.data[0].TipoPagamento);
                    setIban(result.data[0].Iban);
                    setNumOrdine(result.data[0].NumOrdine);
                    setDataOrdine(result.data[0].DataOrdine);
                    setAnnulla(result.data[0].Annullato);
                    if(result.data[0].Firmato!==null){
                        setFirmato(result.data[0].Firmato);
                        setisFirmato(result.data[0].Firmato===1);
                    }
                    else {
                        setFirmato(0);
                        setisFirmato(false);
                    }
                    setApprova(result.data[0].Approvato);
                    setCodiceIva(result.data[0].CodiceIva);
                    setSaldo(String(result.data[0].Saldo));
                    setCostoImponibile(result.data[0].Imponibile);
                    setSaldoCommerciale(result.data[0].SaldoCommerciale);
                    setSaldoDirettore(result.data[0].SaldoDirettore);
                    setCifraAcconto(result.data[0].Acconto);
                    setIdCommerciale(result.data[0].Commerciale_id);
                    setIDContratto(result.data[0].Contratto_id);
                    setDesCommerciale(result.data[0].Nome + " " + result.data[0].Cognome);
                    setDesDirettore(result.data[0].NomeDirettore!=null ? result.data[0].NomeDirettore + " " + result.data[0].CognomeDirettore : "");
                    setProvvCommerciale(result.data[0].ProvvigioneCommerciale!== null ?result.data[0].ProvvigioneCommerciale :0);
                    setProvvDirettore(result.data[0].ProvvigioneDirettore!== null ?result.data[0].ProvvigioneDirettore :0);
                    ChangeIva(result.data[0].CodiceIva);

                    setNote(result.data[0].Note);
                    
                    if(result.data[0].AllegatoEmail_id!==null){
                        setIDAllegatoEmail(result.data[0].AllegatoEmail_id);
                        setDesAllegatoEmail(result.data[0].DescrizioneEmail);
                    }
                    else {
                        setIDAllegatoEmail(0);
                        setDesAllegatoEmail("");
                    }
                    
                    if(result.data[0].CondEconomiche!==null){
                        setCondEconomiche(result.data[0].CondEconomiche);
                    }
                    else {
                        setCondEconomiche("");
                    }
                    setforceIva(String(result.data[0].CodiceIva));
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }
    const LoadServiziOrdine = (event) => {
        if(localStorage.getItem("idordine")!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'serviziordinelist/' + localStorage.getItem("idordine");               
                    result = await ax.post(url);
                    let _forceSconto=false;
                    if (result.data.length>0){
                        result.data.map((item) => {
                            if (parseFloat(item.Sconto)>0){
                                _forceSconto=true;
                                return _forceSconto;
                            }
                            
                        });
                    }
                    setforceSconto(_forceSconto);
                    setRowsServizi(result.data);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning");
                    setVerticalAlert("center");
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    const handleClickSostituisci = (event) => {
        hiddenFileSostituisci.current.click();
    };

    const handleChangeSostituisci = (event) => {
        const _file = event.target.files[0];
        const msg= VerifyPDF(_file);
        
        if (msg!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFileSostituitoErr(true);
        }
        else {
            setFileSostituito(_file);
            setFileSostituitoErr(false);
        }
    };

    const handleSubmitForm = (_goNext) => {
        setgoNext(_goNext);
        if (!ChangeServizi)
        {
            onSubmitForm(_goNext);
        }
        else {
            setOpenConfirmSubmit(true);
        }
            
    };
    const onSubmitForm= (_goNext) => 
    {
        
        if (validator.current.allValid() && RowsServizi.length>0 && FileSostituitoErr!==true) {
            
            setisSubmit(true);
            const intApprovato = Approva ? 1 : 0;
            const intAnnullato = Annulla ? 1 : 0;
            const intSaldoDirettore = SaldoDirettore ? 1 : 0;
            const intSaldoCommerciale = SaldoCommerciale ? 1 : 0;
            const fd = new FormData();
            fd.append("id", localStorage.getItem("idordine"));
            fd.append("Azienda_id", localStorage.getItem("idazienda"));
            fd.append("Commerciale_id", IdCommerciale);
            fd.append("CodiceIva", CodiceIva);
            fd.append("Imponibile", CostoImponibile);
            fd.append("Zona", Zona);
            fd.append("TipoPagamento", ModalPag);
            fd.append("Iban", Iban);
            fd.append("Approvato", intApprovato);
            if (ImgSign!==null){
                fd.append("Firmato", 1);
                fd.append("imgFirma", ImgSign);
            }
            else{
                fd.append("Firmato", Firmato);
                fd.append('FileSostituito', FileSostituito);
            }
            fd.append("Acconto", CifraAcconto);
            fd.append("Saldo", Saldo);
            fd.append("SaldoCommerciale", intSaldoCommerciale);
            fd.append("SaldoDirettore", intSaldoDirettore);
            fd.append("Annullato", intAnnullato);
            fd.append("Note", Note);
            fd.append("AllegatoEmail_id", IDAllegatoEmail);
            fd.append("Contratto_id", IDContratto);
            fd.append("CondEconomiche", CondEconomiche);
            fd.append("ProvvigioneDirettore", ProvvDirettore);
            fd.append("ProvvigioneCommerciale", ProvvCommerciale);
            if (ChangeServizi) {
                let ParamServizi="";
                ParamServizi =RowsServizi.map(function(item) {
                    return item.id + "|" + item.Servizio_Contratto_id + "|" + item.Quantita + "|" + item.Sconto;
                });
                fd.append("ParamServizi", ParamServizi);
            }
                        
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
                
            ax
            .post(process.env.REACT_APP_API_URL+'ordineupdate', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        if (_goNext){
                            setisNext(true);
                        }
                        else{
                            setCloseForm(true);
                        }
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'inserimento! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setisSubmit(false);

        } else {
            if (RowsServizi.length>0){
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                setOpenAlert(true);
            }
            else
            {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno un servizio");
                setOpenAlert(true);
            }
            
        }
    }
    
    let UserRole =GetRole(localStorage.getItem("token"));
    let isAdmin=false;
    if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true
    let urlRedirect="";
    
    if (CloseForm){
        if (localStorage.getItem("menunew")==="1")
        {
            urlRedirect="/home";
        }
        else {
            urlRedirect="/ordini";
        }
    }
    if (isNext && localStorage.getItem("idazienda") !=="" && localStorage.getItem("idordine") !==""){
        urlRedirect="/nuovoordineresponsabili";    
    }
    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.bgHead}>
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Ordine - Servizi
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={() => handleSubmitForm(false)}>
                        Conferma e chiudi {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                    <MatButton color="inherit" onClick={() => handleSubmitForm(true)}>
                        Avanti {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete="off">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                        <ElencaServizio open={OpenSelServizio} handleClose={CloseSelServizio} handleConfirm={onConfirmSelServizio} zona={Zona} rischio={RischioAteco}/>
                        <ElencaServizioContratto open={OpenSelServizioContratto} handleClose={CloseSelServizioContratto} handleConfirm={onConfirmSelServizio} zona={Zona} rischio={RischioAteco}/>
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCellulareReferente">Cellulare</Label>
                                                <Input type="text" name="txtCellulareReferente" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                value={Cellulare} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCodiceAteco">Cod. Ateco</Label>
                                                <Input type="text" name="txtCodiceAteco" id="txtCodiceAteco" placeholder=""
                                                value={CodiceAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtRischio">Rischio</Label>
                                                <Input type="text" name="txtRischio" autoComplete="new-password" placeholder=""
                                                value={DesRischioAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtZona">Zona</Label>
                                                <Input type="text" name="txtZona" id="txtZona" placeholder=""
                                                value={DesZona} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            
                            <Grid item xs={12}>
                                {
                                    (isAdmin===true || isFirmato===false) && (
                                    <Toolbar>
                                        <Button className="btn-mbg" onClick={handleOpenSelServizio} readOnly = {parseInt(IDContratto) > 0} disabled = {parseInt(IDContratto) > 0}>Aggiungi Servizio</Button>
                                        {/* {(DeltaServiziContratto>0) && 
                                        (
                                            <><span>&nbsp;</span><Button className="btn-mbg" onClick={handleOpenSelServizioContratto}>Aggiungi da contratto</Button></>
                                        )
                                        } */}
                                    </Toolbar>
                                    )
                                }
                                <MyConfirmOnSubmit title="Conferma Modifica Ordine" message="Sono state fatte modifiche ai servizi dell'ordine, procedendo con l'operazione saranno cancellate eventuali nomine già assegnate. Si desidera procedere?" handleClose={onCloseConfirmSubmit} handleConfirm={(event) => onSubmitForm(goNext)} open={OpenConfirmSubmit}/>
                                <MyAlertSelServizio message={MsgAlert} severity="warning" handleClose={onCloseAlertSelServizio} vertical="top" horizontal="center" open={OpenAlertSelServizio}/>
                                <MyConfirmDelServizio title="Conferma Cancellazione" message={MsgConfirmDelServizio} handleClose={onCloseConfirmDelServizio} handleConfirm={onDelServizio} open={OpenConfirmDelServizio}/>          
                                <CreateTableServizi 
                                    rows={RowsServizi} 
                                    onDelete={toggleDelServizio} 
                                    onChangeCostoTotale={ChangeCostoTotale} 
                                    onChangeQuantita={onChangeQuantita} 
                                    onChangeSconto={onChangeSconto} 
                                    isAdmin={isAdmin} 
                                    isFirmato={isFirmato}
                                    costoImponibile={CostoImponibile} 
                                    onRicalcola={ServizioDeleted} 
                                    onChangeIva={ChangeIva} 
                                    setIva={forceIva} 
                                    setForceSconto={forceSconto}
                                />
                                <CreateSign open={OpenSign} handleClose={onCloseSign} handleConfirm={onConfirmSign}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtNote">Note interne C&P [max 1000 caratteri]</Label>
                                                <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                onBlur={() => validator.current.showMessageFor('Note')}
                                                value={Note} onChange={handleNote} maxLength="1000"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtCondEconomiche">Cond. Economiche [max 1000 caratteri]</Label>
                                                <Input type="textarea" name="txtCondEconomiche" id="txtCondEconomiche" autoComplete="new-password"
                                                onBlur={() => validator.current.showMessageFor('CondEconomiche')}
                                                value={CondEconomiche} onChange={handleCondEconomiche} maxLength="1000"
                                                />
                                                {validator.current.message('CondEconomiche', CondEconomiche, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtAllegatoEmail">Allegato Email</Label>
                                                <MyAutoComplete id="txtAllegatoEmail" name="txtAllegatoEmail" 
                                                    url={process.env.REACT_APP_API_URL+'allegatoemailsinglelist'} 
                                                    optionname="Descrizione" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('AllegatoEmail')}
                                                    customvalue={DesAllegatoEmail} onChange={handleAllegatoEmail} 
                                                    readOnly={isFirmato || isAdmin===false}
                                                    disabled={isFirmato || isAdmin===false}
                                                    />
                                                {validator.current.message('AllegatoEmail', IDAllegatoEmail, 'alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="selModalPag">Modalità di Pagamento *</Label>
                                                <Input type="select" name="selModalPag" id="selModalPag" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('ModalPag')}
                                                disabled={isFirmato && isAdmin===false} readOnly={isFirmato && isAdmin===false}
                                                value={ModalPag} onChange={handleModalPag}
                                                >
                                                    <option key=""></option>
                                                    <option key="1" value="1">Assegno bancario</option>
                                                    <option key="2" value="2">Assegno Circolare</option>
                                                    <option key="3" value="3">Bonifico Bancario</option>
                                                    <option key="4" value="4">Denaro Contante</option>
                                                </Input>
                                                {validator.current.message('ModalPag', ModalPag, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label>
                                                            <input type="radio" name="radioRicLuogo" value="0" 
                                                                checked={Saldo==="0"} onClick={handleSaldo} />
                                                                &nbsp;Acconto 
                                                        </label>
                                                        {
                                                            (Saldo==="0" || parseFloat(CifraAcconto)>0) && (
                                                                <Input type="number" name="txtAcconto" autoComplete="new-password" placeholder=""
                                                                onBlur={() => validator.current.showMessageFor('Acconto')}
                                                                disabled={isFirmato  && isAdmin===false} readOnly={isFirmato  && isAdmin===false}
                                                                value={CifraAcconto} onChange={handleCifraAcconto} className="text-left"
                                                                />
                                                            )
                                                        }
                                                        {validator.current.message('Acconto', CifraAcconto, 'required|numeric', { className: 'text-danger' })}
                                                    </FormGroup> 
                                                </Col>
                                                <Col xs={6}>
                                                    <label>
                                                        <input type="radio" name="radioRicLuogo" value="1" checked={Saldo==="1"}
                                                        onClick={handleSaldo}/>
                                                        &nbsp;Saldo
                                                    </label>
                                                    {
                                                            (Saldo==="1" && parseFloat(CifraAcconto)>0) && (
                                                                <Input type="text" name="txtSaldo" autoComplete="new-password" placeholder=""
                                                                disabled={true} readOnly={true}
                                                                value={ccyFormat(parseFloat(CostoTotale) - parseFloat(CifraAcconto))} className="text-left"
                                                                />
                                                            )
                                                        }
                                                </Col>
                                            </Row> 
                                        </Col>
                                        {
                                        isFirmato===true ? 
                                        (
                                        <Col md={2}>
                                            <FormGroup>
                                                <input
                                                    type="file"
                                                    ref={hiddenFileSostituisci}
                                                    onChange={handleChangeSostituisci}
                                                    style={{display: 'none'}} 
                                                />
                                                <Label>&nbsp;</Label>
                                                <Button onClick={handleClickSostituisci} disabled={isAdmin===false} readOnly={isAdmin===false} className="btn-mbg btn-block">Sostituisci File</Button>
                                            </FormGroup>
                                        </Col>
                                        ):(
                                            RowsServizi.length>0 && (
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button onClick={onOpenSign} className="btn-mbg btn-block">Firma</Button>
                                                </FormGroup>
                                            </Col>
                                            )
                                        )
                                        }  
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="selIban">Iban</Label>
                                                <Input type="select" name="selIban" id="selIban" placeholder=""
                                                value={Iban} onChange={handleIban}
                                                >
                                                    <option key=""></option>
                                                    <option key="IT76O0538781490000043040468" value="IT76O0538781490000043040468">IT76O0538781490000043040468</option>
                                                    <option key="IT76E0200816304000106779104" value="IT76E0200816304000106779104">IT76E0200816304000106779104</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>   
                                        
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtNumOrdine">N. Ordine</Label>
                                                <Input type="text" name="txtNumOrdine" autoComplete="new-password" placeholder=""
                                                value={NumOrdine} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtDataOrdine">Del</Label>
                                                <Input type="text" name="txtDataOrdine" autoComplete="new-password"
                                                value={DataOrdine} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col  xl={2} lg={2} md={2} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{fontSize:"18px"}}> 
                                                    <Input type="checkbox" name="chkAnnulla" onChange={handleAnnulla} checked={Annulla> 0} />{' '}
                                                Annulla
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        {
                                        (isFirmato===true && isAdmin===true) &&
                                        (
                                        <Col  xl={1} lg={1} md={2} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{fontSize:"18px"}}>
                                                    <Input type="checkbox" name="chkApprova" onChange={handleApprova} checked={Approva> 0} />{' '}
                                                    Approva
                                                </Label>
                                            </FormGroup>
                                        </Col>)
                                        }
                                    </Row>
                                    <Row>
                                        <Col xl={5} lg={5} md={6} xs={12}>
                                            <FormGroup>
                                                <Label for="txtCommerciale">Commerciale *</Label>
                                                <MyAutoComplete id="txtCommerciale" name="txtCommerciale"
                                                    url={process.env.REACT_APP_API_URL + 'commercialesinglelist'}
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    customvalue={DesCommerciale} onChange={handleCommerciale}
                                                    onBlur={() => validator.current.showMessageFor('Commerciale')}
                                                    disabled={isAdmin === false} readOnly={isAdmin === false}
                                                />
                                                {validator.current.message('Commerciale', IdCommerciale, 'required|alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtProvvigioneCommerciale">Provv. Commerciale</Label>
                                                    <Input type="text" 
                                                        name="txtProvvigioneCommerciale" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ProvvCommerciale} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                         {
                                        (isFirmato===true && isAdmin===true) &&
                                        (
                                        <Col  xl={2} lg={3} md={4} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{fontSize:"18px"}}>
                                                    <Input type="checkbox" name="chkSaldoCommerciale" onChange={handleSaldoCommerciale} checked={SaldoCommerciale> 0} />{' '}
                                                    Saldo Commerciale
                                                </Label>
                                            </FormGroup>
                                        </Col>)
                                        }
                                    </Row>
                                    <Row>
                                       
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={5} lg={5} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtDirettore">Direttore</Label>
                                                    <MyAutoComplete id="txtDirettore" name="txtDirettore" 
                                                    url={process.env.REACT_APP_API_URL+'commercialedirettoresinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Direttore')}
                                                    customvalue={DesDirettore} onChange={handleDirettore}
                                                    disabled
                                                    readOnly
                                                    />
                                                    {validator.current.message('Direttore', DesDirettore, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtProvvigioneDirettore">Provv. Direttore</Label>
                                                    <Input type="text" 
                                                        name="txtProvvigioneDirettore" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ProvvDirettore} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        (isFirmato===true && isAdmin===true) &&
                                        (
                                        <Col  xl={2} lg={3} md={4} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{fontSize:"18px"}}>
                                                    <Input type="checkbox" name="chkSaldoDirettore" onChange={handleSaldoDirettore} checked={SaldoDirettore> 0} />{' '}
                                                    Saldo Direttore
                                                </Label>
                                            </FormGroup>
                                        </Col>)
                                        }
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        