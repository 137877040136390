import React, {useRef,useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyAutoComplete from '../../Components/MyAutoComplete';
import MyAlert from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import {GetTextRischioAteco,GetToken,GetIDCommerciale, GetRole} from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import imgSearch from "../../img/search.png";
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { Ruolo_Admin } from "../../Modules/Costanti";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    FormPopup: {
        maxWidth: "800px !important",
        minWidth: "700px !important",
      },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
}));


  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ElencaAziende(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    
    const [selected, setSelected] = useState("");
    const [SelectedData, setSelectedData] = useState([]);
    
    const handleRowClick = (row) => {
        setSelected(row.id);
        setSelectedData(row);
      };

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
      
    const isSelected = (name) => selected===name;

    const isSetFirst = (row) => {
        if (selected==="")
        {
            setSelected(row.id);
            setSelectedData(row);
        }
    };


  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Ragione Sociale</TableCell>
                                <TableCell className="grid-head" align="center">Ateco</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                props.data.map((row,i) => {
                                    if (i===0) isSetFirst(row)
                                    const isItemSelected = isSelected(row.id);
                            
                                    return (
                                        
                                    <TableRow key={row.id} hover tabIndex={-1} className={isItemSelected ? 'row-selected-cert' : ''} onClick={(event) => handleRowClick(row)}>
                                        <TableCell>{row.RagioneSociale}</TableCell>
                                        <TableCell>{row.DescrizioneAteco}</TableCell>
                                    </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}


export default function NewAzienda (props) {
    //alert(props.match.params.id);
    const UserRole =GetRole(localStorage.getItem("token"));
    var isAdmin=false;
    if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true
    const classes = useStyles();
    const msgAziendaAssegnata = "Preventivo non autorizzato in quanto l'azienda è associata ad un altro commerciale!";
    const msgNoCommerciale="Per inserire il preventivo è necessario essere associato ad un commerciale!";
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    
    //------------
    const [RicAzienda, setRicAzienda] = React.useState("");
    const [RicIDAzienda, setRicIDAzienda] = React.useState("");
    const [SedeOp, setSedeOp] = React.useState(false);
    const [CloseForm, setCloseForm] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [PartitaIVA, setPartitaIVA] = React.useState("");
    const [CodFiscale, setCodFiscale] = React.useState("");
    const [CodiceUnivoco, setCodiceUnivoco] = React.useState("");
    const [EmailPec, setEmailPec] = React.useState("");
    const [IndirizzoSL, setIndirizzoSL] = React.useState("");
    const [ComuneSL, setComuneSL] = React.useState("");
    const [CodComuneSL, setCodComuneSL] = React.useState("");
    const [CapSL, setCapSL] = React.useState("");
    const [TelefonoSL, setTelefonoSL] = React.useState("");
    const [IndirizzoSO, setIndirizzoSO] = React.useState("");
    const [ComuneSO, setComuneSO] = React.useState("");
    const [CodComuneSO, setCodComuneSO] = React.useState("");
    const [CapSO, setCapSO] = React.useState("");
    const [DescrizioneAteco, setDescrizioneAteco] = React.useState("");
    const [NegaConsenso, setNegaConsenso] = React.useState(0);
    const [CodiceAteco, setCodiceAteco] = React.useState("");
    const [DesRischioAteco, setDesRischioAteco] = React.useState("");
    const [TelefonoSO, setTelefonoSO] = React.useState("");
    const [Referente, setReferente] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [EmailReferente, setEmailReferente] = React.useState("");
    const [Zona, setZona] = React.useState("");
    const [isNext, setisNext] = React.useState(false);
    const [IDAzienda, setIDAzienda] = React.useState("");
    const [Medico,setMedico] = React.useState("");
    const [Note,setNote] = React.useState("");
    const [IDCommerciale,setIDCommerciale] = React.useState(0);
    const [IDResponsabile,setIDResponsabile] = React.useState(0);
    const [AziendaAssegnata,setAziendaAssegnata] = React.useState(false);
    const [OpenElAziende, setOpenElAziende] = React.useState(false);
    const [DataElAziende, setDataElAziende] = useState([]);
    const handleRagioneSociale = (event) => {  
        setRagioneSociale(event.target.value);  
    }; 
    const handlePartitaIVA = (event) => {
        let piva= event.target.value; 
        setPartitaIVA(piva); 
        
        if (piva.length===11 && parseInt(piva)>0){
            
            setIsLoading(true);
            const token =  GetToken(localStorage.getItem("token"));
            let User_id =parseFloat(GetIDCommerciale(localStorage.getItem("token")));
            
            let result=[];
            if (User_id>0 || isAdmin){
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                
                (async () => {
                    
                    try{
                        let url=process.env.REACT_APP_API_URL+'customerbypiva'
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        const fd = new FormData();
                        fd.append("PartitaIva", piva);
                        fd.append("Commerciale_id", User_id);
                        if (isAdmin){
                            fd.append("isAdmin", 1);
                        }
                        
                        result = await ax.post(url,fd,axiosConfig);
                        if (result.data.Commerciale_id!==undefined)
                        {
                            setAziendaAssegnata(true);  
                            setSeverityAlert("warning");
                            setVerticalAlert("center");
                            setMsgAlert(msgAziendaAssegnata);
                            setOpenAlert(true);
                            result=[];
                        }
                        else{
                            if (result.data.length>0){
                                setDataElAziende(result.data);
                                setOpenElAziende(true);
                            }
                        }
                    }
                    catch(error) {
                        let msg=""
                        if (error.response!==undefined) {
                            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                        }
                        else
                        {
                            msg=GetMessage(undefined,"");
                        }
                        setSeverityAlert("error");
                        setVerticalAlert("center");
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        
                    }
                    
                })(); 
            }
            else
            {
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msgNoCommerciale);
                setOpenAlert(true);
                
            }
            setIsLoading(false);    
                
        }
    }; 
    const handleRicAzienda = (value,text) => {
        setRicAzienda(text);
        setRicIDAzienda(value);
        if (parseFloat(value)>0){
            
            setIsLoading(true);
            const token =  GetToken(localStorage.getItem("token"));
            let User_id =parseFloat(GetIDCommerciale(localStorage.getItem("token")));
            
            let result=[];
            if (User_id>0 || isAdmin){
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                
                (async () => {
                    
                    try{
                        let url=process.env.REACT_APP_API_URL+'customerbyid'
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        const fd = new FormData();
                        fd.append("Customer_id", value);
                        fd.append("Commerciale_id", User_id);
                        if (isAdmin){
                            fd.append("isAdmin", 1);
                        }
                        result = await ax.post(url,fd,axiosConfig);
                        if (result.data.Commerciale_id!==undefined)
                        {
                            setAziendaAssegnata(true);  
                            setSeverityAlert("warning");
                            setVerticalAlert("center");
                            setMsgAlert(msgAziendaAssegnata);
                            setOpenAlert(true);
                            result=[];
                        }
                        else{
                            if (result.data.length>0){
                                setPartitaIVA(result.data[0].PartitaIva);
                                onConfirmElAziende(result.data[0]);
                            }
                        }
                    }
                    catch(error) {
                        let msg=""
                        if (error.response!==undefined) {
                            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                        }
                        else
                        {
                            msg=GetMessage(undefined,"");
                        }
                        setSeverityAlert("error");
                        setVerticalAlert("center");
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        
                    }
                    
                })(); 
            }
            else
            {
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msgNoCommerciale);
                setOpenAlert(true);
                
            }
            setIsLoading(false);    
                
        }
    };
    const handleCodFiscale = (event) => {  
        setCodFiscale(event.target.value);  
    }; 
    const handleCodiceUnivoco = (event) => {  
        setCodiceUnivoco(event.target.value);  
    }; 
    const handleEmailPec = (event) => {  
        setEmailPec(event.target.value);  
    }; 
    const handleIndirizzoSL = (event) => {  
        setIndirizzoSL(event.target.value);  
    }; 
    const handleComuneSL = (value,text) => {
        var arrValue = value.split("|");
        setCodComuneSL(arrValue[0]);
        setCapSL(arrValue[1]);
        setComuneSL(text);  
    }; 
    const handleCapSL = (event) => {  
        setCapSL(event.target.value);  
    }; 
    const handleTelefonoSL = (event) => {  
        setTelefonoSL(event.target.value);  
    }; 
    const handleIndirizzoSO = (event) => {  
        setIndirizzoSO(event.target.value);  
    }; 
    const handleComuneSO = (value,text) => {  
        var arrValue = value.split("|");
        setCodComuneSO(arrValue[0]);
        setCapSO(arrValue[1]);
        setComuneSO(text);  
    }; 
    const handleCapSO = (event) => {  
        setCapSO(event.target.value);  
    }; 
    const handleTelefonoSO = (event) => {  
        setTelefonoSO(event.target.value);  
    }; 
    const handleReferente = (event) => {  
        setReferente(event.target.value);  
    };
    const handleCellulare = (event) => {  
        setCellulare(event.target.value);  
    };
    const handleEmailReferente = (event) => {  
        setEmailReferente(event.target.value);  
    };
    const handleViewSedeOp = (event) => {
        setSedeOp(!SedeOp);
    };
    const handleMedico = (event) => {  
        setMedico(event.target.value);  
    };

    const CloseElAziende = (event) => {  
        setOpenElAziende(false);  
    };

    const onConfirmElAziende = (DataResponse) => {  
        setAziendaAssegnata(false);
        setIDAzienda(DataResponse.id)
        if (DataResponse.CodFiscale===null){
            setCodFiscale("");
        }
        else {
            setCodFiscale(DataResponse.CodFiscale);
        }
        setNegaConsenso(DataResponse.NegaConsenso)
        setRagioneSociale(DataResponse.RagioneSociale);
        setCodiceUnivoco(DataResponse.CodUnivoco);
        setDesRischioAteco(GetTextRischioAteco(DataResponse.Rischio));
        setCodiceAteco(DataResponse.CodiceAteco);
        setZona(DataResponse.Zona);
        setEmailPec(DataResponse.EmailPec);
        setIndirizzoSL(DataResponse.IndirizzoSL);
        setCodComuneSL(DataResponse.CodComuneSL);
        setComuneSL(DataResponse.DesComuneSL);
        setCapSL(DataResponse.CapSL);
        setTelefonoSL(DataResponse.TelefonoSL);
        setIndirizzoSO(DataResponse.IndirizzoSO);
        setCodComuneSO(DataResponse.CodComuneSO);
        setComuneSO(DataResponse.DesComuneSO);
        setCapSO(DataResponse.CapSO);
        setTelefonoSO(DataResponse.TelefonoSO);
        setSedeOp(DataResponse.SedeDifferente);
        setReferente(DataResponse.Referente);
        setEmailReferente(DataResponse.Email);
        setCellulare(DataResponse.CellulareReferente);
        setMedico(DataResponse.NomeMedico);
        setNote(DataResponse.Note);
        setIDCommerciale(DataResponse.Commerciale_id);
        if (DataResponse.Responsabile_id===null){
            setIDResponsabile(0);
        }
        else {
            setIDResponsabile(DataResponse.Responsabile_id);
        }
        
        if (DataResponse.DescrizioneAteco===null){
            setDescrizioneAteco("");
        }
        else
        {
            setDescrizioneAteco(DataResponse.DescrizioneAteco);
        }
        setOpenElAziende(false);
        
    };

    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    const onCloseForm = (event) => {
        setCloseForm(true);
        
    };
   
    const onSubmitForm= (event) => 
    {
        if (!AziendaAssegnata){
            if (validator.current.allValid()) {
                let User_id =GetIDCommerciale(localStorage.getItem("token"));
                let _IDCommerciale=IDCommerciale;
                if (parseFloat(_IDCommerciale)===0){
                    if (parseFloat(User_id)>0){
                        _IDCommerciale=User_id;    
                    }
                }
                
                
                if (_IDCommerciale===""){
                    setSeverityAlert("error");
                    setVerticalAlert("top");
                    setMsgAlert(msgNoCommerciale);
                    setOpenAlert(true);
                }
                else {
                    setisSubmit(true);
                    const fd = new FormData();
                    const intSedeOp = SedeOp ? 1 : 0;
                    fd.append("RagioneSociale", RagioneSociale);
                    fd.append("CodFiscale", CodFiscale);
                    fd.append("PartitaIva", PartitaIVA);
                    fd.append("CodUnivoco", CodiceUnivoco);
                    fd.append("CodiceAteco", CodiceAteco);
                    if (Zona===""){
                        fd.append("Zona", "0");
                    }
                    else{
                        fd.append("Zona", Zona);
                    }
                    fd.append("EmailPec", EmailPec);
                    fd.append("Email", EmailReferente);
                    fd.append("IndirizzoSL", IndirizzoSL);
                    fd.append("CodComuneSL", CodComuneSL);
                    fd.append("DesComuneSL", ComuneSL);
                    fd.append("CapSL", CapSL);
                    fd.append("TelefonoSL", TelefonoSL);
                    fd.append("IndirizzoSO", IndirizzoSO);
                    fd.append("CodComuneSO", parseInt("0" + CodComuneSO));
                    fd.append("DesComuneSO", ComuneSO);
                    fd.append("CapSO", CapSO);
                    fd.append("TelefonoSO", TelefonoSO);
                    fd.append("SedeDifferente", intSedeOp);
                    fd.append("Referente", Referente);
                    fd.append("CellulareReferente", Cellulare);
                    fd.append("NomeMedico", Medico);
                    fd.append("Note", Note);
                    fd.append("Commerciale_id", _IDCommerciale);
                    fd.append("Responsabile_id", IDResponsabile);
                    fd.append("NegaConsenso", NegaConsenso);
                    const token = GetToken(localStorage.getItem("token"));
                        const ax= axios;
                        ax.defaults.headers.common['Authorization']="Bearer " + token;
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        let _rotta="";
                        if (IDAzienda==="") {
                            _rotta="customerinsert";
                        }
                        else {
                            fd.append("id",IDAzienda);
                            _rotta="customerupdate";
        
                        }
                    ax
                    .post(process.env.REACT_APP_API_URL+_rotta, fd,axiosConfig)
                    .then((response) => {
                        
                        if (response.status === 200) {
                            if (response.data.error==="OK"){
                                if (IDAzienda==="") setIDAzienda(response.data.id);
                                setisNext(true);
                            }
                            else {
                                let msg="";
                                let ris=JSON.stringify(response.data);
                                ris=ris.replaceAll("\"", "");
                                ris=ris.replaceAll("{", "");
                                ris=ris.replaceAll("}", "");
                                ris=ris.replaceAll("[", "");
                                ris=ris.replaceAll("]", "");
                                msg="errore durante l'inserimento! " + ris;
                                setMsgAlert(msg);
                                setOpenAlert(true);
                            }
                        }
                        else
                        { 
                        const msg=GetMessage(response.status,response.data.message);
                        setSeverityAlert("error");
                        setVerticalAlert("top");
                        setMsgAlert(msg);
                        setOpenAlert(true);
        
                        }
                    })
                    .catch((error) => {
                        let msg=""
                        if (error.response!==undefined) {
                            msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                        }
                        else
                        {
                            msg=GetMessage(undefined,"");
                        }
                        setSeverityAlert("error");
                        setVerticalAlert("top");
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    });
                    setisSubmit(false);
                }
            } else {
                validator.current.showMessages();
                setSeverityAlert("error");
                setVerticalAlert("top");
                setMsgAlert(MsgAlertDefault);
                setOpenAlert(true);
            }
        }
        else {
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msgAziendaAssegnata);
            setOpenAlert(true);
        }
        
    }
    
    let urlRedirect="";
    
    if (CloseForm){
        if (localStorage.getItem("menunew")==="0")
        {
            urlRedirect="/preventivi";
        }
        else {
            urlRedirect="/";
        }
    }

    if (isNext && IDAzienda !==""){
        localStorage.setItem("idazienda", IDAzienda);
        urlRedirect="/nuovopreventivoservizi/";    
    }
    
    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    const FilterCommerciale_id =parseFloat(GetIDCommerciale(localStorage.getItem("token")));
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Nuovo Preventivo - Dati Azienda
                    </Typography>
                    <Button autoFocus color="inherit" onClick={onSubmitForm}>
                        Avanti {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete="off">
                        <ElencaAziende 
                            open={OpenElAziende} 
                            handleClose={CloseElAziende} 
                            handleConfirm={onConfirmElAziende} 
                            data={DataElAziende} 
                        />
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <Grid container spacing={2} className="custom-form">
                            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                            {
                                isAdmin && (
                                    <Grid item xs={12}>
                                        <Paper className={classes.paper}>
                                            <Row>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        <Label for="txtRicAzienda">Ricerca per Ragione sociale</Label>
                                                        <MyAutoComplete id="txtAzienda" name="txtAzienda" 
                                                            url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                                                            optionname="RagioneSociale" 
                                                            optionvalue="id"
                                                            customvalue={RicAzienda} 
                                                            onChange={handleRicAzienda}
                                                            className="MuiAutocomplete-inputsm-search"
                                                        />
                                                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </Paper>
                                    </Grid>)
                            }
                            {
                            FilterCommerciale_id>0 && !isAdmin && ( 
                                        <Grid item xs={12}>
                                            <Paper className={classes.paper}>
                                                <Row>
                                                    <Col md={8}>
                                                        <FormGroup>
                                                            <Label for="txtRicAzienda">Ricerca per Ragione sociale</Label>
                                                            <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                                                                    idparent={FilterCommerciale_id}
                                                                    url={process.env.REACT_APP_API_URL+'customersinglelistbycomm'} 
                                                                    optionname="RagioneSociale" optionvalue="id"
                                                                    customvalue={RicAzienda} onChange={handleRicAzienda} 
                                                                    className="MuiAutocomplete-inputsm-search"/>
                                                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </Paper>
                                        </Grid>)

                            }
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtPartitaIVA">Partita IVA *</Label>
                                                <Input type="number" name="txtPartitaIVA" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('PartitaIva')}
                                                value={PartitaIVA} onChange={handlePartitaIVA}
                                                />
                                                {validator.current.message('PartitaIva', PartitaIVA, 'required|integer|min:11|max:11', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtCodiceFiscale">Codice Fiscale</Label>
                                                <Input type="text" name="txtCodiceFiscale" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('CodFiscale')}
                                                value={CodFiscale} onChange={handleCodFiscale}
                                                />
                                                {validator.current.message('CodFiscale', CodFiscale, 'alpha_num|min:11', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Label for="txtRagioneSociale">Ragione Sociale *</Label>
                                            <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('RagioneSociale')}
                                            value={RagioneSociale} onChange={handleRagioneSociale}
                                            />
                                            {validator.current.message('RagioneSociale', RagioneSociale,'required|string', { className: 'text-danger' })}
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtCodiceUnivoco">Cod. Univoco</Label>
                                                <Input type="text" name="txtCodiceUnivoco" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('CodiceUnivoco')}
                                                value={CodiceUnivoco} onChange={handleCodiceUnivoco}
                                                />
                                                {validator.current.message('CodiceUnivoco', CodiceUnivoco, 'alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={7}>
                                            <FormGroup>
                                                <Label for="txtEmailPec">Email Pec</Label>
                                                <Input type="email" name="txtEmailPec" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('EmailPec')}
                                                value={EmailPec} onChange={handleEmailPec}
                                                />
                                                {validator.current.message('EmailPec', EmailPec, 'email', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                <Row>
                                    <Col md={5}>
                                        <FormGroup>
                                            <Label for="txtIndirizzoSL">Indirizzo Sede Legale *</Label>
                                            <Input type="text" name="txtIndirizzoSL" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('IndirizzoSL')}
                                            value={IndirizzoSL} onChange={handleIndirizzoSL}
                                            />
                                            {validator.current.message('IndirizzoSL', IndirizzoSL, 'required|string', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="txtComuneSL">Comune *</Label>
                                            <MyAutoComplete id="txtComuneSL" name="txtComuneSL" url={process.env.REACT_APP_API_URL+'comuniwithcap'} optionname="Comune" optionvalue="Codice"
                                            onBlur={() => validator.current.showMessageFor('ComuneSL')}
                                            customvalue={ComuneSL} onChange={handleComuneSL}
                                            />
                                            {validator.current.message('ComuneSL', ComuneSL, 'required|string', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                    <Col md={1}>
                                        <FormGroup>
                                            <Label for="txtCapSL">Cap</Label>
                                            <Input type="text" name="txtCapSL" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('CapSL')}
                                            value={CapSL} onChange={handleCapSL}
                                            />
                                            {validator.current.message('CapSL', CapSL, 'alpha_num', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                    <Col md={2}>
                                        <FormGroup>
                                            <Label for="txtTelefonoSL">Telefono</Label>
                                            <Input type="text" name="txtTelefonoSL" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                            onBlur={() => validator.current.showMessageFor('TelefonoSL')}
                                            value={TelefonoSL} onChange={handleTelefonoSL}
                                            />
                                            {validator.current.message('TelefonoSL', TelefonoSL, 'cphone', { className: 'text-danger' })}
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" name="chkSedeOperativaDifferente" onChange={handleViewSedeOp} />{' '}
                                                    Sede Operativa differente dalla sede legale
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            {SedeOp> 0 && (
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Label for="txtIndirizzoSO">Indirizzo Sede Operativa</Label>
                                                <Input type="text" name="txtIndirizzoSO" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('IndirizzoSO')}
                                                value={IndirizzoSO} onChange={handleIndirizzoSO}
                                                />
                                                {validator.current.message('IndirizzoSO', IndirizzoSO, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtComuneSO">Comune</Label>
                                                <MyAutoComplete id="txtComuneSO" name="txtComuneSO" url={process.env.REACT_APP_API_URL+'comuniwithcap'} optionname="Comune" optionvalue="Codice"
                                            onBlur={() => validator.current.showMessageFor('ComuneSO')}
                                            customvalue={ComuneSO} onChange={handleComuneSO}
                                            />
                                            {validator.current.message('ComuneSO', ComuneSO, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={1}>
                                            <FormGroup>
                                                <Label for="txtCapSO">Cap</Label>
                                                <Input type="text" name="txtCapSO" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('CapSO')}
                                                value={CapSO} onChange={handleCapSO}
                                                />
                                                {validator.current.message('CapSO', CapSO, 'alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTelefonoSO">Telefono</Label>
                                                <Input type="text" name="txtTelefonoSO" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                onBlur={() => validator.current.showMessageFor('TelefonoSO')}
                                                value={TelefonoSO} onChange={handleTelefonoSO}
                                                />
                                                {validator.current.message('TelefonoSO', TelefonoSO, 'cphone', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            )}
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtReferente">Persona Referente *</Label>
                                                <Input type="text" name="txtReferente" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Referente')}
                                                value={Referente} onChange={handleReferente}
                                                />
                                                {validator.current.message('Referente', Referente, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtCellulareReferente">Cellulare *</Label>
                                                <Input type="text" name="txtCellulareReferente" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                onBlur={() => validator.current.showMessageFor('Cellulare')}
                                                value={Cellulare} onChange={handleCellulare}
                                                />
                                                {validator.current.message('Cellulare', Cellulare, 'required|cphone', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtEmailReferente">Email *</Label>
                                                <Input type="email" name="txtEmailReferente" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Email')}
                                                value={EmailReferente} onChange={handleEmailReferente}
                                                />
                                                {validator.current.message('EmailReferente', EmailReferente, 'required|email', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        