import React, {Component} from "react";
import "reactstrap";
import '../css/Header.css';
import img_logo_cpservice from "../img/logo-cpservice.png";
import img_logo_cpcert from "../img/logo-cpcert.png";
import img_logo_cpapp from "../img/logo-cpapp.png";
import img_logOut from "../img/icon-logout.png";
import axios from "axios";
import {Redirect} from "react-router-dom";
import CambioPWD from './CambioPWD';
import {GetToken,GetRole} from "../Modules/Funzioni";
import {app_cpCert,app_cpService} from "../Modules/Costanti";
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stLogout:false,
            UserName:"",
            IDUser:"",
            SelectedApp:"0",
            isOpenModPwd:false,
        };
    }
   
    onLogOut= (event) => {
        //localStorage.setItem("token", "");
        localStorage.clear();
        this.setState({stLogout: true});
    }

    toggleModPwd = () => {
        this.setState({isOpenModPwd: !this.state.isOpenModPwd});
      }
    //   toggleApp = () => {
    //     if (localStorage.getItem("app_selected")!==""){
    //         localStorage.setItem("app_selected", "");
    //         this.setState({SelectedApp: ""});
    //     }
    //   }
    componentDidMount=()=>{
        
        (async () => {
            try
            {
                const token = GetToken(localStorage.getItem("token"));
                const ax=axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                let result=await ax.post(process.env.REACT_APP_API_URL+'me')
                if (result.status === 200) {
                    this.setState({UserName: result.data.name,
                        IDUser:result.data.id});
                    if (String(result.data.Ruolo)!==String(GetRole(localStorage.getItem("token")))) {
                        this.onLogOut();
                    }
                }
                else {
                    if (result.data.message==="Unauthenticated.") {
                        this.onLogOut();
                    }
                }
            }
            catch(error) 
            {
                if (error && error.response && error.response.status===401){
                    if (error.response.statusText==="Unauthorized" || error.response.data.message==="Unauthenticated.") {
                        this.onLogOut();
                    }
                }
            }
        })();    
    }
   render(){

       if (this.state.stLogout) {
           return <Redirect to="/login" />;
       }
     
       let hrClassName="";
       let imgHead=img_logo_cpapp;
       switch (parseInt(process.env.REACT_APP_APPSELECT)){
            case app_cpService:
                imgHead= img_logo_cpservice;
                hrClassName="hr-line";
                break;
            case app_cpCert:
                imgHead= img_logo_cpcert;
                hrClassName="hr-line-cert";
                break;
            default:
                imgHead= img_logo_cpapp;
                if (this.state.SelectedApp===""){
                    return <Redirect to="/welcome" />;
                }
                break;
        }

        return (
            <div className="main-header">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm text-logo-form">
                            <img src={imgHead} alt="CP Portal" style={{cursor:'pointer'}} className="logo-form"/>
                        </div>
                        <div className="col-sm text-header">
                            <button type="button" className="btn btn-default btn-sm" onClick={this.toggleModPwd}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" className="userIcoSvg">
                                    <g fill="none" fillRule="evenodd" stroke="#333" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.176" transform="translate(1 1)">                         <ellipse cx="8.955" cy="5.362" rx="5.37" ry="5.232"></ellipse>
                                        <path d="M17.881 19.884C16.914 13.522 12.942 10.58 9 10.58S.982 13.522.015 19.884"></path>
                                    </g>
                                </svg>
                                <span className="text-nome-profilo">{this.state.UserName}</span>
                            </button>
                        </div>
                        <div className="col-sm text-header">
                            <button type="button" className="btn btn-default btn-sm" onClick={this.onLogOut}>
                                <img src={img_logOut} alt="" className="logo-logout"  />
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
                <hr className={hrClassName}/>
                {this.state.isOpenModPwd && <CambioPWD  handleClose={this.toggleModPwd} id={this.state.IDUser}/>}
            </div>

        )
    }
}