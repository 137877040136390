import React, {useRef,useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import MyAlert from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import {GetTextRischioAteco,GetTextZona} from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import {Nomina_DL} from "../../Modules/Costanti";
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import MyAutoComplete from '../../Components/MyAutoComplete';
import {isDate,GetToken} from "../../Modules/Funzioni";
import MyDatePicker from '../../Components/MyDatePicker';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 400,
    },
    containerMin: {
        maxHeight: 220,
        minHeight: 220,
      },
      container: {
        maxHeight: 330,
        minHeight: 330,
      },
    FormPopup: {
        maxWidth: "1000px !important",
        minWidth: "900px !important",
      },
    
    BtnEdit: {
    color: '#fff',
    backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
        }  
    },
    HeadSection: {
        backgroundColor:"#01114a",
        textAlign:"center",
        fontWeight:"bold",
        color:"#fff",
        height:"30px",
        fontSize:"14pt",
        minHeight: "40px",
        zIndex: 3,
        position: "sticky",
        top: 0,
        left: 0,
    },
    HeadSticky:{
        top: "40px",
        left: 0,
        zIndex: 2,
        position: "sticky",
        backgroundColor:"#fff",
        borderBottom: "1px solid #011e87 !important",
        fontWeight: "bold !important",
    },
    HeadPopPup: {
        backgroundColor:"#3f51b5",
        textAlign:"center",
        fontWeight:"bold",
        color:"#fff",
        height:"30px",
        fontSize:"14pt",
    }
}));


export default function NewResponsabile (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
    
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [DesRischioAteco, setDesRischioAteco] = React.useState("");
    const [CodiceAteco, setCodiceAteco] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [IDResponsabile, setIDResponsabile] = React.useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [DesZona, setDesZona] = React.useState("");
    const [isBack, setisBack] = React.useState(false);
    const [isNext, setisNext] = React.useState(false);
    const [ChangeServizi, setChangeServizi] = React.useState(false);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState([]);
    
    const [Sesso, setSesso] = React.useState("");
    const [CodNazioneNascita, setCodNazioneNascita] = React.useState(0);
    const [NazioneNascita, setNazioneNascita] = React.useState("");
    const [Nome, setNome] = React.useState("");
    const [Cognome, setCognome] = React.useState("");
    const [CodFiscale, setCodFiscale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [CellulareResp, setCellulareResp] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [ComuneResidenza, setComuneResidenza] = React.useState("");
    const [CodComuneResidenza, setCodComuneResidenza] = React.useState("");
    const [ComuneNascita, setComuneNascita] = React.useState("");
    const [CodComuneNascita, setCodComuneNascita] = React.useState("");
    const [DataNascita, setDataNascita] = React.useState("");
    const [Note, setNote] = React.useState("");
    const [AbilitaLogin, setAbilitaLogin] = React.useState("0");
    const [IDUser, setIDUser] = React.useState("0");
   
    const handleSesso = (event) => {  
        setSesso(event.target.value);  
    }; 
    const handleNome = (event) => {  
        setNome(event.target.value);  
    }; 
    const handleCognome = (event) => {  
        setCognome(event.target.value);  
    }; 
    const handleCodFiscale = (event) => {  
        setCodFiscale(event.target.value);  
    }; 
    const handleEmail = (event) => {  
        setEmail(event.target.value);  
    }; 
    const handleCellulareResp = (event) => {  
        setCellulareResp(event.target.value);  
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleComuneResidenza = (value,text) => {
        setCodComuneResidenza(value);
        setComuneResidenza(text);  
    }; 
    const handleNazioneNascita = (value,text) => {
        setCodNazioneNascita(value);
        setNazioneNascita(text);  
    }; 
    const handleComuneNascita = (value,text) => {
        setCodComuneNascita(value);
        setComuneNascita(text);  
    }; 
    const handleComuneEsteroNascita = (event) => {  
        setComuneNascita(event.target.value); 
        setCodComuneNascita(0); 
    }; 
    const handleDataNascita = (value) => {  
        setDataNascita(value);  
    };

    const CheckSelected = (_rows) => {
        let newSelected = [];
        _rows.map((row) => {
            if (row.IDNomina>0){
                newSelected = newSelected.concat(row.id);
            }
        });
        setSelected(newSelected);
    }

    const handleRowClick = (_id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
        setChangeServizi(true);
    };

    const handleRowAllClick = (event) => {
        setChangeServizi(true);
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    function ValidationCodFiscale () {
        let ValMancante="";
        if (Sesso==="")
        {
            ValMancante +="Sesso, "
        }

        if (Nome==="")
        {
            ValMancante +="Nome, "
        }

        if (Cognome==="")
        {
            ValMancante +="Cognome, "
        }

        if (CodNazioneNascita==="")
        {
            ValMancante +="Nazione Nascita, "
        }

        if (CodNazioneNascita!==0 && CodNazioneNascita!==""){
            setSeverityAlert("warning")
            setMsgAlert("Il calcolo del codice fiscale può essere effettuato solo per nazionalità italiane!");
            setVerticalAlert("center");
            setOpenAlert(true);
            return false;
        }

        if (CodComuneNascita==="")
        {
            ValMancante +="Comune Nascita, "
        }

        if (isDate(DataNascita)!=="")
        {
            ValMancante +="Data Nascita, "
        }

        if (ValMancante!==""){
            //elimina l'ultima virgola
            ValMancante=ValMancante.substring(0,ValMancante.length-2)
            setSeverityAlert("warning")
            setMsgAlert("Valori mancanti nei seguenti campi: " + ValMancante);
            setVerticalAlert("center");
            setOpenAlert(true);
            return false;
        }

        return true;
        
    }

    const CalcolaCodFiscale= (event) => 
        {
            
            if (ValidationCodFiscale()) {
                setIsLoading(true); 
                const fd = new FormData();
                fd.append("Sesso", Sesso);
                fd.append("Nome", Nome);
                fd.append("Cognome", Cognome);
                fd.append("CodComuneNascita", CodComuneNascita);
                fd.append("DataNascita", DataNascita);
                
                const token =  GetToken(localStorage.getItem("token"));
                const ax= axios;
                ax.defaults.headers.common['Authorization']="Bearer " + token;
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                        
                ax
                .post(process.env.REACT_APP_API_URL+'calcolacodicefiscale', fd,axiosConfig)
                .then((response) => {
                    
                    if (response.status === 200) {
                        if (response.data.error==="OK"){
                            setCodFiscale(response.data.CodiceFiscale);
                            setSeverityAlert("success");
                            setMsgAlert("Calcolo del Codice Fiscale esguito correttamente!");
                            setVerticalAlert("center");
                            setOpenAlert(true);
                        }
                        else {
                            
                            setSeverityAlert("error");
                            setMsgAlert("Errore durante il calcolo del codice fiscale!");
                            setVerticalAlert("center");
                            setOpenAlert(true);
                        }
                    }
                    else
                    { 
                    const msg=GetMessage(response.status,response.data.message);
                    setSeverityAlert("error");
                    setMsgAlert(msg);
                    setVerticalAlert("center");
                    setOpenAlert(true);
                    }
                })
                .catch((error) => {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setMsgAlert(msg);
                    setVerticalAlert("center");
                    setOpenAlert(true);
                });
                setIsLoading(false); 
            } 
        }


    const onConfirm = () => {
        if (validator.current.allValid()) {
            const fd = new FormData();
            fd.append("Azienda_id", localStorage.getItem("idazienda"));
            fd.append("Ordine_id", localStorage.getItem("idordine"));
            fd.append("UserAbilitato", AbilitaLogin);
            fd.append("id", IDResponsabile);
            fd.append("User_id", IDUser);
            fd.append("Sesso", Sesso);
            fd.append("Nome", Nome);
            fd.append("Cognome", Cognome);
            fd.append("CodFiscale", CodFiscale);
            fd.append("Email", Email);
            fd.append("Cellulare", Cellulare);
            fd.append("Indirizzo", Indirizzo);
            fd.append("CodComuneResidenza", CodComuneResidenza);
            fd.append("DesComuneResidenza", ComuneResidenza);
            fd.append("CodNazioneNascita", CodNazioneNascita);
            fd.append("CodComuneNascita", CodComuneNascita);
            fd.append("DesComuneNascita", ComuneNascita);
            fd.append("DataNascita", DataNascita);
            fd.append("Note", Note);
            if (ChangeServizi){
                fd.append("ControllaServizi", "1");
                if (data!==undefined){
                    if (data.length>0){
                        fd.append("ParamServizi", selected);
                    }
                }
            }
            
            
            handleConfirmResp(fd);
        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setMsgAlert(MsgAlertDefault);
            setVerticalAlert("top");
            setOpenAlert(true);
        }
    }

    async function LoadServizi(idresp) {
        if (localStorage.getItem("idordine")!=="" && idresp!==""){
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'serviziordinedaasegnare'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                fd.append("Ordine_id", localStorage.getItem("idordine"));
                fd.append("IDIntestatario", idresp);
                fd.append("TipoNomina", Nomina_DL);
                
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setVerticalAlert("center");
                setOpenAlert(true);

            }
            if (result.data!==undefined)    {
                setData(result.data);
                CheckSelected(result.data);
            }
        }
    }

    async function LoadDatiResp() {
        if (localStorage.getItem("idordine")!=="" && localStorage.getItem("idazienda")!==""){
            
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'responsabileordine/' + localStorage.getItem("idordine");               
                result = await ax.post(url);
                if (result.data.id>0){
                    setIDResponsabile(result.data.id);
                    setIDUser(result.data.User_id);
                    setAbilitaLogin(result.data.UserAbilitato);
                    setSesso(result.data.Sesso);
                    setNome(result.data.Nome);
                    setCognome(result.data.Cognome);
                    setCodFiscale(result.data.CodFiscale);
                    setNazioneNascita(result.data.DesNazione);
                    setCodNazioneNascita(result.data.CodNazioneNascita);
                    setCodComuneNascita(result.data.CodComuneNascita);
                    setComuneNascita(result.data.DesComuneNascita);
                    setDataNascita(result.data.DataNascita);
                    setIndirizzo(result.data.Indirizzo);
                    setCodComuneResidenza(result.data.CodComuneResidenza);
                    setComuneResidenza(result.data.DesComuneResidenza);
                    setEmail(result.data.Email);
                    setCellulare(result.data.Cellulare);
                    setNote(result.data.Note);
                    return result.data.id;
                }
                else{
                    setIDResponsabile("0")
                }
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setVerticalAlert("center");
                setOpenAlert(true);
            }
            return "0";
        }
    }

    useEffect(() => {  
        (async () => {
            setIsLoading(true);   
            await LoadDatiAzienda();
            let idresp = await LoadDatiResp();
            await LoadServizi(idresp);
            setIsLoading(false);
        })();
    }, []); 
    
    const isSelected = (name) => selected.indexOf(name) !== -1;
    
    const handleConfirmResp = (fd) => {  
        setisSubmit(true); 
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        (async () => {
            let result=[];
            try{
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                let url="";
                if (parseFloat(IDResponsabile)>0){
                    url=process.env.REACT_APP_API_URL+'responsabileupdate';               
                }
                else {
                    url=process.env.REACT_APP_API_URL+'responsabileinsert';               
                }
                
                result = await ax.post(url,fd,axiosConfig);
                if (result.status === 200) {
                    if (result.data.error==="OK"){
                        setisNext(true);
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(result.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'operazione! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                        setisSubmit(false);
                    }
                }
                else
                { 
                    const msg=GetMessage(result.status,result.data.message);
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                    setisSubmit(false);
                }
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            
        })();    
    };

    async function LoadDatiAzienda() {
        if(localStorage.getItem("idazienda")!==""){
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'customer/' + localStorage.getItem("idazienda");               
                result = await ax.post(url);
                setRagioneSociale(result.data[0].RagioneSociale);
                setDesRischioAteco(GetTextRischioAteco(result.data[0].Rischio));
                setCodiceAteco(result.data[0].CodiceAteco);
                setDesZona(GetTextZona(result.data[0].Zona));
                setCellulare(result.data[0].CellulareReferente);
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning");
                setVerticalAlert("center");
                setMsgAlert(msg);
                setOpenAlert(true);

            }
        }  
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    let urlRedirect="";
    
    if (CloseForm){
        if (localStorage.getItem("menunew")==="1")
        {
            urlRedirect="/home";
        }
        else {
            urlRedirect="/ordini";
        }
    }

    const onBackForm = (event) => {
        setisBack(true);
    };
    if (isBack && localStorage.getItem("idazienda") !=="" && localStorage.getItem("idordine") !==""){
        urlRedirect="/modificaordineservizi";    
    }

    if (isNext && localStorage.getItem("idordine") !=="" && localStorage.getItem("idazienda") !==""){
        urlRedirect="/nuovoordinedipendenti";    
    }

    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.bgHead}>
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Ordine - Legale Rappresentante
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={onBackForm}>
                        Indietro {isBack ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                    <MatButton autoFocus color="inherit" onClick={onConfirm} disabled={isSubmit}>
                        Avanti {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete="off">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                        <Grid container spacing={2} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCellulareReferente">Cellulare</Label>
                                                <Input type="text" name="txtCellulareReferente" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                                value={Cellulare} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtCodiceAteco">Cod. Ateco</Label>
                                                <Input type="text" name="txtCodiceAteco" id="txtCodiceAteco" placeholder=""
                                                value={CodiceAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtRischio">Rischio</Label>
                                                <Input type="text" name="txtRischio" autoComplete="new-password" placeholder=""
                                                value={DesRischioAteco} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtZona">Zona</Label>
                                                <Input type="text" name="txtZona" id="txtZona" placeholder=""
                                                value={DesZona} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <Label for="txtNome">Nome *</Label>
                                            <Input type="text" name="txtNome" autoComplete="new-password" placeholder="" 
                                            onBlur={() => validator.current.showMessageFor('Nome')}
                                            value={Nome} onChange={handleNome} 
                                            />
                                            {validator.current.message('Nome', Nome, 'required|string', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={4}>
                                            <Label for="txtCognome">Cognome *</Label>
                                            <Input type="text" name="txtCognome" autoComplete="new-password" placeholder="" 
                                            onBlur={() => validator.current.showMessageFor('Cognome')}
                                            value={Cognome} onChange={handleCognome} 
                                            />
                                            {validator.current.message('Cognome', Cognome, 'required|string', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={2}>
                                            <Label for="selSesso">Sesso *</Label>
                                            <Input type="select" name="selSesso" id="selSesso" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('Sesso')}
                                            value={Sesso} onChange={handleSesso}
                                            >
                                                <option key=""></option>
                                                <option key="M" value="M">M</option>
                                                <option key="F" value="F">F</option>
                                            </Input>
                                            {validator.current.message('Sesso', Sesso, 'required|alpha', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={2}>
                                            <Label for="txtDataNascita">D. Nascita *</Label>
                                            <MyDatePicker name="txtDataNascita" 
                                            onBlur={() => validator.current.showMessageFor('DataNascita')}
                                            value={DataNascita} onChange={handleDataNascita} autoComplete="new-password"
                                            />
                                            {validator.current.message('DataNascita', DataNascita, 'required|data', { className: 'text-danger' })}
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={4}>
                                            <Label for="txtNazioneNascita">Nazione Nascita *</Label>
                                            <MyAutoComplete id="txtNazioneNascita" name="txtNazioneNascita" 
                                            url={process.env.REACT_APP_API_URL+'nazioni'} 
                                            optionname="DesNazione" optionvalue="CodNazione"
                                            onBlur={() => validator.current.showMessageFor('NazioneNascita')}
                                            customvalue={NazioneNascita} onChange={handleNazioneNascita}
                                            />
                                            {validator.current.message('NazioneNascita', NazioneNascita, 'required|string', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={4}>
                                            <Label for="txtComuneNascita">Comune *</Label>
                                            {CodNazioneNascita> 0 && (
                                            <Input type="text" name="txtComuneNascitaEstero" autoComplete="new-password"
                                            onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                            value={ComuneNascita} onChange={handleComuneEsteroNascita}
                                            />)}
                                            {CodNazioneNascita=== 0 && (
                                            <MyAutoComplete id="txtComuneNascita" name="txtComuneNascita" 
                                            url={process.env.REACT_APP_API_URL+'comuni'} 
                                            optionname="Comune" optionvalue="CodComune"
                                            onBlur={() => validator.current.showMessageFor('ComuneNascita')}
                                            customvalue={ComuneNascita} onChange={handleComuneNascita}
                                            />)}
                                            {validator.current.message('ComuneNascita', ComuneNascita, 'required|string', { className: 'text-danger' })}
                                        </Col>     
                                        <Col md={3}>
                                            <Label for="txtCodiceFiscale">Codice Fiscale *</Label>
                                            <Input type="text" name="txtCodiceFiscale" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('CodFiscale')}
                                            value={CodFiscale} onChange={handleCodFiscale}
                                            />
                                            {validator.current.message('CodFiscale', CodFiscale, 'required|alpha_num|min:16', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={1}>
                                            <Label>&nbsp;</Label>
                                            <Button type="button" className="btn-mbg btn-small btn-block" onClick={CalcolaCodFiscale} >Calcola</Button>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <Label for="txtIndirizzo">Indirizzo Residenza *</Label>
                                            <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                            value={Indirizzo} onChange={handleIndirizzo}
                                            />
                                            {validator.current.message('Indirizzo', Indirizzo, 'required|string', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={6}>
                                            <Label for="txtComuneResidenza">Comune *</Label>
                                            <MyAutoComplete id="txtComuneResidenza" name="txtComuneResidenza" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                            onBlur={() => validator.current.showMessageFor('ComuneResidenza')}
                                            customvalue={ComuneResidenza} onChange={handleComuneResidenza}
                                            />
                                            {validator.current.message('ComuneResidenza', ComuneResidenza, 'required|string', { className: 'text-danger' })}
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={8}>
                                            <Label for="txtEmail">Email *</Label>
                                            <Input type="email" name="txtEmail" autoComplete="new-password" placeholder=""
                                            onBlur={() => validator.current.showMessageFor('Email')}
                                            value={Email} onChange={handleEmail}
                                            />
                                            {validator.current.message('Email', Email, 'required|email', { className: 'text-danger' })}
                                        </Col>
                                        <Col md={4}>
                                            <Label for="txtCellulare">Cellulare</Label>
                                            <Input type="text" name="txtCellulare" autoComplete="new-password" placeholder="(es. 0000-0000000)"
                                            onBlur={() => validator.current.showMessageFor('Cellulare')}
                                            value={CellulareResp} onChange={handleCellulareResp}
                                            />
                                            {validator.current.message('Cellulare', CellulareResp, 'cphone', { className: 'text-danger' })}
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>           
                                <TableContainer component={Paper} className={classes.container}>
                                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                                        <TableHead>            
                                            <TableRow>
                                                <TableCell className="grid-head" align="left">
                                                <Checkbox
                                                    checked={data.length > 0 && selected.length === data.length}
                                                    onChange={handleRowAllClick}
                                                    inputProps={{ 'aria-label': 'Seleziona Tutto' }}
                                                />
                                                </TableCell>
                                                <TableCell className="grid-head" align="center">Titolo</TableCell>
                                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            { data.length>0? (
                                                data.map((row) => {
                                                    const isItemSelected = isSelected(row.id);
                                                    const labelId = `row-${row.id}`;
                                                    
                                                    return (
                                                        
                                                    <TableRow key={row.id} hover tabIndex={-1} onClick={(event) => handleRowClick(row.id)}>
                                                        <TableCell>
                                                            <Checkbox
                                                                checked={isItemSelected}
                                                                inputProps={{ 'aria-labelledby': labelId }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>{row.Titolo}</TableCell>
                                                        <TableCell>{row.Descrizione}</TableCell>
                                                    </TableRow>
                                                    );
                                                })
                                            ):(
                                                <TableRow>
                                                    <TableCell colSpan={3} style={{textAlign:"center"}}><h7>- Nessun Servizio Associabile -</h7></TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        