import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AssignmentIcon from '@material-ui/icons/Assignment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import imgProvPagata from "../../img/Provigion3Min.jpg";
import imgProvPagataParziale from "../../img/Provigion1Min.jpg";
import imgProvDaPagare from "../../img/Provigion2Min.jpg";
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import {GetTextStatoOrdine,isDate,DateDiff, GetIDCommerciale,GetToken,GetRole,ccyFormat, get_years,GetItemStatoOrdine, GetItemLast10Year, GetGradoCommerciale} from "../../Modules/Funzioni";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import Avatar from '@material-ui/core/Avatar';
import MyDatePicker from '../MyDatePicker';
import { Redirect } from "react-router-dom";
import { Ruolo_Commerciale, Stato_DaApprovare, Ruolo_Ispettore, Ruolo_Admin, Grado_Direttore } from '../../Modules/Costanti';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ReactExport from "react-export-excel";
import Tooltip from '@material-ui/core/Tooltip';

const GetImponibileRow = (_PrezzoTotale, _Annullato) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_PrezzoTotale);
    }
    return ccyFormat(Prezzo);
}
const GetPrezzoRow = (_PrezzoTotale,_CodiceIva, _Annullato) =>{
    let PrezzoIvato=0;
    if (parseFloat(_Annullato)!==1){
        let Prezzo=parseFloat(_PrezzoTotale);
        PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100))
    }

    return ccyFormat(PrezzoIvato);
}
const GetProvvigioneRow = (_PrezzoTotale, _Annullato, _Provvigione) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_PrezzoTotale);
    }
    Prezzo=Prezzo*parseFloat(_Provvigione)/100;
    return ccyFormat(Prezzo);
}
const GetPagatoRow = (_PrezzoTotale,_CodiceIva, _Annullato,_Acconto,_Saldo) =>{
    let PrezzoIvato=0;
    if (parseFloat(_Annullato)!==1){
        if (parseFloat(_Saldo)===1){
            let Prezzo=parseFloat(_PrezzoTotale);
            PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100));

        }else{
            if (parseFloat(_Acconto)>0){
                PrezzoIvato +=parseFloat(_Acconto);
            }
        }
        
    }
    return ccyFormat(PrezzoIvato);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));

OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open, fileName } = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            <ListItem button onClick={() => handleListItemClick("Apri")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <AssignmentIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Ordine</ListItemText>
            </ListItem>
            <ListItem button onClick={() => handleListItemClick("NomineDL")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Nomine Leg. Rappresentante</ListItemText>
            </ListItem>
            <ListItem button onClick={() => handleListItemClick("NomineDIP")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <PeopleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Nomine Dipendenti</ListItemText>
            </ListItem>
            {
            fileName!=="" && fileName!==null  &&
            (<ListItem button onClick={() => handleListItemClick("Scarica")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica PDF Ordine</ListItemText>
            </ListItem>)
            }
            <ListItem button onClick={() => handleListItemClick("Duplica")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <NoteAddIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Duplica Ordine</ListItemText>
            </ListItem>
        </List>
      </Dialog>
    );
  }

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'NumServizi', string: false, disablePadding: true, label: 'Q.ta' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'Comm.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
];
const headCellsAdmin = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumOrdine', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataOrdineCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'NumServizi', string: false, disablePadding: true, label: 'Q.ta' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'PagatoTotale', string: false, disablePadding: true, label: 'Pagato' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
    { id: 'Comm', string: false, disablePadding: true, label: 'Comm.' },
    { id: 'ProvvigioneCommerciale', string: false, disablePadding: true, label: '% C' },
    { id: 'ProvvigioneDirettore', string: false, disablePadding: true, label: '% D' }
];

function GetHeadCells(_userRole,_GradoCommerciale){
    if (parseInt(_userRole)===Ruolo_Admin || _GradoCommerciale===Grado_Direttore){
        return headCellsAdmin;
    }
    else {
        return headCells;
    }
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
            {(() => {
                let _UserRole =GetRole(localStorage.getItem("token"));
                let _GradoCommerciale = GetGradoCommerciale(localStorage.getItem("token"));
                let _headCells=GetHeadCells(_UserRole, _GradoCommerciale);
               
                return(_headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                 )));
                })()}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
function ExportToExcelAdmin (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Ordini">
                <ExcelColumn label="N." value="NumOrdine"/>
                <ExcelColumn label="Data" value="Dataordine"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.Imponibile,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.Imponibile,col.CodiceIva,col.Annullato)}/>
                <ExcelColumn label="Pagato"
                             value={(col) => GetPagatoRow(col.Imponibile,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoOrdine(col.NumServiziNominali,
                                col.NumNomine,
                                col.Approvato,
                                col.Saldo,
                                col.Annullato,
                                col.Firmato,
                                col.InScadenza,
                                col.Scaduti,
                                col.NumServizi,
                                col.NumConsegnati,
                                col.NumServiziScaricabili)}/>
                <ExcelColumn label="Provv. Commerciale"
                    value={(col) => GetProvvigioneRow(col.Imponibile,col.Annullato,col.ProvvigioneCommerciale)}/>
                <ExcelColumn label="Provv. Direttore"
                    value={(col) => GetProvvigioneRow(col.Imponibile,col.Annullato,col.ProvvigioneDirettore)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}
function ExportToExcelCommerciale (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Ordini">
                <ExcelColumn label="N." value="NumOrdine"/>
                <ExcelColumn label="Data" value="Dataordine"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.Imponibile,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.Imponibile,col.CodiceIva,col.Annullato)}/>
                <ExcelColumn label="Pagato"
                             value={(col) => GetPagatoRow(col.Imponibile,col.CodiceIva,col.Annullato,col.Acconto,col.Saldo)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoOrdine(col.NumServiziNominali,
                                col.NumNomine,
                                col.Approvato,
                                col.Saldo,
                                col.Annullato,
                                col.Firmato,
                                col.InScadenza,
                                col.Scaduti,
                                col.NumServizi,
                                col.NumConsegnati,
                                col.NumServiziScaricabili)}/>
                <ExcelColumn label="Provv. Commerciale"
                    value={(col) => GetProvvigioneRow(col.Imponibile,col.Annullato,col.ProvvigioneCommerciale)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('desc');
  let tokenRicerca=localStorage.getItem("tokenRicercaOrdini");
  let tmpRicStato="";
  let tmpRicAnnoCompetenza=(new Date().getFullYear());
  let tmpRicNumRecord="100";
  let tmpRicIDAzienda_Resp="";
  let tmpRicAzienda_Resp="";
  let tmpRicTipoAzienda_Resp="0";
  let tmpRicIDCommerciale="";
  let tmpRicCommerciale="";
  let tmpRicPagato="";
  let tmpRicDataOrdineInizio="";
  let tmpRicDataOrdineFine="";
  let tmpRicSubalterno=false;
  let tmpRicUrlAzienda_Resp=process.env.REACT_APP_API_URL+"customersinglelist";
  let tmpRicOptionValue="id";
  let tmpRicOptionName="RagioneSociale"
  let tmpPage="0";
  let tmpRicTipoLuogo="0";
  let tmpRicLuogo="";
  let tmpRicUrlLuogo=process.env.REACT_APP_API_URL+"province";
  let tmpRicOptionLuogoName="Provincia";
  let tmpRicOptionLuogoValue="CodProv";
    if (tokenRicerca!=="" && tokenRicerca!==null){
        let mtxTokenRicerca=tokenRicerca.split("|");
        tmpRicStato=mtxTokenRicerca[0];
        tmpRicIDAzienda_Resp=mtxTokenRicerca[1];
        tmpRicAzienda_Resp=mtxTokenRicerca[2];
        tmpRicIDCommerciale=mtxTokenRicerca[3];
        tmpRicCommerciale=mtxTokenRicerca[4];
        tmpRicPagato=mtxTokenRicerca[5];
        tmpRicDataOrdineInizio=mtxTokenRicerca[6];
        tmpRicDataOrdineFine=mtxTokenRicerca[7];
        tmpRicSubalterno=mtxTokenRicerca[8]==="true";
        tmpPage=parseInt(mtxTokenRicerca[9]);
        tmpRicTipoAzienda_Resp=mtxTokenRicerca[10];
        tmpRicAnnoCompetenza=mtxTokenRicerca[11];
        tmpRicNumRecord=mtxTokenRicerca[12];
        tmpRicTipoLuogo=mtxTokenRicerca[13];
        tmpRicLuogo=mtxTokenRicerca[14];
        tmpRicUrlLuogo=mtxTokenRicerca[15];
        tmpRicOptionLuogoName=mtxTokenRicerca[16];
        tmpRicOptionLuogoValue=mtxTokenRicerca[17];

    }
    if (tmpRicTipoAzienda_Resp==="1"){
        tmpRicUrlAzienda_Resp=process.env.REACT_APP_API_URL+"responsabilesinglelist";
        tmpRicOptionName="Descrizione";
    }

  const [RicTextLuogo, setRicTextLuogo] = React.useState(tmpRicLuogo);
  const [RicTipoLuogo, setRicTipoLuogo] = React.useState(tmpRicTipoLuogo);
  const [RicLuogo, setRicLuogo] = React.useState(tmpRicLuogo);
  const [RicOptionLuogoValue, setRicOptionLuogoValue] = useState(tmpRicOptionLuogoValue);
  const [RicOptionLuogoName, setRicOptionLuogoName] = useState(tmpRicOptionLuogoName);
  const [RicUrlLuogo, setRicUrlLuogo] = useState(tmpRicUrlLuogo);
  const [ProvvCommTotale, setProvvCommTotale] = useState(0);
  const [ProvvDirTotale, setProvvDirTotale] = useState(0);
  const [NewOrdine, setNewOrdine] = React.useState(false);
  const [RicAzienda_Resp, setRicAzienda_Resp] = React.useState(tmpRicAzienda_Resp);
  const [RicIDAzienda_Resp, setRicIDAzienda_Resp] = React.useState(tmpRicIDAzienda_Resp);
  const [RicCommerciale, setRicCommerciale] = React.useState(tmpRicCommerciale);
  const [RicSubalterno, setRicSubalterno] = React.useState(tmpRicSubalterno);
  const [RicIDCommerciale, setRicIDCommerciale] = React.useState(tmpRicIDCommerciale);
  const [RicStato, setRicStato] = React.useState(tmpRicStato);
  const [RicPagato, setRicPagato] = React.useState(tmpRicPagato);
  const [RicDataOrdineInizio, setRicDataOrdineInizio] = React.useState(tmpRicDataOrdineInizio);
  const [RicDataOrdineFine, setRicDataOrdineFine] = React.useState(tmpRicDataOrdineFine);
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(tmpPage);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(20);  
  const [dense, setDense] = React.useState(false);
  const [ModOrdine, setModOrdine] = useState(false);
  const [DuplicaOrdine, setDuplicaOrdine] = useState(false);
  const [NominaLegRappr, setNominaLegRappr] = useState(false);
  const [NominaDip, setNominaDip] = useState(false);
  const [SelIdOrdine, setSelIdOrdine] = useState("");
  const [SelIdAzienda, setSelIdAzienda] = useState("");
  const [SelNumOrdine, setSelNumOrdine] = useState("");
  const [SelDataOrdine, setSelDataOrdine] = useState("");
  const [SelFileName,setSelFileName]=useState("");
  const [RicAnnoCompetenza, setRicAnnoCompetenza] = React.useState(tmpRicAnnoCompetenza);
  const [RicNumRecord, setRicNumRecord] = React.useState(tmpRicNumRecord);
  const [PrezzoTotale, setPrezzoTotale] = useState(0);
  const [ImponibileTotale, setImponibileTotale] = useState(0);
  const [IncassoTotale, setIncassoTotale] = useState(0);
  const [OpenOperDialog,setOpenOperDialog]=useState(false);
  
  const [RicTipoCustomer, setRicTipoCustomer] = React.useState(tmpRicTipoAzienda_Resp);
  const [RicOptionValue, setRicOptionValue] = useState(tmpRicOptionValue);
  const [RicOptionName, setRicOptionName] = useState(tmpRicOptionName);
  const [RicUrlCustomer, setRicUrlCustomer] = useState(tmpRicUrlAzienda_Resp);
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
const UserRole =GetRole(localStorage.getItem("token"));
const GradoCommerciale =GetGradoCommerciale(localStorage.getItem("token"));

function GetIconCommissione(SaldoCommerciale,SaldoDirettore){
    let mIcon=imgProvDaPagare;
    if (SaldoCommerciale===1 && SaldoDirettore===1){
        mIcon=imgProvPagata;
    }
    else {
        if (SaldoCommerciale===1 || SaldoDirettore===1){
            mIcon=imgProvPagataParziale;
        }
    }
    return <img style={{height:"15pt"}} src={mIcon} className="date-picker-icon" alt=""/>;
}

  //-----------
  const toggleNewOrdine = () => {
    resetStorage();
    setNewOrdine(true);
  }

  const toggleOperDialog = (_IdOrdine,_IdAzienda,_FileName,_NumOrdine,_DataOrdine) => {
    resetStorage();
    setSelIdOrdine(_IdOrdine);
    setSelIdAzienda(_IdAzienda);
    setSelFileName(_FileName);
    setSelNumOrdine(_NumOrdine);
    setSelDataOrdine(_DataOrdine);
    setOpenOperDialog(true);
  }
  
  
  const resetStorage = () =>{
    localStorage.setItem("idordine", "");
    localStorage.setItem("idazienda", "");
    localStorage.setItem("menunew", "0");
    localStorage.setItem("tokenRicercaOrdini", RicStato + "|" + RicIDAzienda_Resp + "|" + 
    RicAzienda_Resp + "|" + RicIDCommerciale + "|" + RicCommerciale + "|" + RicPagato + "|" + 
    RicDataOrdineInizio + "|" + RicDataOrdineFine + "|" + RicSubalterno + "|" + page + "|" +
    RicTipoCustomer + "|" + RicAnnoCompetenza + "|" + RicNumRecord + "|" + RicTipoLuogo + "|" + RicLuogo + "|" +
    RicUrlLuogo + "|" + RicOptionLuogoName  + "|" + RicOptionLuogoValue);
  }

  
  const DownloadDocumento = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelFileName);
            fd.append("id", SelIdOrdine);
            let url=process.env.REACT_APP_API_URL+'docordine'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'ordine_' + SelNumOrdine + '_' + get_years(SelDataOrdine) + '.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }


  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'ordinelist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                if (parseFloat(IDCommerciale)>0  && (UserRole===Ruolo_Commerciale || UserRole===Ruolo_Ispettore)){
                    fd.append("Commerciale_id", IDCommerciale);    
                }
                fd.append("RicCommerciale_id", RicIDCommerciale);
                if (RicTipoCustomer==="0"){
                    fd.append("IDAzienda", RicIDAzienda_Resp);
                }
                else {
                    fd.append("Responsabile_id", RicIDAzienda_Resp);
                }
                fd.append("DataOrdineInizio", RicDataOrdineInizio);
                fd.append("DataOrdineFine", RicDataOrdineFine);
                fd.append("AnnoCompetenza", RicAnnoCompetenza);
                fd.append("LastNumberData", RicNumRecord);
                fd.append("RicLuogo", RicLuogo);
                fd.append("RicTipoLuogo", RicTipoLuogo);
                fd.append("Stato", RicStato);
                if (RicSubalterno===true)  fd.append("Subalterno", "1");
                fd.append("Pagato", RicPagato);
                                
                result = await ax.post(url,fd,axiosConfig);
                localStorage.setItem("tokenRicercaOrdini","");
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
            CalcolaTotale(result.data);
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 
const handleRicLuogo = (value,text) => {
    setRicTextLuogo(text);
    setRicLuogo(value);
    setDisableFilter(false);
};
const handleRicTipoLuogo = (event) => { 
    setRicTipoLuogo(event.target.value);
    
    setRicLuogo("");
    setRicTextLuogo("");
    if (event.target.value==="0"){
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"province");
        setRicOptionLuogoValue("CodProv");
        setRicOptionLuogoName("Provincia");
    }
    else
    {
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"regioni");
        setRicOptionLuogoValue("CodRegione");
        setRicOptionLuogoName("NomeRegione");
    }
    
    setDisableFilter(false);
};

const handleRicAzienda_Resp = (value,text) => {
    setRicAzienda_Resp(text);
    setRicIDAzienda_Resp(value);;
    setDisableFilter(false);
};

const handleRicCommerciale = (value,text) => {
    setRicCommerciale(text);
    setRicIDCommerciale(value);;
    if (value===""){
        setRicSubalterno(false);;
    }
    setDisableFilter(false);
};

const handleRicSubalterno = (value) => {  
    setRicSubalterno(!RicSubalterno); 
   
    setDisableFilter(false);
};

const handleRicDataOrdineInizio = (value) => {
    setRicDataOrdineInizio(value); 
    if (isDate(RicDataOrdineFine)===""){
        if (DateDiff(value,RicDataOrdineFine)<0){
            setRicDataOrdineFine("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataOrdineFine = (value) => {  
    setRicDataOrdineFine(value);  
    if (isDate(RicDataOrdineInizio)===""){
        if (DateDiff(RicDataOrdineInizio,value)<0){
            setRicDataOrdineInizio("");
        }
    }
    setDisableFilter(false);
};

const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};
const handleRicAnnoCompetenza = (event) => {
    setRicAnnoCompetenza(event.target.value);
    setDisableFilter(false);
};
const handleRicNumRecord = (event) => {
    setRicNumRecord(event.target.value);
    setDisableFilter(false);
};
const handleRicPagato = (event) => {
    setRicPagato(event.target.value);
    setDisableFilter(false);
};

const CalcolaTotale = (_mdata)=>{
    let newPrezzoTotale=0;
    let newPrezzoParziale=0;
    let newImponibileTotale=0;
    let newImponibileParziale=0;
    let newParzialeIncassato=0;
    let newTotaleIncassato=0;
    let newProvvCommTotale=0;
    let newProvvCommParziale=0;
    let newProvvDirTotale=0;
    let newProvvDirParziale=0;
    
    if (_mdata!==undefined){

        for (const item of _mdata) {
            if (parseFloat(item.Annullato)!==1 && parseFloat(item.Approvato)===1){
                newPrezzoParziale=parseFloat(item.Imponibile) + (parseFloat(item.Imponibile)*parseFloat(item.CodiceIva/100));
                newPrezzoTotale +=newPrezzoParziale;

                newImponibileParziale=parseFloat(item.Imponibile);
                newImponibileTotale +=newImponibileParziale;

                newProvvCommParziale=newImponibileParziale * parseFloat(item.ProvvigioneCommerciale)/100;
                newProvvCommTotale +=newProvvCommParziale;

                newProvvDirParziale=newImponibileParziale * parseFloat(item.ProvvigioneDirettore)/100;
                newProvvDirTotale +=newProvvDirParziale;

                if (parseFloat(item.Saldo)===1){
                    newParzialeIncassato=parseFloat(item.Imponibile) + (newParzialeIncassato*parseFloat(item.CodiceIva/100));
                    newTotaleIncassato +=newParzialeIncassato;


                }else{
                    if (parseFloat(item.Acconto)>0){
                        newTotaleIncassato +=parseFloat(item.Acconto);
                    }
                }
            }
        }
        setPrezzoTotale(newPrezzoTotale);
        setImponibileTotale(newImponibileTotale);
        setIncassoTotale(newTotaleIncassato);
        setProvvCommTotale(newProvvCommTotale);
        setProvvDirTotale(newProvvDirTotale);
    }
}
const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "Apri":
            {
                setModOrdine(true);
                break;
            }
        case "Duplica":
            {
                setDuplicaOrdine(true);
                break;
            }
        case "Scarica":
            {
                DownloadDocumento();
                break;
            }
        case "NomineDL":
            {
                setNominaLegRappr(true);
                break;
            }
        case "NomineDIP":
            {
                setNominaDip(true);
                break;
            }
        default:

    }
};
const handleRicTipoCustomer = (event) => { 
    setRicTipoCustomer(event.target.value);
    
    setRicAzienda_Resp("");
    if (event.target.value==="0"){
        setRicUrlCustomer(process.env.REACT_APP_API_URL+"customersinglelist");
        setRicOptionValue("id");
        setRicOptionName("RagioneSociale");
    }
    else
    {
        setRicUrlCustomer(process.env.REACT_APP_API_URL+"responsabilesinglelist");
        setRicOptionValue("id");
        setRicOptionName("Descrizione");
    }
    
    setDisableFilter(false);
};
const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};
let emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) -1;
    if (emptyRows>5){emptyRows=3}

    if (NewOrdine){
        const urlRedirect="/nuovoordineazienda";
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
    if (ModOrdine){
        const urlRedirect="/modificaordineservizi";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idordine", SelIdOrdine);
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
    if (DuplicaOrdine){
        const urlRedirect="/nuovoordineservizi";
        localStorage.setItem("idazienda", SelIdAzienda);
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
    if (NominaLegRappr){
        const urlRedirect="/nuovoordineresponsabili";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idordine", SelIdOrdine);
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }
    if (NominaDip){
        const urlRedirect="/nuovoordinedipendenti";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idordine", SelIdOrdine);
        //const urlRedirect="/nuovoordineresponsabili/17/20";
        return <Redirect to={urlRedirect} />;
    }

    let IDCommerciale=GetIDCommerciale(localStorage.getItem("token"));
    if (parseFloat(IDCommerciale)===0 || RicTipoCustomer!=="0"){
        IDCommerciale="";
    }
  return (  
    
    <div className={classes.root}>
        <OperationDialog classes={classes} onClose={onCloseOperDialog} onSelected={onSelectedItemOperDialog} open={OpenOperDialog} fileName={SelFileName}/>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        <div className="small" style={{width:"98%"}}>
            <Row>
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicAnnoCompetenza">Anno</Label>
                        <Input type="select" className="search" name="selRicAnnoCompetenza" 
                        id="selRicAnnoCompetenza" placeholder="" bsSize="sm"
                            value={RicAnnoCompetenza} onChange={handleRicAnnoCompetenza}>
                                {GetItemLast10Year().map((item) => (
                                    <option key={item.Anno} value={item.Anno}>{item.Anno}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicNumRecord">Ultimi Ordini</Label>
                        <Input type="select" className="search" name="selRicNumRecord" 
                        id="selRicNumRecord" placeholder="" bsSize="sm"
                            value={RicNumRecord} onChange={handleRicNumRecord}>
                                <option key="100" value="100">100</option>
                                <option key="250" value="250">250</option>
                                <option key="500" value="500">500</option>
                                <option key="750" value="750">750</option>
                                <option key="1000" value="1000">1000</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={3} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicStato">Stato</Label>
                        <Input type="select" className="search" name="selRicStato" 
                        id="selRicStato" placeholder="" bsSize="sm"
                            value={RicStato} onChange={handleRicStato}>
                                <option key="" value="">Tutti</option>
                                {GetItemStatoOrdine().map((item) => (
                                    <option key={item.stato_value} value={item.stato_value}>{item.stato_desc}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={6} lg={5} md={6} xs={12}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                <input type="radio" name="radioRicCustomer" value="0" onClick={handleRicTipoCustomer} />
                                    &nbsp;Azienda
                                </label>
                           </Col> 
                           <Col md="6">
                            <label>
                                <input type="radio" name="radioRicCustomer" value="1" onClick={handleRicTipoCustomer}/>
                                &nbsp;Leg. Rappresentante
                            </label>
                           </Col> 
                        </Row>
                        <MyAutoComplete id="txtRicAzienda_Resp" name="txtRicAzienda_Resp" 
                            idparent={IDCommerciale} 
                            url={RicUrlCustomer} 
                            optionname={RicOptionName} 
                            optionvalue={RicOptionValue}
                            customvalue={RicAzienda_Resp} 
                            onChange={handleRicAzienda_Resp} 
                            className="MuiAutocomplete-inputsm-search"
                        />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={4} lg={4} md={6} xs={12}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                    Commerciale
                                </label>
                           </Col> 
                           {
                            RicCommerciale!=="" && (
                           <Col md="6">
                                <label style={{fontSize:'15px'}}>
                                    <input type="checkbox" name="chkRicSubalterno" onChange={handleRicSubalterno} checked={RicSubalterno} /> {'   '}
                                    Includi Subalterni
                                </label>
                           </Col> )
                           }
                        </Row>
                        <MyAutoComplete id="txtRicCommerciale" name="txtRicCommerciale" 
                        url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                        idparent={IDCommerciale}
                        optionname="DesCommerciale" optionvalue="id"
                        customvalue={RicCommerciale} onChange={handleRicCommerciale} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                
                <Col xl={2} lg={2} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicStato">Pagamento</Label>
                        <Input type="select" className="search" name="selRicPagato" 
                        id="selRicPagato" placeholder="" bsSize="sm"
                            value={RicPagato} onChange={handleRicPagato}>
                                <option key="" value="">Tutti</option>
                                <option key="1" value="1">Da Pagare</option>
                                <option key="2" value="2">Pag. Parziali</option>
                                <option key="3" value="3">Saldati</option>
                                <option key="4" value="4">Da Saldare Comm.</option>
                                <option key="5" value="5">Da Saldare Dir.</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={3} lg={3} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataOrdineInizio">Da Data</Label>
                        <MyDatePicker name="txtRicDataOrdineInizio" className="form-control-sm"
                            value={RicDataOrdineInizio} onChange={handleRicDataOrdineInizio} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col>
                <Col xl={3} lg={3} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataOrdineFine">A Data</Label>
                        <MyDatePicker name="txtRicDataOrdineFine" className="form-control-sm" 
                            value={RicDataOrdineFine} onChange={handleRicDataOrdineFine} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col> 
                <Col xl={4} lg={5} md={4} xs={12}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                <input type="radio" name="radioRicLuogo" value="0" onClick={handleRicTipoLuogo} />
                                    &nbsp;Provincia
                                </label>
                           </Col> 
                           <Col md="6">
                            <label>
                                <input type="radio" name="radioRicLuogo" value="1" onClick={handleRicTipoLuogo}/>
                                &nbsp;Regione
                            </label>
                           </Col> 
                        </Row>
                        <MyAutoComplete id="txtRicLuogo" name="txtRicLuogo" 
                            url={RicUrlLuogo} 
                            optionname={RicOptionLuogoName} optionvalue={RicOptionLuogoValue}
                            customvalue={RicTextLuogo} 
                            onChange={handleRicLuogo} 
                            className="MuiAutocomplete-inputsm-search"
                        />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            {(parseFloat(IDCommerciale)>0 && RicStato!==Stato_DaApprovare) ? 
            (<Grid item xs={12}>
                <Row>
                    &nbsp;
                </Row>
                <Row>
                    <Col xs={6} md={4} lg={2} xl={1}><Button onClick={toggleNewOrdine} className="btn-mbg">Nuovo</Button></Col>
                    <Col xs={6} md={4} lg={2} xl={1}>{UserRole ===Ruolo_Admin || GradoCommerciale === Grado_Direttore ? <ExportToExcelAdmin data={data}/> : <ExportToExcelCommerciale data={data}/> }</Col>
                    <Col lg={1} xl={1}></Col>
                    <Col lg={2} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Imponibile &euro; {ccyFormat(ImponibileTotale)} </b></div></Col>
                    <Col lg={2} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Totale &euro; {ccyFormat(PrezzoTotale)} </b></div></Col>
                    <Col lg={2} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Pagato &euro; {ccyFormat(IncassoTotale)} </b></div></Col>
                </Row>
                {
                    (RicPagato==="4" || RicPagato==="5") && (
                        <>
                            <Row>
                                &nbsp;
                            </Row>
                            <Row>
                                <Col xs={12} md={9} lg={5} xl={3}>&nbsp;</Col>
                                <Col xs={12} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Commerciali &euro; {ccyFormat(ProvvCommTotale)} </b></div></Col>
                                {(UserRole ===Ruolo_Admin || GradoCommerciale === Grado_Direttore) && (<Col xs={12} lg={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> Provv. Direttori &euro; {ccyFormat(ProvvDirTotale)} </b></div></Col>)}
                            </Row>
                            <Row>
                                &nbsp;
                            </Row> 
                        </>
                    )
                }
            </Grid>):(
            <Row>
                <Col xs={6} md={4} lg={2} xl={1}>{UserRole ===Ruolo_Admin ? <ExportToExcelAdmin data={data}/> : <ExportToExcelCommerciale data={data}/> }</Col>
            </Row>
            )}
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleOperDialog(row.id,row.Azienda_id,row.File_Name,row.NumOrdine,row.DataOrdine)}>
                                        <MoreVertIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.NumOrdine}</TableCell>
                                <TableCell>{row.DataOrdine}</TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{row.DesCommerciale}</TableCell>
                                <TableCell>{row.NumServizi}</TableCell>
                                <TableCell>{GetImponibileRow(row.Imponibile,row.Annullato)}</TableCell>
                                <TableCell>{row.CodiceIva}</TableCell>
                                <TableCell>{GetPrezzoRow(row.Imponibile,row.CodiceIva,row.Annullato)}</TableCell>
                                <TableCell>{GetPagatoRow(row.Imponibile,row.CodiceIva,row.Annullato,row.Acconto,row.Saldo)}</TableCell>
                                <TableCell>{GetTextStatoOrdine(row.NumServiziNominali,
                                                               row.NumNomine,
                                                               row.Approvato,
                                                               row.Saldo,
                                                               row.Annullato,
                                                               row.Firmato,
                                                               row.InScadenza,
                                                               row.Scaduti,
                                                               row.NumServizi,
                                                               row.NumConsegnati,
                                                               row.NumServiziScaricabili)}</TableCell>
                                <TableCell style={{textAlign:'center'}}>
                                    <Tooltip title="Stato pagamento provvigioni" aria-label="Stato pagamento provvigioni">{GetIconCommissione(row.SaldoCommerciale,row.SaldoDirettore)}</Tooltip>
                                </TableCell>
                                <TableCell>{GetProvvigioneRow(row.Imponibile,row.Annullato,row.ProvvigioneCommerciale)}</TableCell>
                                {(UserRole ===Ruolo_Admin || GradoCommerciale === Grado_Direttore) && (<TableCell>{GetProvvigioneRow(row.Imponibile,row.Annullato,row.ProvvigioneDirettore)}</TableCell>)}                               
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={14} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[20, 40, 60]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 