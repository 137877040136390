import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, Col, Row, Input, FormGroup, Label } from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyAlert from '../../Components/MyAlert';
import MyAlertSelServizio from '../../Components/MyAlert';
import { GetMessage } from "../../Modules/Message";
import { GetToken, VerifyPDF, GetRole, ccyFormat, VerifyImage } from "../../Modules/Funzioni";
import { Redirect } from "react-router-dom";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import imgSearch from "../../img/search.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyConfirmDelServizio from '../../Components/MyConfirm';
import SignCanvas from 'react-signature-canvas'
import { Ruolo_Admin } from '../../Modules/Costanti';
import MyDatePicker from '../../Components/MyDatePicker';
import MyAutoComplete from '../../Components/MyAutoComplete';
import MyPhoneInput from "../../Components/MyPhoneInput";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
    },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
    },

    BtnEdit: {
        '&:hover': {
            cursor: "pointer"
        }
    }
}));
function CreateSign(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [Signed, setSigned] = React.useState(false);
    const mySign = useRef({});

    const onConfirm = () => {
        props.handleConfirm(mySign.current.getTrimmedCanvas().toDataURL("image/png"));
    }

    const onClear = () => {
        setSigned(false);
        mySign.current.clear();
    }

    const onSign = () => {
        setSigned(true);
    }

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
        >
            <DialogContent className={classes.FormPopup} >
                <SignCanvas ref={mySign} canvasProps={{ className: 'signCanvas' }} onEnd={onSign} />
            </DialogContent>
            <DialogActions>
                <div style={{ width: "30%" }}>
                    <Button onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={!Signed} readOnly={!Signed}>
                        Conferma
                    </Button>
                </div>
                <div style={{ width: "30%" }}>
                    <Button onClick={props.handleClose} className="btn-block btn btn-secondary">
                        Annulla
                    </Button>
                </div>
                <div style={{ width: "30%" }}>
                    <Button onClick={onClear} className="btn-mbg1 btn-block" disabled={!Signed} readOnly={!Signed}>
                        Cancella
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);


    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {
        setRicDescrizione(event.target.value);
        setDisableFilter(false);
    };

    const handleRowClick = (_id, _descrizione, _prezzo) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { id: _id, Descrizione: _descrizione, Prezzo: _prezzo, Quantita: 1 },
        ];
        setSelectedData(ArrayRis);
    };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    };

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }

    React.useEffect(() => {
        setSelected("");
    }, [props.open]);

    useEffect(() => {

        if (isSending) {
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;

            (async () => {
                let result = [];
                try {
                    let url = process.env.REACT_APP_API_URL + 'serviziosalist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);

                    result = await ax.post(url, fd, axiosConfig);
                }
                catch (error) {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);
                if (result.data !== undefined) {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })();
        }

    }, []);


    const isSelected = (name) => selected === name;

    return (
        <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="responsive-dialog-title"
            maxWidth="xl"
        >
            <DialogTitle id="responsive-dialog-title">
                <div className="small" style={{ width: "98%" }}>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                                <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                                    value={RicDescrizione} onChange={handleRicDescrizione}
                                    bsSize="sm" />
                                <img style={{ height: "15pt" }} src={imgSearch} className="input-icon" alt="" />
                            </FormGroup>
                        </Col>
                        <Col xs={2}>
                            <FormGroup>
                                <Label bsSize="sm">&nbsp;</Label>
                                <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </DialogTitle>
            <DialogContent className={classes.FormPopup} >
                <DialogContentText>
                    <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert} />
                    {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                    <TableCell className="grid-head" align="center">Prezzo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.length > 0 ? (
                                    data.map((row) => {
                                        const isItemSelected = isSelected(row.id);

                                        return (

                                            <TableRow key={row.id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.id, row.Descrizione, row.Prezzo)}>
                                                <TableCell>{row.Descrizione}</TableCell>
                                                <TableCell align="right">{row.Prezzo}</TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} style={{ textAlign: "center" }}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div style={{ width: "30%" }}>
                    <Button onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={selected === ""}>
                        Conferma
                    </Button>
                </div>
                <div style={{ width: "30%" }}>
                    <Button onClick={props.handleClose} className="btn-block">
                        Annulla
                    </Button>

                </div>
            </DialogActions>
        </Dialog>
    );
}

function CreateTableServizi(props) {
    const classes = useStyles();
    const [Rows, setRows] = React.useState([]);
    const [PercSconto, setPercSconto] = React.useState(0);
    const [PercIva, setPercIva] = React.useState(0);
    const [CostoScontato, setCostoScontato] = React.useState(0);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [DescrizioneSpesa, setDescrizioneSpesa] = React.useState("");
    const [IDSpesa, setIDSpesa] = React.useState(0);
    const [SpeseKm, setSpeseKm] = React.useState(0);
    const [NumKm, setNumKm] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoIva, setCostoIva] = React.useState(0);
    const [ApplicaSconto, setApplicaSconto] = React.useState(0);
    const [VisualizzaCheckSconto, setVisualizzaCheckSconto] = React.useState(false);

    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert, setMsgAlert] = React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };

    const handleClickAltro = () => {
        setVisualizzaCheckSconto(!VisualizzaCheckSconto);
    }
    const handleApplicaSconto = (event) => {
        setApplicaSconto(!ApplicaSconto);
        setPercSconto(0);
        CalcolaPrezzi(0, PercIva, NumKm, CostoSpesa);
        props.onChangeSconto(0);
    }
    const handlePercSconto = (event) => {
        if (parseFloat(event.target.value) > 100) {
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _PercSconto = parseFloat(event.target.value);
        if (isNaN(_PercSconto)) _PercSconto = 0;
        setPercSconto(_PercSconto);
        CalcolaPrezzi(_PercSconto, PercIva, NumKm, CostoSpesa);
        props.onChangeSconto(_PercSconto);

    }
    const handleFocusPercSconto = (event) => {
        event.target.select();
    }
    const handlePercIva = (event) => {
        if (parseFloat(event.target.value) > 100) {
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _PercIva = parseFloat(event.target.value);
        if (isNaN(_PercIva)) _PercIva = 0;
        setPercIva(_PercIva);
        CalcolaPrezzi(PercSconto, _PercIva, NumKm, CostoSpesa);
        props.onChangeIva(_PercIva);

    }
    const handleTipoSpesa = (value,text) => {
        if (value!==""){
            var arrValue = value.split("|");
            setDescrizioneSpesa(text);  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,arrValue[1]);
            props.onChangeCostoSpesa(arrValue[1],arrValue[0]);
        }
        else
        {
            setDescrizioneSpesa("");  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,0);
            props.onChangeCostoSpesa(0,0);
        }
    }; 
    const handleNumKm = (event) => {
        if (parseFloat(event.target.value) > 10000) {
            setMsgAlert("Valore massimo: 10000");
            setOpenAlert(true);
            event.target.value = 0;

        }
        let _NumKm = parseFloat(event.target.value);
        if (isNaN(_NumKm)) _NumKm = 0;
        setNumKm(_NumKm);
        CalcolaPrezzi(PercSconto, PercIva, _NumKm, CostoSpesa);
        props.onChangeNumKm(_NumKm);

    };

    const handleFocusPercIva = (event) => {
        event.target.select();
    };
    const handleFocusNumKm = (event) => {
        event.target.select();
    };

    const handleQuantita = (_num, _id) => {
        if (props.rows.length > 0) {
            props.rows.map((item) => {
                if (item.id === _id) {
                    item.Quantita = _num;
                    props.onChangeQuantita();
                }
                return true;
            });
        }
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa);
    }
    const CalcolaPrezzi = (_PercSconto, _PercIva, _NumKm, _CostoSpesa) => {
        let newCostoImponibile = 0;
        let newCostoScontato = 0;
        let newCostoSpesa=parseFloat(_CostoSpesa);
        let newCostoImpianto = parseFloat(props.CostoImpianto);
        let newCostoImpiantoExp = parseFloat(props.CostoImpiantoExp);
        let newCostoSpeseKm = _NumKm * props.CostoPerKm;
        let newCostoTotale = 0;
        let newCostoIva = 0;
        if (props.rows.length > 0) {
            props.rows.map((item) => {
                newCostoImponibile += (parseFloat(item.Prezzo) * parseFloat(item.Quantita));
                return newCostoImponibile;
            });
        }

        newCostoScontato = ((newCostoImponibile - (newCostoImponibile * (_PercSconto / 100))) + (newCostoImpianto - (newCostoImpianto * (_PercSconto / 100))) + (newCostoImpiantoExp - (newCostoImpiantoExp * (_PercSconto / 100))));

        newCostoScontato = parseFloat(newCostoScontato.toFixed(2));
        newCostoIva = newCostoScontato * (parseFloat(_PercIva) / 100);
        newCostoIva = parseFloat(newCostoIva.toFixed(2));
        newCostoSpesa=parseFloat(newCostoSpesa.toFixed(2));
        newCostoSpeseKm = parseFloat(newCostoSpeseKm.toFixed(2));
        newCostoTotale = newCostoIva + newCostoScontato + newCostoSpeseKm  + newCostoSpesa;
        setCostoImponibile(newCostoImponibile);
        setSpeseKm(newCostoSpeseKm);
        setCostoSpesa(newCostoSpesa);
        setCostoScontato(newCostoScontato);
        setCostoIva(newCostoIva);
        setCostoTotale(newCostoTotale);
        props.onChangeCostoTotale(newCostoTotale);
    }
    React.useEffect(() => {
        setRows(props.rows);
        //alert("state rows");
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa);
    }, [props.rows]);

    React.useEffect(() => {
        CalcolaPrezzi(PercSconto, PercIva, NumKm, CostoSpesa);
    }, [props.onRicalcola]);

    React.useEffect(() => {
        if (parseFloat(props.setSconto) > 0) {
            handleClickAltro();
            setApplicaSconto(!ApplicaSconto);
        }
        setPercSconto(props.setSconto);
        CalcolaPrezzi(props.setSconto, PercIva, NumKm, CostoSpesa);
    }, [props.setSconto]);

    React.useEffect(() => {
        setPercIva(props.setIva);
        CalcolaPrezzi(PercSconto, props.setIva, NumKm, CostoSpesa);
    }, [props.setIva]);

    React.useEffect(() => {
        setCostoSpesa(props.CostoSpesa);
        CalcolaPrezzi(PercSconto, PercIva, NumKm, props.CostoSpesa);
    }, [props.CostoSpesa]);

    React.useEffect(() => {
        setIDSpesa(props.setIDSpesa);
    }, [props.IDSpesa]);

    React.useEffect(() => {
        setDescrizioneSpesa(props.DescrizioneSpesa);
    }, [props.DescrizioneSpesa]);

    React.useEffect(() => {
        setNumKm(props.setNumKm);
        CalcolaPrezzi(PercSconto, PercIva, props.setNumKm, CostoSpesa);
    }, [props.setNumKm]);

    return (

        <TableContainer component={Paper}>
            <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert} />
            <Table className={classes.table} aria-label="spanning table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className="grid-head"></TableCell>
                        <TableCell className="grid-head">Descrizione</TableCell>
                        <TableCell className="grid-head" align="right">Prezzo</TableCell>
                        <TableCell className="grid-head">Q.ta</TableCell>
                        <TableCell className="grid-head" align="right">Importo</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Rows.length > 0 ? (Rows.map((row) => {
                        return (
                            <TableRow key={row.id} className="rt-tr-group">
                                <TableCell>
                                    {
                                        (props.isAdmin === true || props.isFirmato === false) && (
                                            <Avatar className={"btn-mbg-cert " + classes.BtnEdit} onClick={() => props.onDelete(row.id)}>
                                                <DeleteIcon />
                                            </Avatar>
                                        )
                                    }
                                </TableCell>
                                <TableCell>{row.Descrizione}</TableCell>
                                <TableCell align="right">{row.Prezzo}</TableCell>
                                <TableCell>
                                    {
                                        (props.isAdmin === true || props.isFirmato === false) ?
                                            (<MyNumInput otherparam={row.id} value={row.Quantita} onChange={handleQuantita} />) : (row.Quantita)
                                    }
                                </TableCell>
                                <TableCell align="right">{ccyFormat(parseFloat(row.Prezzo) * parseFloat(row.Quantita))}</TableCell>
                            </TableRow>
                        );
                    }
                    )) : (
                        <TableRow>
                            <TableCell colSpan={5} style={{ textAlign: "center" }}><h5>- Nessun Servizio di Scariche Atmosferiche Selezionato -</h5></TableCell>
                        </TableRow>
                    )}
                    <TableRow className="rt-tr-group">
                        <TableCell colSpan={5}>&nbsp;</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale Scariche Atmosferiche</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(CostoImponibile))}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale Verifica Impianto Messa a Terra</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(props.CostoImpianto))}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}>
                            <b>Totale Verifica Maggior Rischio Esplosione</b>
                        </TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(parseFloat(props.CostoImpiantoExp))}</b></TableCell>
                    </TableRow>
                    {ApplicaSconto ? (
                        <TableRow>
                            <TableCell colSpan={2} />
                            <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                                <span style={{ paddingTop: "4px", width: "200px" }}><b>Sconto</b></span>
                                <Input type="number" name="txtSconto" min="0" max="99" autoComplete="new-password"
                                    onChange={handlePercSconto} value={PercSconto}
                                    onFocus={handleFocusPercSconto}
                                    disabled={props.isFirmato && props.isAdmin === false} readOnly={props.isFirmato && props.isAdmin === false}
                                    style={{ width: "90px" }} bsSize="sm"
                                />
                                <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;%</b></span>
                            </TableCell>
                            <TableCell colSpan={2} align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(CostoScontato)}</b></TableCell>
                        </TableRow>) : ('')}
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                            <span style={{ paddingTop: "4px", width: "200px" }}><b>IVA</b></span>
                            <Input type="number" name="txtIva" min="0" max="99" autoComplete="new-password"
                                onChange={handlePercIva} value={PercIva}
                                onFocus={handleFocusPercIva}
                                style={{ width: "90px" }} bsSize="sm"
                            />
                            <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;%</b></span>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6" }} align="right"><b>{ccyFormat(CostoIva)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} />
                        <TableCell style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)", display: "flex" }}>
                            <span style={{ paddingTop: "4px", width: "200px" }}><b>Rimborso Km {props.CostoPerKm} &euro;</b></span>
                            <Input type="number" name="txtNumKm" min="0" max="9999" autoComplete="new-password"
                                onChange={handleNumKm} value={NumKm}
                                onFocus={handleFocusNumKm}
                                style={{ width: "90px" }} bsSize="sm"
                            />
                            <span style={{ paddingTop: "4px" }}>  <b>&nbsp;&nbsp;Km</b></span>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6" }} align="right"><b>{ccyFormat(SpeseKm)}</b></TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                        <MyAutoComplete id="txtTipoSpesa" name="txtTipoSpesa" 
                            url={process.env.REACT_APP_API_URL+'persspeseordinisinglelist'} 
                            optionname="Descrizione" optionvalue="Codice"
                            customvalue={DescrizioneSpesa} onChange={handleTipoSpesa}
                            placeholder="Nessuna Spesa selezionata"
                        /> 
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(CostoSpesa)}</b></TableCell>
                </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            <div style={{ display: "flex" }}>
                                <Button className="btn-mbg-cert btn-small" onClick={handleClickAltro}>Altro {VisualizzaCheckSconto ? ('<<') : ('>>')}</Button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                {VisualizzaCheckSconto ? (
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="checkbox" name="chkApplicaSconto" onChange={handleApplicaSconto} disabled={props.isFirmato && props.isAdmin === false} checked={ApplicaSconto > 0} />{' '}
                                            Applica Sconto
                                        </Label>
                                    </FormGroup>
                                ) : ('')}
                            </div>
                        </TableCell>
                        <TableCell colSpan={2} style={{ background: "#deeaf6", borderLeft: "1px solid rgba(224, 224, 224, 1)" }}><b>Totale &euro;</b></TableCell>
                        <TableCell align="right" style={{ background: "#deeaf6" }}><b>{ccyFormat(CostoTotale)}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function ModOrdine(props) {
    const classes = useStyles();
    const MsgAlertDefault = "Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [OpenAlertSelServizio, setOpenAlertSelServizio] = React.useState(false);
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [MsgAlert, setMsgAlert] = React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const onCloseAlertSelServizio = (event) => {
        setOpenAlertSelServizio(false);
    };
    //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [ChangeServizi, setChangeServizi] = React.useState(false);
    const [NumOrdine, setNumOrdine] = React.useState("");
    const [DataOrdine, setDataOrdine] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [IdCommerciale, setIdCommerciale] = React.useState("");
    const [DesDirettore, setDesDirettore] = React.useState("");
    const [Approva, setApprova] = React.useState("");
    const [ApprovatoOld, setApprovatoOld] = React.useState("");
    const [AutoRinnovo, setAutoRinnovo] = React.useState("");
    const [DataVerifica, setDataVerifica] = React.useState("");
    const [DataSaldo, setDataSaldo] = React.useState("");
    const [DataVerificaVuota, setDataVerificaVuota] = React.useState("0");
    const [Annulla, setAnnulla] = React.useState("");
    const [FirmaInVerifica, setFirmaInVerifica] = React.useState("");
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [PartitaIva, setPartitaIva] = React.useState("");    
    const [IndirizzoAzienda, setIndirizzoAzienda] = React.useState("");
    const [ComuneAzienda, setComuneAzienda] = React.useState("");
    const [CapAzienda, setCapAzienda] = React.useState("");
    const [CodiceUnivocoAzienda, setCodiceUnivocoAzienda] = React.useState("");
    const [ModalPag, setModalPag] = React.useState("");
    const [TipoOrdine, setTipoOrdine] = React.useState("");
    const [Sconto, setSconto] = React.useState(0);
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [IDSpesa, setIDSpesa] = React.useState(0);
    const [DescrizioneSpesa, setDescrizioneSpesa] = React.useState("");
    const [ProvvCommerciale, setProvvCommerciale] = React.useState(0);
    const [ProvvDirettore, setProvvDirettore] = React.useState(0);
    const [SpesaCommerciale, setSpesaCommerciale] = React.useState(0);
    const [SpesaDirettore, setSpesaDirettore] = React.useState(0);
    const [CodiceIva, setCodiceIva] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [OpenSign, setOpenSign] = useState(false);
    const [NumKm, setNumKm] = React.useState(0);
    const [KwImpianto, setKwImpianto] = React.useState(0);
    const [DescrizioneImpianto, setDescrizioneImpianto] = React.useState("");
    const [CostoImpianto, setCostoImpianto] = React.useState(0);
    const [CostoImpiantoExp, setCostoImpiantoExp] = React.useState(0);
    const [VerificaExp, setVerificaExp] = React.useState(0);
    const [VerificaMt, setVerificaMt] = React.useState(0);
    const [CostoPerKm, setCostoPerKm] = React.useState(0);
    const [Firmato, setFirmato] = React.useState(0);
    const [CifraAcconto, setCifraAcconto] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [Saldo, setSaldo] = React.useState("0");
    const [SaldoCommerciale, setSaldoCommerciale] = React.useState("");
    const [SaldoDirettore, setSaldoDirettore] = React.useState("");
    const [Ricalcola, setRicalcola] = React.useState(false);
    const [RowsServizi, setRowsServizi] = React.useState([]);
    const [Note, setNote] = React.useState("");
    const [CondEconomiche, setCondEconomiche] = React.useState("");
    const [ImgSign, setImgSign] = React.useState(null);
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [isFirmato, setisFirmato] = React.useState(false);
    const [Telefono, setTelefono] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    //confirm object
    const [MsgConfirmDelServizio, setMsgConfirmDelServizio] = React.useState("Si desidera eliminare il servizio dalla lista dei selezionati?");
    const [OpenConfirmDelServizio, setOpenConfirmDelServizio] = React.useState(false);
    const [OpenConfirmSubmit, setOpenConfirmSubmit] = React.useState(false);
    const [IdConfirmDelServizio, setIdConfirmDelServizio] = React.useState(false);
    const [forceSconto, setforceSconto] = React.useState("0");
    const [forceIva, setforceIva] = React.useState("0");
    const [forceNumKm, setforceNumKm] = React.useState("0");
    const [FileSostituitoErr, setFileSostituitoErr] = React.useState(false);
    const [FileSostituito, setFileSostituito] = React.useState("");
    const [FilePagamentoErr, setFilePagamentoErr] = React.useState(false);
    const [FilePagamento, setFilePagamento] = React.useState("");
    const onCloseConfirmDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
    };
    const onCloseConfirmSubmit = (event) => {
        setOpenConfirmSubmit(false);
    };
    const onCloseSign = (event) => {
        setOpenSign(false);
    };
    const onConfirmSign = (_Sign) => {
        setImgSign(_Sign);
        setOpenSign(false);
    };
    const onOpenSign = (event) => {
        setOpenSign(!OpenSign);
    };
    const toggleDelServizio = (_Id) => {
        setIdConfirmDelServizio(_Id);
        setOpenConfirmDelServizio(true);
    };
    const handleNote = (event) => {
        setNote(event.target.value);
    };
    const handleCondEconomiche = (event) => {
        setCondEconomiche(event.target.value);
    };
    const handleCifraAcconto = (event) => {
        setCifraAcconto(event.target.value);
        ControllaValoreAcconto(CostoTotale, event.target.value);
    };

    const onChangeQuantita = () => {
        setChangeServizi(true);
    }

    const ChangeCostoTotale = (_value) => {
        setCostoTotale(_value);
        ControllaValoreAcconto(_value, CifraAcconto);
    };

    const ControllaValoreAcconto = (_costo, _acconto) => {

        if (parseFloat(_acconto) > parseFloat(_costo)) {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("L'acconto supera il totale da pagare verrà azzerato!");
            setCifraAcconto(0);
            setOpenAlert(true);
        }
    }

    const handleSaldo = (event) => {
        setSaldo(event.target.value);
    };

    const handleSaldoCommerciale = (event) => {
        setSaldoCommerciale(!SaldoCommerciale);
    };

    const handleSaldoDirettore = (event) => {
        setSaldoDirettore(!SaldoDirettore);
    };
    const onDelServizio = (event) => {
        setOpenConfirmDelServizio(false);

        let newRows = RowsServizi;
        let index = -1;
        let indexSel = -1;

        if (newRows.length > 0) {
            newRows.map((item) => {
                index += 1;
                if (item.id === IdConfirmDelServizio) {
                    indexSel = index;
                }
                return indexSel;
            });
        }

        if (indexSel !== -1) {
            newRows.splice(indexSel, 1);
            setRowsServizi(newRows);
            setRicalcola(!Ricalcola);
            setChangeServizi(true);
        }

    };
    const handleModalPag = (event) => {
        setModalPag(event.target.value);
    };
    const handleTipoOrdine = (event) => {
        setTipoOrdine(event.target.value);
    };
    const handleOpenSelServizio = (event) => {
        setOpenSelServizio(true);
    };

    const CloseSelServizio = (event) => {
        setOpenSelServizio(false);
    };

    const onConfirmSelServizio = (RisArray) => {

        let newArray;


        let id = RisArray[0].id;

        let res = false;

        if (RowsServizi.length > 0) {
            RowsServizi.map(function (item) {
                if (item.id === id) {
                    res = true;
                    return true;
                }
                return res;
            });
        }


        if (!res) {
            if (RowsServizi.length > 0) {
                newArray = RowsServizi.concat(RisArray);
            }
            else {
                newArray = RisArray;
            }
            //newArray.push(RisArray)
            setRowsServizi(newArray);
            setChangeServizi(true);
            setOpenSelServizio(false);
        }
        else {
            setMsgAlert("Servizio già selezionato, per aumentare il quantitativo incrementare il campo quantità!");
            setOpenAlertSelServizio(true);

        }
    };

    const handleAnnulla = (event) => {
        setAnnulla(!Annulla);
    };
    const handleFirmaInVerifica = (event) => {
        setFirmaInVerifica(!FirmaInVerifica);
    };
    const handleCommerciale = (value, text) => {
        setIdCommerciale(value);
        setDesCommerciale(text);
    };
    const handleDirettore = (_value, text) => {
        setDesDirettore(text);
    };
    const handleApprova = (event) => {
        setApprova(!Approva);
    };

    const handleAutoRinnovo = (event) => {
        setAutoRinnovo(!AutoRinnovo);
    };

    const handleDataVerifica = (value) => {
        setDataVerifica(value);
    };
    const handleDataSaldo = (value) => {
        setDataSaldo(value);
    };
    const ChangeSconto = (_value) => {
        setSconto(_value);
    };
    const ChangeCostoSpesa = (_value,_IDSpesa) => {
        setCostoSpesa(_value);
        setIDSpesa(_IDSpesa); 
    };
    const ChangeIva = (_value) => {
        setCodiceIva(_value);
    };

    const ChangeNumKm = (_value) => {
        setNumKm(_value);
    };

    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
        },
        validators: {
            cphone: {  // name the rule
                //message: 'obbligatorio o non corretto',
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
                },
                //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
                //required: true  // optional
            }
        }
    }));

    const hiddenFileSostituisci = React.useRef(null);
    const hiddenFilePagamento = React.useRef(null);

    React.useEffect(() => {
        (async () => {
            setIsLoading(true);
            if (localStorage.getItem("idordine") !== "") {
                await LoadDatiOrdine();
                await LoadServiziOrdine();
            }
            if (localStorage.getItem("idazienda") !== "") {
                await LoadDatiAzienda();
            }
            setIsLoading(false);
        })();
    }, []);
    
    async function LoadDatiAzienda()  {
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'customer/' + localStorage.getItem("idazienda");
            result = await ax.post(url);
            setRagioneSociale(result.data[0].RagioneSociale);
            setEmail(result.data[0].Email);
            setPartitaIva(result.data[0].PartitaIva);
            setCodiceUnivocoAzienda(result.data[0].CodUnivoco);
            setCapAzienda(result.data[0].CapSL);
            setComuneAzienda(result.data[0].DesComuneSL);
            setIndirizzoAzienda(result.data[0].IndirizzoSL);
            setTelefono(result.data[0].TelefonoSL);
            setCellulare(result.data[0].CellulareReferente);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);
    }

    async function LoadDatiOrdine() {
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'ordinecert/' + localStorage.getItem("idordine");
            result = await ax.post(url);
            setModalPag(result.data[0].TipoPagamento);
            setTipoOrdine(result.data[0].TipoOrdine);
            setNumOrdine(result.data[0].NumOrdine);
            setDataOrdine(result.data[0].DataOrdine);
            setKwImpianto(result.data[0].PotenzaKw);
            setCostoPerKm(result.data[0].CostoPerKm);
            setCostoImpianto(result.data[0].CostoImpianto);
            setCostoImpiantoExp(result.data[0].CostoImpiantoExp);
            setVerificaExp(result.data[0].VerificaExp);
            setVerificaMt(result.data[0].VerificaMt);

            if (result.data[0].DataVerifica !== null && result.data[0].DataVerifica !== "") {
                setDataVerifica(result.data[0].DataVerifica);
            }
            else {
                setDataVerifica("");
                setDataVerificaVuota("1");
            }
            if (result.data[0].DataSaldo !== null && result.data[0].DataSaldo !== "") {
                setDataSaldo(result.data[0].DataSaldo);
            }
            else {
                setDataSaldo("");
            }
            setAnnulla(result.data[0].Annullato);
            setFirmaInVerifica(result.data[0].FirmaInVerifica === 1);
            if (result.data[0].Firmato !== null) {
                setFirmato(result.data[0].Firmato);
                setisFirmato(result.data[0].Firmato === 1);
            }
            else {
                setFirmato(0);
                setisFirmato(false);
            }

            setApprova(result.data[0].Approvato);
            setApprovatoOld(result.data[0].Approvato);
            setAutoRinnovo(result.data[0].AutoRinnovo);
            setCodiceIva(result.data[0].CodiceIva);
            setSaldo(String(result.data[0].Saldo));
            setSaldoCommerciale(result.data[0].SaldoCommerciale);
            setSaldoDirettore(result.data[0].SaldoDirettore);
            setCifraAcconto(result.data[0].Acconto);
            setDesCommerciale(result.data[0].Nome + " " + result.data[0].Cognome);
            setIdCommerciale(result.data[0].Commerciale_id);
            setDesDirettore(result.data[0].NomeDirettore!=null ? result.data[0].NomeDirettore + " " + result.data[0].CognomeDirettore : "");
            setProvvCommerciale(result.data[0].ProvvigioneCommerciale!== null ?result.data[0].ProvvigioneCommerciale :0);
            setProvvDirettore(result.data[0].ProvvigioneDirettore!== null ?result.data[0].ProvvigioneDirettore :0);
            setSpesaCommerciale(result.data[0].SpesaCommerciale!== null ?result.data[0].SpesaCommerciale :0);
            setSpesaDirettore(result.data[0].SpesaDirettore!== null ?result.data[0].SpesaDirettore :0);
            ChangeSconto(result.data[0].Sconto);
            ChangeIva(result.data[0].CodiceIva);
            ChangeNumKm(result.data[0].NumKm);

            setNote(result.data[0].Note);
            if (result.data[0].DescImpianto !== null) {
                setDescrizioneImpianto(result.data[0].DescImpianto);
            }
            else {
                setDescrizioneImpianto("");
            }
            if (result.data[0].CondEconomiche !== null) {
                setCondEconomiche(result.data[0].CondEconomiche);
            }
            else {
                setCondEconomiche("");
            }
            setforceSconto(String(result.data[0].Sconto));
            setforceIva(String(result.data[0].CodiceIva));
            setforceNumKm(String(result.data[0].NumKm));
            setIDSpesa(result.data[0].Spesa_id);
            setCostoSpesa(result.data[0].CostoSpesa);
            setDescrizioneSpesa(result.data[0].DescrizioneSpesa);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);

        }
    }
    async function LoadServiziOrdine ()  {
        const token = GetToken(localStorage.getItem("token"));
        const ax = axios;
        ax.defaults.headers.common['Authorization'] = "Bearer " + token;
        let result = [];
        try {
            let url = process.env.REACT_APP_API_URL + 'servizisaordinelist/' + localStorage.getItem("idordine");
            result = await ax.post(url);
            setRowsServizi(result.data);
        }
        catch (error) {
            let msg = ""
            if (error.response !== undefined) {
                msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
            }
            else {
                msg = GetMessage(undefined, "");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);
        }
    }

    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    const handleClickPagamento = (event) => {
        hiddenFilePagamento.current.click();
    };

    const handleChangePagamento = (event) => {
        const _file = event.target.files[0];
        const msg = VerifyImage(_file) ;
        
        if (msg !== "") {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFilePagamentoErr(true);
        }
        else {
            setFilePagamento(_file);
            setFilePagamentoErr(false);
        }
    };

    const handleClickSostituisci = (event) => {
        hiddenFileSostituisci.current.click();
    };

    const handleChangeSostituisci = (event) => {
        const _file = event.target.files[0];
        const msg = VerifyPDF(_file);

        if (msg !== "") {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFileSostituitoErr(true);
        }
        else {
            setFileSostituito(_file);
            setFileSostituitoErr(false);
        }
    };

    const handleSubmitForm = () => {
        //if (!ChangeServizi)
        //{
        onSubmitForm();
        //}
        //else {
        //    setOpenConfirmSubmit(true);
        //}

    };
    const onSubmitForm = () => {

        if (validator.current.allValid() && (RowsServizi.length > 0 || parseFloat(localStorage.getItem("idimpianto")) > 0) && FileSostituitoErr !== true && FilePagamentoErr !== true) {

            setisSubmit(true);
            const intApprovato = Approva ? 1 : 0;
            const intAutoRinnovo = AutoRinnovo ? 1 : 0;

            const intAnnullato = Annulla ? 1 : 0;
            const intFirmaInVerifica = FirmaInVerifica ? 1 : 0;
            const intSaldoDirettore = SaldoDirettore ? 1 : 0;
            const intSaldoCommerciale = SaldoCommerciale ? 1 : 0;
            const fd = new FormData();
            fd.append("id", localStorage.getItem("idordine"));
            fd.append("Azienda_id", localStorage.getItem("idazienda"));
            fd.append("Impianto_id", localStorage.getItem("idimpianto"));
            fd.append("Commerciale_id", IdCommerciale);
            fd.append("Spesa_id", IDSpesa); 
            fd.append("CostoSpesa", CostoSpesa); 
            fd.append("Sconto", Sconto);
            fd.append("CostoPerKm", CostoPerKm);
            fd.append("NumOrdine", NumOrdine);
            fd.append("NumKm", NumKm);
            fd.append("PotenzaKw", KwImpianto);
            fd.append("CostoImpianto", CostoImpianto);
            fd.append("SpesaDirettore", SpesaDirettore);
            fd.append("SpesaCommerciale", SpesaCommerciale);
            fd.append("ProvvigioneDirettore", ProvvDirettore);
            fd.append("ProvvigioneCommerciale", ProvvCommerciale);
            fd.append("CostoImpiantoExp", CostoImpiantoExp);
            fd.append("VerificaExp", VerificaExp);
            fd.append("VerificaMt", VerificaMt);
            fd.append("DescImpianto", DescrizioneImpianto);
            fd.append("CodiceIva", CodiceIva);
            fd.append("TipoPagamento", ModalPag);
            fd.append("TipoOrdine", TipoOrdine);
            fd.append("Approvato", intApprovato);
            fd.append("AutoRinnovo", intAutoRinnovo);
            fd.append("DataVerifica", DataVerifica);
            fd.append("DataSaldo", DataSaldo);
            fd.append("VerificaPrecedenteVuota", DataVerificaVuota);
            if (ImgSign !== null) {
                fd.append("Firmato", 1);
                fd.append("imgFirma", ImgSign);
            }
            else {
                fd.append("Firmato", Firmato);
                fd.append('FileSostituito', FileSostituito);
            }
            if (FilePagamento !== null){
                fd.append('FilePagamento', FilePagamento);
            }
            fd.append("Acconto", CifraAcconto);
            fd.append("Saldo", Saldo);
            fd.append("SaldoCommerciale", intSaldoCommerciale);
            fd.append("SaldoDirettore", intSaldoDirettore);
            fd.append("Annullato", intAnnullato);
            fd.append("FirmaInVerifica", intFirmaInVerifica);
            fd.append("Note", Note);
            fd.append("CondEconomiche", CondEconomiche);
            if (ChangeServizi) {
                let ParamServizi = "";
                ParamServizi = RowsServizi.map(function (item) {
                    return item.id + "|" + item.Quantita;
                });
                fd.append("ParamServizi", ParamServizi);
            }

            const token = GetToken(localStorage.getItem("token"));
            const ax = axios;
            ax.defaults.headers.common['Authorization'] = "Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }

            ax
                .post(process.env.REACT_APP_API_URL + 'ordinecertupdate', fd, axiosConfig)
                .then((response) => {

                    if (response.status === 200) {
                        if (response.data.error === "OK") {
                            setCloseForm(true);
                        }
                        else {
                            let msg = "";
                            let ris = JSON.stringify(response.data);
                            ris = ris.replaceAll("\"", "");
                            ris = ris.replaceAll("{", "");
                            ris = ris.replaceAll("}", "");
                            ris = ris.replaceAll("[", "");
                            ris = ris.replaceAll("]", "");
                            msg = "errore durante l'inserimento! " + ris;
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                        }
                    }
                    else {
                        const msg = GetMessage(response.status, response.data.message);
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);

                    }
                })
                .catch((error) => {
                    let msg = ""
                    if (error.response !== undefined) {
                        msg = GetMessage(error.response.status, error.response.data.message || error.response.message);
                    }
                    else {
                        msg = GetMessage(undefined, "");
                    }
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                });
            setisSubmit(false);

        } else {
            if (RowsServizi.length > 0 || parseFloat(localStorage.getItem("idimpianto")) > 0) {
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                setOpenAlert(true);
            }
            else {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno un servizio");
                setOpenAlert(true);
            }

        }
    }

    let UserRole = GetRole(localStorage.getItem("token"));
    let isAdmin = false;
    if (parseFloat(UserRole) === Ruolo_Admin) isAdmin = true
    let urlRedirect = "";

    if (CloseForm) {
        if (localStorage.getItem("menunew") === "1") {
            urlRedirect = "/home";
        }
        else {
            urlRedirect = "/ordini";
        }
    }

    if (urlRedirect !== "") {
        return <Redirect to={urlRedirect} />;
    }
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Dettaglio Ordine - Servizi
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={handleSubmitForm}>
                        Conferma {isSubmit ? (<span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span>) : (<span></span>)}
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete="off">
                        {isLoading ? (<CircularProgress className="sp-center" />) : (<span></span>)}
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert} />
                        <ElencaServizio open={OpenSelServizio} handleClose={CloseSelServizio} handleConfirm={onConfirmSelServizio} />
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtEmail">Email</Label>
                                                <Input type="text" name="txtEmail" autoComplete="new-password" placeholder=""
                                                value={Email} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtImpianto">Impianto</Label>
                                                <Input type="text" name="txtImpianto" autoComplete="new-password" placeholder=""
                                                value={DescrizioneImpianto} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xl={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                style={{backgroundColor:'#D3D3D3'}}>
                                                    <Typography ><b>Altri Dati Azienda</b></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid item xs={12}>
                                                        <Row>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtPartitaIva">Partita IVA</Label>
                                                                    <Input type="text" name="txtPartitaIva" autoComplete="new-password" placeholder=""
                                                                    value={PartitaIva} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtCodiceUnivoco">Cod. Univoco</Label>
                                                                    <Input type="text" name="txtCodiceUnivoco" autoComplete="new-password" placeholder=""
                                                                    value={CodiceUnivocoAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtTelefono">Telefono</Label>
                                                                    <MyPhoneInput 
                                                                        type="text" 
                                                                        name="txtTelefono" 
                                                                        autoComplete="new-password" 
                                                                        placeholder=""
                                                                        value={Telefono} 
                                                                        readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                <Label for="txtCellulare">Cellulare</Label>
                                                                    <MyPhoneInput 
                                                                        type="text" 
                                                                        name="txtCellulare" 
                                                                        autoComplete="new-password" 
                                                                        placeholder=""
                                                                        value={Cellulare} 
                                                                        readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={4} md={6}>
                                                                <FormGroup>
                                                                    <Label for="txtIndirizzo">Indirizzo</Label>
                                                                    <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                                    value={IndirizzoAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={3} md={5}>
                                                                <FormGroup>
                                                                    <Label for="txtComune">Comune</Label>
                                                                    <Input type="text" name="txtComune" autoComplete="new-password" placeholder=""
                                                                    value={ComuneAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg={1} md={1}>
                                                                <FormGroup>
                                                                    <Label for="txtCap">Cap</Label>
                                                                    <Input type="text" name="txtComune" autoComplete="new-password" placeholder=""
                                                                    value={CapAzienda} readOnly disabled
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            
                                                        </Row>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                {
                                    (isAdmin === true || isFirmato === false) && (
                                        <Toolbar>
                                            <Button className="btn-mbg-cert" onClick={handleOpenSelServizio}>Aggiungi Servizio</Button>
                                        </Toolbar>
                                    )
                                }
                                <MyAlertSelServizio message={MsgAlert} severity="warning" handleClose={onCloseAlertSelServizio} vertical="top" horizontal="center" open={OpenAlertSelServizio} />
                                <MyConfirmDelServizio title="Conferma Cancellazione" message={MsgConfirmDelServizio} handleClose={onCloseConfirmDelServizio} handleConfirm={onDelServizio} open={OpenConfirmDelServizio} />
                                <CreateTableServizi
                                    rows={RowsServizi}
                                    onDelete={toggleDelServizio}
                                    onChangeCostoTotale={ChangeCostoTotale}
                                    onChangeQuantita={onChangeQuantita}
                                    isAdmin={isAdmin}
                                    CostoImpianto={CostoImpianto}
                                    CostoImpiantoExp={CostoImpiantoExp}
                                    CostoSpesa={CostoSpesa}
                                    IDSpesa={IDSpesa}
                                    DescrizioneSpesa={DescrizioneSpesa}
                                    CostoPerKm={CostoPerKm}
                                    isFirmato={isFirmato}
                                    onRicalcola={Ricalcola}
                                    onChangeSconto={ChangeSconto}
                                    onChangeCostoSpesa={ChangeCostoSpesa}
                                    onChangeIva={ChangeIva}
                                    onChangeNumKm={ChangeNumKm}
                                    setSconto={forceSconto}
                                    setIva={forceIva}
                                    setNumKm={forceNumKm}
                                />
                                <CreateSign open={OpenSign} handleClose={onCloseSign} handleConfirm={onConfirmSign} />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('Note')}
                                                    value={Note} onChange={handleNote} maxLength="200"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtCondEconomiche">Cond. Economiche [max 200 caratteri]</Label>
                                                <Input type="textarea" name="txtCondEconomiche" id="txtCondEconomiche" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('CondEconomiche')}
                                                    value={CondEconomiche} onChange={handleCondEconomiche} maxLength="200"
                                                />
                                                {validator.current.message('CondEconomiche', CondEconomiche, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <FormGroup style={{ textAlign: "center" }}>
                                                <div>&nbsp;</div>
                                                <Label>
                                                    <Input type="checkbox" name="chkAutoRinnovo" onChange={handleAutoRinnovo} checked={AutoRinnovo > 0} />{' '}
                                                    Tacitamente Rinnovabile
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col xl={4} md={4}>
                                            <FormGroup>
                                                <Label for="selModalPag">Modalità di Pagamento *</Label>
                                                <Input type="select" name="selModalPag" id="selModalPag" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('ModalPag')}
                                                    value={ModalPag} onChange={handleModalPag}
                                                >
                                                    <option key=""></option>
                                                    <option key="1" value="1">Assegno bancario</option>
                                                    <option key="2" value="2">Assegno Circolare</option>
                                                    <option key="3" value="3">Bonifico Bancario</option>
                                                    <option key="4" value="4">Denaro Contante</option>
                                                    <option key="5" value="5">Pos</option>
                                                </Input>
                                                {validator.current.message('ModalPag', ModalPag, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col xl={4} md={4}>
                                            <Row>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <label>
                                                            <input type="radio" name="radioRicSaldo" value="0"
                                                                checked={Saldo === "0"} onClick={handleSaldo} />
                                                            &nbsp;Acconto
                                                        </label>
                                                        {
                                                            (Saldo === "0" || parseFloat(CifraAcconto) > 0) && (
                                                                <Input type="number" name="txtAcconto" autoComplete="new-password" placeholder=""
                                                                    onBlur={() => validator.current.showMessageFor('Acconto')}
                                                                    disabled={isFirmato && isAdmin === false} readOnly={isFirmato && isAdmin === false}
                                                                    value={CifraAcconto} onChange={handleCifraAcconto} className="text-left"
                                                                />
                                                            )
                                                        }
                                                        {validator.current.message('Acconto', CifraAcconto, 'required|numeric', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={6}>
                                                    <label>
                                                        <input type="radio" name="radioRicSaldo" value="1" checked={Saldo === "1"}
                                                            onClick={handleSaldo} />
                                                        &nbsp;Saldo
                                                    </label>
                                                    {
                                                        (Saldo === "1" && parseFloat(CifraAcconto) > 0) && (
                                                            <Input type="text" name="txtSaldo" autoComplete="new-password" placeholder=""
                                                                disabled={true} readOnly={true}
                                                                value={ccyFormat(parseFloat(CostoTotale) - parseFloat(CifraAcconto))} className="text-left"
                                                            />
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Col>
                                        {
                                            (isAdmin === true && Saldo === "1") &&
                                            (
                                                <Col xl={2} lg={2} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtDataSaldo">Data Saldo</Label>
                                                        <MyDatePicker name="txtDataSaldo"
                                                            onBlur={() => validator.current.showMessageFor('DataSaldo')}
                                                            value={DataSaldo} onChange={handleDataSaldo} autoComplete="new-password"
                                                        />
                                                        {validator.current.message('DataSaldo', DataSaldo, 'data', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>)
                                        }
                                        {
                                            isFirmato === true ?
                                                (
                                                    <Col xl={2} md={3}>
                                                        <FormGroup>
                                                            <input
                                                                type="file"
                                                                ref={hiddenFileSostituisci}
                                                                onChange={handleChangeSostituisci}
                                                                style={{ display: 'none' }}
                                                            />
                                                            <Label>&nbsp;</Label>
                                                            <Button onClick={handleClickSostituisci} disabled={isAdmin === false} readOnly={isAdmin === false} className="btn-mbg-cert btn-block">Sostituisci Ordine</Button>
                                                        </FormGroup>
                                                    </Col>
                                                ) : (
                                                    <Col md={2}>
                                                        <FormGroup>
                                                            <Label check style={{ fontSize: "18px" }}>
                                                                <Input type="checkbox" name="chkFirmaAllaVerifica" onChange={handleFirmaInVerifica} checked={FirmaInVerifica > 0} />{' '}
                                                                Firma alla verifica<br />&nbsp;
                                                            </Label>
                                                            <Button onClick={onOpenSign} disabled={FirmaInVerifica > 0} className="btn-mbg-cert btn-block">Firma</Button>
                                                        </FormGroup>
                                                    </Col>
                                                )
                                        }
                                    </Row>
                                    <Row>
                                        <Col xl={4} md={4}>
                                            <FormGroup>
                                                <Label for="selTipoVerifica">Tipo Verifica *</Label>
                                                <Input type="select" name="selTipoVerifica" id="selTipoVerifica" placeholder=""
                                                    onBlur={() => validator.current.showMessageFor('TipoVerifica')}
                                                    disabled={isFirmato} readOnly={isFirmato}
                                                    value={TipoOrdine} onChange={handleTipoOrdine}
                                                >
                                                    <option key=""></option>
                                                    <option key="0" value="0">Verifica Ordinaria</option>
                                                    <option key="1" value="1">Verifica Straordinaria</option>
                                                </Input>
                                                {validator.current.message('TipoOrdine', TipoOrdine, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        {
                                            (isFirmato === true && isAdmin === true) &&
                                            (
                                                <Col xl={2} lg={2} md={4} xs={12}>
                                                    <FormGroup>
                                                        <Label for="txtDataVerifica">Data Verifica</Label>
                                                        <MyDatePicker name="txtDataVerifica"
                                                            onBlur={() => validator.current.showMessageFor('DataVerifica')}
                                                            value={DataVerifica} onChange={handleDataVerifica} autoComplete="new-password"
                                                        />
                                                        {validator.current.message('DataVerifica', DataVerifica, 'data', { className: 'text-danger' })}
                                                    </FormGroup>
                                                </Col>)
                                        }
                                        {
                                            (Saldo === "1") &&
                                            (<Col xl={2} md={3}>
                                                <FormGroup>
                                                    <input
                                                        type="file"
                                                        ref={hiddenFilePagamento}
                                                        onChange={handleChangePagamento}
                                                        accept="image/*" 
                                                        capture
                                                        style={{ display: 'none' }}
                                                    />
                                                    <Label>&nbsp;</Label>
                                                    <Button onClick={handleClickPagamento} className="btn-mbg-cert btn-block">Foto Pagamento</Button>
                                                </FormGroup>
                                            </Col>)
                                        }
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtNumOrdine">N. Ordine</Label>
                                                <Input type="text" name="txtNumOrdine" autoComplete="new-password" placeholder=""
                                                    value={NumOrdine} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={2} lg={2} md={3} xs={12}>
                                            <FormGroup>
                                                <Label for="txtDataOrdine">Del</Label>
                                                <Input type="text" name="txtDataOrdine" autoComplete="new-password"
                                                    value={DataOrdine} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xl={1} lg={1} md={2} xs={12}>
                                            <FormGroup>
                                                <div>&nbsp;</div>
                                                <Label check style={{ fontSize: "18px" }}>
                                                    <Input type="checkbox" name="chkAnnulla" onChange={handleAnnulla} checked={Annulla > 0} />{' '}
                                                    Annulla
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        {
                                            ((isFirmato === true || FirmaInVerifica === true) && isAdmin === true) &&
                                            (
                                                <Col xl={2} lg={2} md={2} xs={12}>
                                                    <FormGroup>
                                                        <div>&nbsp;</div>
                                                        <Label check style={{ fontSize: "18px" }}>
                                                            <Input type="checkbox" name="chkApprova" onChange={handleApprova} checked={Approva > 0} readOnly={ApprovatoOld> 0} disabled={ApprovatoOld> 0}/>{' '}
                                                            Approva
                                                        </Label>
                                                    </FormGroup>
                                                </Col>)
                                        }
                                        {
                                            (isFirmato === true && isAdmin === true) &&
                                            (
                                                <Col xl={2} lg={3} md={4} xs={12}>
                                                    <FormGroup>
                                                        <div>&nbsp;</div>
                                                        <Label check style={{ fontSize: "18px" }}>
                                                            <Input type="checkbox" name="chkSaldoCommerciale" onChange={handleSaldoCommerciale} checked={SaldoCommerciale > 0} />{' '}
                                                            Saldo Commerciale
                                                        </Label>
                                                    </FormGroup>
                                                </Col>)
                                        }
                                        {
                                            (isFirmato === true && isAdmin === true) &&
                                            (
                                                <Col xl={2} lg={3} md={4} xs={12}>
                                                    <FormGroup>
                                                        <div>&nbsp;</div>
                                                        <Label check style={{ fontSize: "18px" }}>
                                                            <Input type="checkbox" name="chkSaldoDirettore" onChange={handleSaldoDirettore} checked={SaldoDirettore > 0} />{' '}
                                                            Saldo Direttore
                                                        </Label>
                                                    </FormGroup>
                                                </Col>)
                                        }
                                    </Row>
                                    <Row>
                                        <Col xl={5} lg={5} md={6} xs={12}>
                                            <FormGroup>
                                                <Label for="txtCommerciale">Commerciale *</Label>
                                                <MyAutoComplete id="txtCommerciale" name="txtCommerciale"
                                                    url={process.env.REACT_APP_API_URL + 'commercialesinglelist'}
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    customvalue={DesCommerciale} onChange={handleCommerciale}
                                                    onBlur={() => validator.current.showMessageFor('Commerciale')}
                                                    disabled={isAdmin === false} readOnly={isAdmin === false}
                                                />
                                                {validator.current.message('Commerciale', IdCommerciale, 'required|alpha_num', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtProvvigioneCommerciale">Provv. Commerciale</Label>
                                                    <Input type="text" 
                                                        name="txtProvvigioneCommerciale" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ProvvCommerciale} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtSpesaCommerciale">Spesa Commerciale</Label>
                                                    <Input type="text" 
                                                        name="txtSpesaCommerciale" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ccyFormat(parseFloat(SpesaCommerciale))} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={5} lg={5} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtDirettore">Direttore</Label>
                                                    <MyAutoComplete id="txtDirettore" name="txtDirettore" 
                                                    url={process.env.REACT_APP_API_URL+'commercialedirettoresinglelist'} 
                                                    optionname="DesCommerciale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Direttore')}
                                                    customvalue={DesDirettore} onChange={handleDirettore}
                                                    disabled
                                                    readOnly
                                                    />
                                                    {validator.current.message('Direttore', DesDirettore, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtProvvigioneDirettore">Provv. Direttore</Label>
                                                    <Input type="text" 
                                                        name="txtProvvigioneDirettore" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ProvvDirettore} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                        {
                                        isAdmin === true &&
                                            (
                                            <Col xl={2} lg={2} md={6} xs={12}>
                                                <FormGroup>
                                                    <Label for="txtSpesaDirettore">Spesa Direttore</Label>
                                                    <Input type="text" 
                                                        name="txtSpesaDirettore" 
                                                        autoComplete="new-password" 
                                                        placeholder=""
                                                        disabled
                                                        readOnly
                                                        value={ccyFormat(parseFloat(SpesaDirettore))} className="text-left"
                                                    />
                                                </FormGroup>
                                            </Col>)
                                        }
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
