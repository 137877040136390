import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import {GetTextStatoPreventivoCert,isDate,DateDiff, GetIDCommerciale,GetToken,GetRole,ccyFormat, get_years,GetItemStatoPreventivoCert, GetItemLast10Year} from "../../Modules/Funzioni";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import Avatar from '@material-ui/core/Avatar';
import MyDatePicker from '../MyDatePicker';
import { Redirect } from "react-router-dom";
import { Ruolo_Commerciale,Ruolo_Admin, Ruolo_CommTecnico, Stato_Preventivo_Annullato } from '../../Modules/Costanti';
import FilterListIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ReactExport from "react-export-excel";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(41, 110, 156, 1) !important",
        '&:hover':{
            cursor:"pointer"
      }  
    }
}));

OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open, fileName } = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            <ListItem button onClick={() => handleListItemClick("Apri")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <FilterListIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Preventivo</ListItemText>
            </ListItem>
            {
            fileName!=="" && fileName!==null  &&
            (<ListItem button onClick={() => handleListItemClick("Scarica")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica PDF Preventivo</ListItemText>
            </ListItem>)
            }
        </List>
      </Dialog>
    );
  }

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumPreventivo', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataPreventivoCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'DescImpianto', string: false, disablePadding: true, label: 'Impianto' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
];

const headCellsAdmin = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'NumPreventivo', string: false, disablePadding: true, label: 'N.' },
    { id: 'DataPreventivoCast', string: false, disablePadding: true, label: 'Data' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Ragione Sociale' },
    { id: 'Commerciale', string: false, disablePadding: true, label: 'Commerciale' },
    { id: 'Imponibile', string: false, disablePadding: true, label: 'Imponibile' },
    { id: 'Iva', string: false, disablePadding: true, label: 'IVA' },
    { id: 'Spese', string: false, disablePadding: true, label: 'Spese' },
    { id: 'PrezzoTotale', string: false, disablePadding: true, label: 'Totale' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
];

function GetHeadCells(_userRole){
    if (parseInt(_userRole)===Ruolo_Admin){
        return headCellsAdmin;
    }
    else {
        return headCells;
    }
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {(() => {
                let _UserRole =GetRole(localStorage.getItem("token"));
                let _headCells=GetHeadCells(_UserRole);
               
                return(_headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                )));
                })()}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
const GetImponibileRow = (_PrezzoTotale,_Sconto, _Annullato) =>{
    let Prezzo=0;
    if (parseFloat(_Annullato)!==1){
        Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
    }
    return ccyFormat(Prezzo);
}
const GetPrezzoRow = (_PrezzoTotale,_Sconto,_CodiceIva, _Annullato, _SpeseKm, _CostoSpesa) =>{
    let PrezzoIvato=0;
    if (parseFloat(_Annullato)!==1){
        let Prezzo=parseFloat(_PrezzoTotale)- (parseFloat(_PrezzoTotale)*parseFloat(_Sconto/100));
        PrezzoIvato=Prezzo + (Prezzo*parseFloat(_CodiceIva/100))
        PrezzoIvato=PrezzoIvato + parseFloat(_SpeseKm)+ parseFloat(_CostoSpesa);
    }
    return ccyFormat(PrezzoIvato);
}
const GetSpese = (_SpeseKm, _CostoSpesa) =>{
    let Costo=parseFloat(_SpeseKm)+ parseFloat(_CostoSpesa);
    return ccyFormat(Costo);
}
function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Preventivi">
                <ExcelColumn label="N." value="NumPreventivo"/>
                <ExcelColumn label="Data" value="DataPreventivo"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Commerciale" value="DesCommerciale"/>
                <ExcelColumn label="Impianto" value="DescImpianto"/>
                <ExcelColumn label="Imponibile"
                             value={(col) => GetImponibileRow(col.PrezzoTotale,col.Sconto,col.Annullato)}/>
                <ExcelColumn label="IVA" value="CodiceIva"/>
                <ExcelColumn label="Spese Km" value="Spese"/>
                <ExcelColumn label="Prezzo"
                             value={(col) => GetPrezzoRow(col.PrezzoTotale,col.Sconto,col.CodiceIva,col.Annullato,col.SpeseKm,col.CostoSpesa)}/>
                <ExcelColumn label="Stato"
                             value={(col) => GetTextStatoPreventivoCert(col.Approvato,
                                col.Annullato,
                                col.Inviato)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}

export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('desc');
  let tokenRicerca=localStorage.getItem("tokenRicercaPreventivi");
  let tmpRicStato="";
  let tmpRicIDAzienda="";
  let tmpRicAzienda="";
  let tmpRicIDImpianto="";
  let tmpRicIDCommerciale="";
  let tmpRicCommerciale="";
  let tmpRicDataPreventivoInizio="";
  let tmpRicDataPreventivoFine="";
  let tmpRicSubalterno=false;
  let tmpRicAnnoCompetenza=(new Date().getFullYear());
  let tmpRicNumRecord="100";
  let tmpPage=0;
    if (tokenRicerca!=="" && tokenRicerca!==null){
        let mtxTokenRicerca=tokenRicerca.split("|");
        tmpRicStato=mtxTokenRicerca[0];
        tmpRicIDAzienda=mtxTokenRicerca[1];
        tmpRicAzienda=mtxTokenRicerca[2];
        tmpRicIDImpianto=mtxTokenRicerca[3];
        tmpRicIDCommerciale=mtxTokenRicerca[4];
        tmpRicCommerciale=mtxTokenRicerca[5];
        tmpRicDataPreventivoInizio=mtxTokenRicerca[6];
        tmpRicDataPreventivoFine=mtxTokenRicerca[7];
        tmpRicSubalterno=mtxTokenRicerca[8]==="true";
        tmpPage=parseInt(mtxTokenRicerca[9]);
        tmpRicAnnoCompetenza=mtxTokenRicerca[10];
        tmpRicNumRecord=mtxTokenRicerca[11];

    }
  const [NewPreventivo, setNewPreventivo] = React.useState(false);
  const [RicAzienda, setRicAzienda] = React.useState(tmpRicAzienda);
  const [RicIDAzienda, setRicIDAzienda] = React.useState(tmpRicIDAzienda);
  const RicIDImpianto = tmpRicIDImpianto;
  const [RicCommerciale, setRicCommerciale] = React.useState(tmpRicCommerciale);
  const [RicSubalterno, setRicSubalterno] = React.useState(tmpRicSubalterno);
  const [RicIDCommerciale, setRicIDCommerciale] = React.useState(tmpRicIDCommerciale);
  const [RicAnnoCompetenza, setRicAnnoCompetenza] = React.useState(tmpRicAnnoCompetenza);
  const [RicNumRecord, setRicNumRecord] = React.useState(tmpRicNumRecord);
  const [RicStato, setRicStato] = React.useState(tmpRicStato);
  const [RicDataPreventivoInizio, setRicDataPreventivoInizio] = React.useState(tmpRicDataPreventivoInizio);
  const [RicDataPreventivoFine, setRicDataPreventivoFine] = React.useState(tmpRicDataPreventivoFine);
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(tmpPage);  
  const [data, setData] = useState([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [DisableFilter, setDisableFilter] = useState(true);
  const [isSending, setIsSending] = useState(false);   
  const [rowsPerPage, setRowsPerPage] = React.useState(20);  
  const [dense, setDense] = React.useState(false);
  const [ModPreventivo, setModPreventivo] = useState(false);
  const [SelIdPreventivo, setSelIdPreventivo] = useState("");
  const [SelIdAzienda, setSelIdAzienda] = useState("");
  const [SelNumPreventivo, setSelNumPreventivo] = useState("");
  const [SelDataPreventivo, setSelDataPreventivo] = useState("");
  const [SelFileName,setSelFileName]=useState("");
  
  const [PrezzoTotale, setPrezzoTotale] = useState(0);
  const [ImponibileTotale, setImponibileTotale] = useState(0);
  const [OpenOperDialog,setOpenOperDialog]=useState(false);
  
  //alert object
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const [MsgAlert,setMsgAlert]=React.useState("");
  const onCloseAlert = (event) => {
    setOpenAlert(false);
};
const UserRole =GetRole(localStorage.getItem("token"));
  //-----------
  const toggleNewPreventivo = () => {
    resetStorage();
    setNewPreventivo(true);
  }
  
  const toggleOperDialog = (_IdPreventivo,_IdAzienda,_FileName,_NumPreventivo,_DataPreventivo) => {
    resetStorage();
    setSelIdPreventivo(_IdPreventivo);
    setSelIdAzienda(_IdAzienda);
    setSelFileName(_FileName);
    setSelNumPreventivo(_NumPreventivo);
    setSelDataPreventivo(_DataPreventivo);
    setOpenOperDialog(true);
  }
    
  const resetStorage = () =>{
    localStorage.setItem("idpreventivo", "");
    localStorage.setItem("tokenRicercaPreventivi", RicStato + "|" + RicIDAzienda + "|" + 
    RicAzienda + "|" + RicIDImpianto + "|" + + RicIDCommerciale + "|" + 
    RicCommerciale  + "|" + RicDataPreventivoInizio + "|" + 
    RicDataPreventivoFine + "|" + RicSubalterno + "|" + page + "|" + 
    RicAnnoCompetenza + "|" + RicNumRecord);
  }

  const DownloadDocumento = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelFileName);
            fd.append("id", SelIdPreventivo);
            let url=process.env.REACT_APP_API_URL+'docpreventivocert'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [response.data], 
                    {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'preventivo_' + SelNumPreventivo + '_' + get_years(SelDataPreventivo) + '.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
                });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }


  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const IDCommerciale = GetIDCommerciale(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'preventivocertlist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                if (UserRole===Ruolo_Commerciale || UserRole===Ruolo_CommTecnico){
                    if (parseFloat(IDCommerciale)>0){
                        fd.append("Commerciale_id", IDCommerciale); 
                    } 
                }
                fd.append("RicCommerciale_id", RicIDCommerciale);
                fd.append("IDAzienda", RicIDAzienda);
                fd.append("IDImpianto", RicIDImpianto);
                fd.append("DataPreventivoInizio", RicDataPreventivoInizio);
                fd.append("DataPreventivoFine", RicDataPreventivoFine);
                fd.append("Stato", RicStato);
                fd.append("AnnoCompetenza", RicAnnoCompetenza);
                fd.append("LastNumberData", RicNumRecord);
                if (RicSubalterno===true)  fd.append("Subalterno", "1");
                                
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
                localStorage.setItem("tokenRicercaPreventivi","");
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
            CalcolaTotale(result.data);
        })(); 
        
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 


const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};

const handleRicCommerciale = (value,text) => {
    setRicCommerciale(text);
    setRicIDCommerciale(value);;
    if (value===""){
        setRicSubalterno(false);;
    }
    setDisableFilter(false);
};

const handleRicSubalterno = (value) => {  
    setRicSubalterno(!RicSubalterno); 
   
    setDisableFilter(false);
};

const handleRicDataPreventivoInizio = (value) => {  
    setRicDataPreventivoInizio(value); 
    if (isDate(RicDataPreventivoFine)===""){
        if (DateDiff(value,RicDataPreventivoFine)<0){
            setRicDataPreventivoFine("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataPreventivoFine = (value) => {  
    setRicDataPreventivoFine(value);  
    if (isDate(RicDataPreventivoInizio)===""){
        if (DateDiff(RicDataPreventivoInizio,value)<0){
            setRicDataPreventivoInizio("");
        }
    }
    setDisableFilter(false);
};

const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};
const handleRicAnnoCompetenza = (event) => {
    setRicAnnoCompetenza(event.target.value);
    setDisableFilter(false);
};
const handleRicNumRecord = (event) => {
    setRicNumRecord(event.target.value);
    setDisableFilter(false);
};
const CalcolaTotale = (_mdata)=>{
    let newPrezzoTotale=0;
    let newPrezzoParziale=0;
    let newImponibileTotale=0;
    let newImponibileParziale=0;
    if (_mdata!==undefined){

        for (const item of _mdata) {
            if (parseFloat(item.Annullato)!==1){
                newPrezzoParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newPrezzoParziale=newPrezzoParziale + (newPrezzoParziale*parseFloat(item.CodiceIva/100));
                newPrezzoTotale +=newPrezzoParziale;

                newImponibileParziale=parseFloat(item.PrezzoTotale)- (parseFloat(item.PrezzoTotale)*parseFloat(item.Sconto/100));
                newImponibileTotale +=newImponibileParziale;
            }
        }

        setPrezzoTotale(newPrezzoTotale);
        setImponibileTotale(newImponibileTotale);
       
    }
}

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "Apri":
            {
                setModPreventivo(true);
                break;
            }
        case "Scarica":
            {
                DownloadDocumento();
                break;
            }
        default:
    }
};
const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};
let emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) -1;
    if (emptyRows>5){emptyRows=3}

    if (NewPreventivo){
        const urlRedirect="/nuovopreventivoazienda";
        return <Redirect to={urlRedirect} />;
    }

    if (ModPreventivo){
        const urlRedirect="/modificapreventivoservizi";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idpreventivo", SelIdPreventivo);
        return <Redirect to={urlRedirect} />;
    }
    
    let IDCommerciale=GetIDCommerciale(localStorage.getItem("token"));
    if (parseFloat(IDCommerciale)===0){
        IDCommerciale="";
    }
  return (  
    
    <div className={classes.root}>
        <OperationDialog classes={classes} onClose={onCloseOperDialog} onSelected={onSelectedItemOperDialog} open={OpenOperDialog} fileName={SelFileName} />
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        <div className="small" style={{width:"98%"}}>
            <Row>
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label for="selRicAnnoCompetenza">Anno</Label>
                        <Input type="select" className="search" name="selRicAnnoCompetenza" 
                        id="selRicAnnoCompetenza" placeholder="" bsSize="sm"
                            value={RicAnnoCompetenza} onChange={handleRicAnnoCompetenza}>
                                {GetItemLast10Year().map((item) => (
                                    <option key={item.Anno} value={item.Anno}>{item.Anno}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label for="selRicNumRecord">Ultimi Preventivi</Label>
                        <Input type="select" className="search" name="selRicNumRecord" 
                        id="selRicNumRecord" placeholder="" bsSize="sm"
                            value={RicNumRecord} onChange={handleRicNumRecord}>
                                <option key="100" value="100">100</option>
                                <option key="250" value="250">250</option>
                                <option key="500" value="500">500</option>
                                <option key="750" value="750">750</option>
                                <option key="1000" value="1000">1000</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={3} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicStato">Stato</Label>
                        <Input type="select" className="search" name="selRicStato" 
                        id="selRicStato" placeholder="" bsSize="sm"
                            value={RicStato} onChange={handleRicStato}>
                                <option key="Tutti" value="">Tutti</option>
                                {GetItemStatoPreventivoCert().map((item) => (
                                    <option key={item.stato_value} value={item.stato_value}>{item.stato_desc}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={6} lg={7} md={4} xs={12}>
                    <FormGroup>
                        <Label for="txtRicAzienda">Azienda</Label>
                        <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                        url={process.env.REACT_APP_API_URL+'customersinglelist'}
                        idparent={IDCommerciale} 
                        optionname="RagioneSociale" optionvalue="id"
                        customvalue={RicAzienda} onChange={handleRicAzienda} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={4} lg={5} md={4} xs={12}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                    Commerciale
                                </label>
                           </Col> 
                           {
                            RicCommerciale!=="" && (
                           <Col md="6">
                                <label style={{fontSize:'15px'}}>
                                    <input type="checkbox" name="chkRicSubalterno" onChange={handleRicSubalterno} checked={RicSubalterno} /> {'   '}
                                    Includi Subalterni
                                </label>
                           </Col> )
                           }
                        </Row>
                        <MyAutoComplete id="txtRicCommerciale" name="txtRicCommerciale" 
                        url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                        idparent={IDCommerciale}
                        optionname="DesCommerciale" optionvalue="id"
                        customvalue={RicCommerciale} onChange={handleRicCommerciale} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataPreventivoInizio">Da Data</Label>
                        <MyDatePicker name="txtRicDataPreventivoInizio" className="form-control-sm"
                            value={RicDataPreventivoInizio} onChange={handleRicDataPreventivoInizio} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataPreventivoFine">A Data</Label>
                        <MyDatePicker name="txtRicDataPreventivoFine" className="form-control-sm" 
                            value={RicDataPreventivoFine} onChange={handleRicDataPreventivoFine} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col> 
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            {((parseFloat(IDCommerciale)>0 || UserRole===Ruolo_Admin) && RicStato!==Stato_Preventivo_Annullato) ? 
            (<Grid item xs={12}>
                {UserRole===Ruolo_Admin && (<Row>&nbsp;</Row>)}
                <Row>
                    <Col xs={6} md={2} lg={2} xl={2}><Button onClick={toggleNewPreventivo} className="btn-mbg-cert">Nuovo</Button></Col>
                    <Col xs={6} md={2} lg={4} xl={1}><ExportToExcel data={data} userrole={UserRole}/></Col>
                    <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> {UserRole===Ruolo_Admin && ("Imponibile &euro;" + ccyFormat(ImponibileTotale))} </b></div></Col>
                    <Col lg={2} md={3} xl={3}><div style={{background:"#deeaf6",padding:"4px",border:"1px solid rgba(224, 224, 224, 1)"}}><b> {UserRole===Ruolo_Admin && ("Totale &euro;"  + ccyFormat(PrezzoTotale))} </b></div></Col>
                </Row>
            </Grid>):(
            <Row>
                <Col xs={6} md={4} lg={2} xl={1}><ExportToExcel data={data} userrole={UserRole}/></Col>
            </Row>
            )}
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={index}
                                className="rt-tr-group"
                            >
                                <TableCell id={labelId} scope="row">
                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleOperDialog(row.id,row.Azienda_id,row.File_Name,row.NumPreventivo,row.DataPreventivo)}>
                                        <MoreVertIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.NumPreventivo}</TableCell>
                                <TableCell>{row.DataPreventivo}</TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{ (UserRole===Ruolo_Admin) ? (row.DesCommerciale):(row.DescImpianto)}</TableCell>
                                <TableCell>{GetImponibileRow(row.PrezzoTotale,row.Sconto,row.Annullato)}</TableCell>
                                <TableCell>{row.CodiceIva}</TableCell>
                                <TableCell>{GetSpese(row.SpeseKm,row.CostoSpesa)}</TableCell>
                                <TableCell>{GetPrezzoRow(row.PrezzoTotale,row.Sconto,row.CodiceIva,row.Annullato,row.SpeseKm,row.CostoSpesa)}</TableCell>
                                <TableCell>{GetTextStatoPreventivoCert(
                                                               row.Approvato,
                                                               row.Annullato,
                                                               row.Inviato)}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={10} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[20, 40, 60]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 