import React, {useState,useRef,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from '../MyDatePicker';
import MyAutoComplete from '../MyAutoComplete';
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {add_years,GetRole,GetTextRischioAteco,VerifyPDF,GetTitServizi, add_months} from "../../Modules/Funzioni";
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import imgSearch from "../../img/search.png";
import {GetToken} from "../../Modules/Funzioni";
import { Ruolo_Admin } from "../../Modules/Costanti";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
    
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicTitolo, setRicTitolo] = React.useState("");
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);   
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);
    
    
//alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {  
        setRicDescrizione(event.target.value);  
        setDisableFilter(false);
    };

    const handleRicTitolo = (event) => {  
        setRicTitolo(event.target.value);  
        setDisableFilter(false);
    };

    const handleRowClick = (_id,_titolo,_descrizione,_rischio,_validita) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { id: _id, Titolo: _titolo, Descrizione: _descrizione, MesiValidita: _validita, Rischio: _rischio},
        ];
        setSelectedData(ArrayRis);
      };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(!isSending);
    }; 

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
    
   
    useEffect(() => {    
        if (props.idazienda!=="" && props.tiponomina!==""){
            
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'servizilistazienda'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);
                    fd.append("Azienda_id", props.idazienda);
                    fd.append("Titolo", RicTitolo);
                    fd.append("TipoNomina", props.tiponomina);
                    
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
            })(); 
    }
        
}, [isSending,props.idazienda,props.tiponomina]); 

    
    const isSelected = (name) => selected===name;

  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
            <div className="small" style={{width:"98%"}}>
                <Row>
                    <Col xs={3}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicTitolo">Titolo</Label>
                            <Input type="select" className="search" name="selRicTitolo" 
                            id="selRicTitolo" placeholder="" bsSize="sm"
                                value={RicTitolo} onChange={handleRicTitolo}>
                                    <option key="" value="">Tutti</option>
                                    {GetTitServizi().map((item) => (
                                    <option key={item.titolo_value} value={item.titolo_value}>{item.titolo_name}</option>
                                ))}
                            </Input>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>
                    
                    <Col xs={7}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                            <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                            value={RicDescrizione} onChange={handleRicDescrizione}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col> 
                    <Col xs={2}>
                        <FormGroup>
                            <Label bsSize="sm">&nbsp;</Label>
                            <Button type="button" className="btn-mbg btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                    
                </Row>
            </div>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Titolo</TableCell>
                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                <TableCell className="grid-head" align="center">Rischio</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            
                            { data.length>0? (
                                data.map((row) => {
                                    const isItemSelected = isSelected(row.id);
                            
                                    return (
                                        
                                    <TableRow key={row.id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.id,row.Titolo,row.Descrizione,row.Rischio,row.MesiValidita)}>
                                        <TableCell>{row.Titolo}</TableCell>
                                        <TableCell>{row.Descrizione}</TableCell>
                                        <TableCell>{GetTextRischioAteco(row.Rischio)}</TableCell>
                                    </TableRow>
                                    );
                                })
                            ):(
                                <TableRow>
                                    <TableCell colSpan={3} style={{textAlign:"center"}}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}

export default function ModDocumento (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare la modifica controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [IDAzienda, setIDAzienda] = React.useState("");
    const [IDOrdine, setIDOrdine] = React.useState("");
    const [Azienda, setAzienda] = React.useState("");
    const [DataRilascio, setDataRilascio] = React.useState("");
    const [DataScadenza, setDataScadenza] = React.useState("");
    const [DesRischio, setDesRischio] = React.useState("");
    const [Titolo, setTitolo] = React.useState("");
    const [DescServizio, setDescServizio] = React.useState("");
    const [NumOrdine, setNumOrdine] = React.useState("");
    const [DataOrdine, setDataOrdine] = React.useState("");
    const [MesiValidita, setMesiValidita] = React.useState("");
    const [IDIntestatario, setIDIntestatario] = React.useState("0");
    const [FileDocumento, setFileDocumento] = React.useState("");
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [DocumentoName, setDocumentoName] = React.useState("");
    const [IDServizio, setIDServizio] = React.useState("");
    const [IDServizioElenco, setIDServizioElenco] = React.useState("");
    const [FileErr, setFileErr] = React.useState(false);
    let UserRole =GetRole(localStorage.getItem("token"));
    let isAdmin=false;
    if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true
    const hiddenFileSostituisci = React.useRef(null);

    const handleAzienda = (value,text) => {
        setIDAzienda(value);
        setAzienda(text); 
        setIDIntestatario("0");
        svuotaSelServizio();
    }; 

    const svuotaSelServizio = () => {
        setIDServizio("");
        setTitolo("");
        setDescServizio("");
        setMesiValidita("");
        setDesRischio("");
        setDataScadenza("");
    }; 

    const handleClickSostituisci = event => {
        hiddenFileSostituisci.current.click();
    };

    const handleFile= (event) => {
        const _file = event.target.files[0];
        const msg= VerifyPDF(_file);
        
        if (msg!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFileErr(true);
        }
        else {
            setFileDocumento(_file);
            setFileErr(false);
        }
        
    }
    const handleOpenSelServizio = (event) => { 
        if (IDAzienda!==""){
            setOpenSelServizio(true);  
        } 
        else {
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert("Selezionare prima l'azienda di appartenenza!");
            setOpenAlert(true);
        }
        
    };

    const CloseSelServizio = (event) => {  
        setOpenSelServizio(false);  
    };

    const onConfirmSelServizio = (RisArray) => {  

        setIDServizio(RisArray[0].id);
        setTitolo(RisArray[0].Titolo);
        setDescServizio(RisArray[0].Descrizione);
        setMesiValidita(RisArray[0].MesiValidita);
        setDesRischio(GetTextRischioAteco(RisArray[0].Rischio));
        setOpenSelServizio(false); 
        setDataScadenza(add_months(DataRilascio,RisArray[0].MesiValidita)); 
        
    };
    const handleMesiValidita = (event) => {
        setMesiValidita(event.target.value);  
        setDataScadenza(add_months(DataRilascio, event.target.value));
    };
    const handleDataRilascio = (value) => {
        setDataRilascio(value);  
        setDataScadenza(add_months(value, MesiValidita));
    };
       
    React.useEffect(() => {
        if (props.id!==""){
            LoadData();
        }
    }, [props.id]);
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    const LoadData = (event) => {
        if(props.id!==""){
            setIsLoading(true); 
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'nomina/' + props.id;               
                    result = await ax.post(url);
                    setAzienda(result.data[0].RagioneSociale);
                    setIDAzienda(result.data[0].Azienda_id);
                    setIDOrdine(result.data[0].Ordine_id);
                    setIDServizio(result.data[0].Servizio_id);
                    setIDServizioElenco(result.data[0].ServizioElenco_id);
                    if (result.data[0].DataRilascio===null){
                        setDataRilascio("");
                    }
                    else{
                        setDataRilascio(result.data[0].DataRilascio);
                    }
                    
                    setDataScadenza(add_months(result.data[0].DataRilascio,result.data[0].MesiValidita));
                    setDesRischio(GetTextRischioAteco(result.data[0].Rischio));
                    setTitolo(result.data[0].Titolo);
                    setDescServizio(result.data[0].Descrizione);
                    setNumOrdine(result.data[0].NumOrdine);
                    setDataOrdine(result.data[0].DataOrdine);
                    setMesiValidita(result.data[0].MesiValidita);
                    setDocumentoName(result.data[0].Attestato_Name);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setSeverityAlert("warning")
                    setVerticalAlert("center")
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                
            })();  
        }  
    }

        const submitForm= (event) => 
        {
            if (! FileErr) {
                if (validator.current.allValid()) {
                    setisSubmit(true);
                    const fd = new FormData();
                    fd.append("id", props.id);
                    fd.append("Ordine_id", IDOrdine);
                    fd.append("Azienda_id", IDAzienda);
                    fd.append("RuoloIntestatario", "0");
                    fd.append("Intestatario_id", "0");
                    fd.append("Servizio_id", IDServizio);
                    fd.append("ServizioElenco_id", IDServizioElenco);
                    fd.append('DataRilascio', DataRilascio);
                    fd.append('MesiValidita', MesiValidita);
                    if (DataRilascio!=="" && FileDocumento!=="" && (DocumentoName==="" || DocumentoName===null)){
                        fd.append('Attestato_Name', "Documento.pdf");
                    }
                    else {
                        fd.append("Attestato_Name", DocumentoName);
                    }
                    fd.append('attestato', FileDocumento);
                        const token = GetToken(localStorage.getItem("token"));
                            const ax= axios;
                            ax.defaults.headers.common['Authorization']="Bearer " + token;
                            const axiosConfig = {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                }
                            }
                            
                            ax
                            .post(process.env.REACT_APP_API_URL+'nominaupdate', fd,axiosConfig)
                            .then((response) => {
                                
                                if (response.status === 200) {
                                    if (response.data.error==="OK"){
                                        props.handleClose();
                                        props.onUpdate();
                                    }
                                    else {
                                        let msg="";
                                        let ris=JSON.stringify(response.data);
                                        ris=ris.replaceAll("\"", "");
                                        ris=ris.replaceAll("{", "");
                                        ris=ris.replaceAll("}", "");
                                        ris=ris.replaceAll("[", "");
                                        ris=ris.replaceAll("]", "");
                                        msg="errore durante la modifica! " + ris;
                                        setSeverityAlert("error")
                                        setVerticalAlert("top")
                                        setMsgAlert(msg);
                                        setOpenAlert(true);
                                    }
                                }
                                else
                                { 
                                const msg=GetMessage(response.status,response.data.message);
                                setSeverityAlert("error")
                                setVerticalAlert("top")
                                setMsgAlert(msg);
                                setOpenAlert(true);
                                
                                }
                            })
                            .catch((error) => {
                                let msg=""
                                if (error.response!==undefined) {
                                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                                }
                                else
                                {
                                    msg=GetMessage(undefined,"");
                                }
                                setSeverityAlert("error")
                                setVerticalAlert("top")
                                setMsgAlert(msg);
                                setOpenAlert(true);
                                
                            });
                            setisSubmit(false);
    
                } else {
                    validator.current.showMessages();
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(MsgAlertDefault);
                    setOpenAlert(true);
                }
            }
            else
            {
                setSeverityAlert("warning")
                setVerticalAlert("top")
                setMsgAlert("File selezionato non corretto, scegliere un altro file!");
                setOpenAlert(true);
            }
            
        }
 
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.bgHead}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Dettaglio Documento
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <form autoComplete="off">
                        <ElencaServizio open={OpenSelServizio} 
                            handleClose={CloseSelServizio} 
                            handleConfirm={onConfirmSelServizio} 
                            tiponomina={0}
                            idazienda={IDAzienda}/>
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtNumOrdine">Num. Ordine</Label>
                                                    <Input type="text" name="txtNumOrdine" id="txtNumOrdine" placeholder=""
                                                    value={NumOrdine} readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataOrdine">Del</Label>
                                                    <Input type="text" name="txtDataOrdine" id="txtDataOrdine" placeholder=""
                                                    value={DataOrdine} readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtAzienda">Azienda *</Label>
                                                    <MyAutoComplete id="txtAzienda" name="txtAzienda" 
                                                    url={process.env.REACT_APP_API_URL+'customersinglelist'} 
                                                    optionname="RagioneSociale" optionvalue="id"
                                                    onBlur={() => validator.current.showMessageFor('Azienda')}
                                                    customvalue={Azienda} onChange={handleAzienda} 
                                                    readOnly={NumOrdine!=="" && NumOrdine!==null}
                                                    disabled = {(NumOrdine!=="" && NumOrdine!==null)? true : false} 
                                                    />
                                                    {validator.current.message('Azienda', Azienda, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={1}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button type="button" className="btn-mbg btn-small btn-block" onClick={handleOpenSelServizio} 
                                                    disabled = {((NumOrdine!=="" && NumOrdine!==null))? true : false} 
                                                    >Seleziona</Button>
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                                <FormGroup>
                                                    <Label for="txtTitolo">Titolo</Label>
                                                    <Input type="text" name="txtTitolo" autoComplete="new-password" placeholder=""
                                                    value={Titolo} disabled readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtDescServizio">Servizio *</Label>
                                                    <Input type="text" name="txtDescServizio" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('DescServizio')}
                                                    value={DescServizio} 
                                                    disabled readOnly
                                                    />
                                                    {validator.current.message('DescServizio', DescServizio, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtRischio">Rischio</Label>
                                                    <Input type="text" name="txtRischio" autoComplete="new-password" placeholder=""
                                                    value={DesRischio} disabled readOnly
                                                    />
                                                </FormGroup>
                                            </Col>                                            
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataRilascio">D. Rilascio</Label>
                                                    <MyDatePicker name="txtDataRilascio" 
                                                    onBlur={() => validator.current.showMessageFor('DataRilascio')}
                                                    value={DataRilascio} onChange={handleDataRilascio} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('DataRilascio', DataRilascio, 'string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtValidita">Mesi Validità</Label>
                                                    <Input type="text" name="txtValidita" autoComplete="new-password" placeholder=""
                                                    onChange={handleMesiValidita}
                                                    value={MesiValidita} disabled= {((isAdmin))? false : true} 
                                                    readOnly= {((isAdmin))? false : true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label for="txtDataScadenza">Scadenza</Label>
                                                    <Input type="text" name="txtDataScadenza" autoComplete="new-password" placeholder=""
                                                    value={DataScadenza} disabled readOnly
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {
                                                DocumentoName ==="" || DocumentoName ===null ? (
                                                    IDAzienda!=="" && (
                                                    <Col md={5}>
                                                        <FormGroup>
                                                            <Label for="txtFileDocumento">&nbsp;</Label>
                                                            <Input type="file" name="txtFileDocumento" autoComplete="new-password"  onChange={handleFile} placeholder=""
                                                            />
                                                        </FormGroup>
                                                    </Col>)
                                                ):(
                                                <Col md={5}>
                                                    <FormGroup>
                                                        <Label>&nbsp;</Label>
                                                        <input
                                                            type="file"
                                                            ref={hiddenFileSostituisci}
                                                            onChange={handleFile}
                                                            style={{display: 'none'}} 
                                                        />
                                                        <Label>&nbsp;</Label>
                                                        <Button onClick={handleClickSostituisci} className="btn-mbg btn-block">Sostituisci Documento</Button>
                                                    </FormGroup>
                                                </Col>
                                                )
                                            }
                                        </Row> 
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}