import React, { Component } from "react";
import { Redirect, Switch, Route} from "react-router-dom";
/*import {Button, Form, FormGroup, Input, Label} from "reactstrap";
import Footer from "../Components/Footer";*/
import Header from "../Components/Header";
import Sidebar from "../Components/SideBar";
import ElAziende from "../Components/ElAziende";
import ElDipendenti from "../Components/CPServ/ElDipendenti";
import ElResponsabili from "../Components/ElResponsabili";
import ElCommerciali from "../Components/ElCommerciali";
import ElMarketingCampaign from "../Components/ElMarketingCampaign";
import ElIspettori from "../Components/CPCert/ElIspettori";
import ElUser from "../Components/ElUser";
import ElServizi from "../Components/CPServ/ElServizi";
import ElListini from "../Components/CPServ/ElListini"
import ElContrattiCPServ from "../Components/CPServ/ElContratti";
import ElOrdiniCPServ from "../Components/CPServ/ElOrdini";
import ElOrdiniCPCert from "../Components/CPCert/ElOrdini";
import ElPreventiviCPCert from "../Components/CPCert/ElPreventivi";
import ElAttestati from "../Components/CPServ/ElAttestati";
import ElDocumenti from "../Components/CPServ/ElDocumenti";
import ElModuliNomine from "../Components/CPServ/ElModuliNomine";
import ElAllegatiEmail from "../Components/CPServ/ElAllegatiEmail";
import ElServiziScaricheAtmosferiche from "../Components/CPCert/ElServiziScaricheAtmosferiche";
import ElServiziMessaTerra from "../Components/CPCert/ElServiziMessaTerra";
import ElServiziRischioEsplosione from "../Components/CPCert/ElServiziRischioEsplosione";
import ElStrumentiVerifiche from "../Components/CPCert/ElStrumentiVerifiche";
import ElVerifiche from "../Components/CPCert/ElVerifiche";
import ElImpElettrici from "../Components/CPCert/ElImpElettrici";
import ElPersSpeseDirettori from "../Components/CPCert/ElPersSpeseDirettori";
import ElPersSpeseOrdini from "../Components/CPCert/ElPersSpese";
import NotFound from "../Components/NotFound";
import {app_cpCert, app_cpService,Ruolo_Commerciale} from '../Modules/Costanti';
import {GetRole} from "../Modules/Funzioni";
export default class Home extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        
        const token = localStorage.getItem("token");
        
        if (token==null || token ==="") {
            return <Redirect to="/login"/>;
        }
        const app_selected =process.env.REACT_APP_APPSELECT;
        const userRole=GetRole(token);
        if (isNaN(parseInt(app_selected)) || parseInt(app_selected)===0){
            return <Redirect to="/welcome" />;
        }

        return (
            /*<div className="page-container">
                <Header/>
                <div className="content-wrap row">
                    <Sidebar items={items}/>
                    <div className="content-form col-sm"><span><h3 className="text-decoration-none">Anagrafica ElAziende</h3><hr/></span>
                        <Grid/>
                    </div>
                </div>
                <Footer/>
            </div>*/
            <div className="page-container container-fluid">
                <Header/>
                <div className="content-wrap row">
                    <Sidebar/>
                    {(() => {
                            switch (parseInt(app_selected))
                            {
                                case app_cpService:
                                    {
                                        return (
                                        <div id="content-form" className="col-sm">
                                            <Switch>
                                                <Route exact path="/">
                                                </Route>
                                                <Route path="/home">
                                                </Route>
                                                <Route path="/ordini">
                                                    <ElOrdiniCPServ />
                                                </Route>
                                                <Route path="/contratti">
                                                    <ElContrattiCPServ />
                                                </Route>
                                                <Route path="/aziende">
                                                    <ElAziende />
                                                </Route>
                                                <Route path="/dipendenti">
                                                    <ElDipendenti/>
                                                </Route>
                                                <Route path="/rappresentanti">
                                                    <ElResponsabili/>
                                                </Route>
                                                <Route path="/commerciali">
                                                    <ElCommerciali/>
                                                </Route>
                                                <Route path="/utenti">
                                                    <ElUser/>
                                                </Route>
                                                <Route path="/servizi">
                                                    <ElServizi/>
                                                </Route>
                                                <Route path="/listini">
                                                    <ElListini/>
                                                </Route>
                                                {userRole!==Ruolo_Commerciale &&
                                                (<Route path="/attestati">
                                                    <ElAttestati/>
                                                </Route>
                                                )}
                                                {userRole!==Ruolo_Commerciale &&
                                                (<Route path="/documenti">
                                                    <ElDocumenti/>
                                                </Route>
                                                )}
                                                <Route path="/modnomine">
                                                    <ElModuliNomine/>
                                                </Route>
                                                <Route path="/allemail">
                                                    <ElAllegatiEmail/>
                                                </Route>
                                                <Route path="/campagne">
                                                    <ElMarketingCampaign/>
                                                </Route>
                                                <Route>
                                                    <NotFound />
                                                </Route>
                                            </Switch>
                                        </div>)
                                    }
                                case app_cpCert:
                                    {
                                        return(
                                        <div id="content-form" className="col-sm">
                                            <Switch>
                                                <Route exact path="/">
                                                </Route>
                                                <Route path="/home">
                                                </Route>
                                                <Route path="/ordini">
                                                    <ElOrdiniCPCert />
                                                </Route>
                                                <Route path="/preventivi">
                                                    <ElPreventiviCPCert />
                                                </Route>
                                                <Route path="/aziende">
                                                    <ElAziende />
                                                </Route>
                                                <Route path="/verifiche">
                                                    <ElVerifiche />
                                                </Route>
                                                <Route path="/modnomine">
                                                    <ElModuliNomine/>
                                                </Route>
                                                <Route path="/rappresentanti">
                                                    <ElResponsabili/>
                                                </Route>
                                                <Route path="/commerciali">
                                                    <ElCommerciali/>
                                                </Route>
                                                <Route path="/ispettori">
                                                    <ElIspettori/>
                                                </Route>
                                                <Route path="/utenti">
                                                    <ElUser/>
                                                </Route>
                                                <Route path="/strumentiverifiche">
                                                    <ElStrumentiVerifiche/>
                                                </Route>
                                                <Route path="/listini">
                                                </Route>
                                                <Route path="/servizisa">
                                                    <ElServiziScaricheAtmosferiche/>
                                                </Route>
                                                <Route path="/serviziexp">
                                                    <ElServiziRischioEsplosione/>
                                                </Route>
                                                <Route path="/servizimt">
                                                    <ElServiziMessaTerra/>
                                                </Route>
                                                <Route path="/impelettrici">
                                                    <ElImpElettrici/>
                                                </Route>
                                                <Route path="/spesedirettori">
                                                    <ElPersSpeseDirettori/>
                                                </Route>
                                                <Route path="/persspeseordini">
                                                    <ElPersSpeseOrdini/>
                                                </Route>
                                                <Route>
                                                    <NotFound />
                                                </Route>
                                            </Switch>
                                        </div>)
                                    }
                            }
                        })()} 
                </div>
            </div>
        );
    }
}