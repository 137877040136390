import React, {useRef,useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import MatButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import MyNumInput from '../../Components/MyNumInput';
import MyAlert from '../../Components/MyAlert';
import MyAlertSelServizio from '../../Components/MyAlert';
import {GetMessage} from "../../Modules/Message";
import { Redirect } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MyConfirmInvioPreventivo from '../../Components/MyConfirm';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { RadioGroup } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import {GetIDCommerciale,ccyFormat,GetToken, GetRole} from "../../Modules/Funzioni";
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import imgSearch from "../../img/search.png";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import MyConfirmDelServizio from '../../Components/MyConfirm';
import MyAutoComplete from '../../Components/MyAutoComplete';
import { Ruolo_Admin } from "../../Modules/Costanti";
import MyPhoneInput from "../../Components/MyPhoneInput";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
    
      BtnEdit: {
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));


  function ElencaServizio(props) {
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [RicDescrizione, setRicDescrizione] = React.useState("");
    const [isSending, setIsSending] = useState(true);   
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selected, setSelected] = React.useState("");
    const [SelectedData, setSelectedData] = React.useState([]);
        
//alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleRicDescrizione = (event) => {  
        setRicDescrizione(event.target.value);  
        setDisableFilter(false);
    };

    const handleRowClick = (_id,_descrizione,_prezzo) => {
        let newSelected = "";
        newSelected = _id;
        setSelected(newSelected);
        const ArrayRis = [
            { id: _id, Descrizione: _descrizione, Prezzo: _prezzo, Quantita: 1},
        ];
        setSelectedData(ArrayRis);
      };

    const onFilter = () => {
        setDisableFilter(true);
        setSelected("");
        setSelectedData([]);
        setIsSending(true);
    }; 

    const onConfirm = () => {
        props.handleConfirm(SelectedData);
    }
    
    React.useEffect(() => {
        setSelected("");
    }, [props.open]);
   
    useEffect(() => {    
        if (isSending){
            setIsLoading(true);
            const token =  GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'serviziosalist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("Descrizione", RicDescrizione);
                    
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);

                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
                setIsSending(false);
            })(); 
    }
        
}, []); 
    
    const isSelected = (name) => selected===name;

  return (
      <Dialog 
        fullScreen={fullScreen}
        open={props.open} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xl"
      >
        <DialogTitle id="responsive-dialog-title">
            <div className="small" style={{width:"98%"}}>
                <Row>         
                    <Col xs={6}>
                        <FormGroup>
                            <Label bsSize="sm" for="selRicDescrizione">Descrizione</Label>
                            <Input type="search" className="search" name="txtRicDescrizione" placeholder=""
                            value={RicDescrizione} onChange={handleRicDescrizione}
                            bsSize="sm"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col> 
                    <Col xs={2}>
                        <FormGroup>
                            <Label bsSize="sm">&nbsp;</Label>
                            <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        </DialogTitle>
        <DialogContent className={classes.FormPopup} >
            <DialogContentText>
                <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
                {isLoading ? ( <CircularProgress className="sp-center" size={200} /> ) : (<span></span>) }
                <TableContainer component={Paper} className={classes.container}>
                    <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                        <TableHead>            
                            <TableRow>
                                <TableCell className="grid-head" align="center">Descrizione</TableCell>
                                <TableCell className="grid-head" align="center">Prezzo</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            { data.length>0? (
                                data.map((row) => {
                                    const isItemSelected = isSelected(row.id);
                            
                                    return (
                                        
                                    <TableRow key={row.id} hover tabIndex={-1} className={isItemSelected ? 'row-selected' : ''} onClick={(event) => handleRowClick(row.id,row.Descrizione,row.Prezzo)}>
                                        <TableCell>{row.Descrizione}</TableCell>
                                        <TableCell align="right">{row.Prezzo}</TableCell>
                                    </TableRow>
                                    );
                                })
                            ):(
                                <TableRow>
                                    <TableCell colSpan={3} style={{textAlign:"center"}}><h4>- Nessun Servizio Presente -</h4></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"30%"}}>
                <Button  onClick={onConfirm} className="btn-mbg-cert btn-block" disabled={selected===""}>
                    Conferma 
                </Button>
            </div>
            <div style={{width:"30%"}}>
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
  );
}

function CreateTableServizi(props) {
    const classes = useStyles();
    const[Rows, setRows] = React.useState([]);
    const [PercSconto, setPercSconto] = React.useState(0);
    const [PercIva, setPercIva] = React.useState(process.env.REACT_APP_IVA);
    const [SpeseKm, setSpeseKm] = React.useState(0);
    const [NumKm, setNumKm] = React.useState(0);
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [DescrizioneSpesa, setDescrizioneSpesa] = React.useState("");
    const [CostoPerKm, setCostoPerKm] = React.useState(process.env.REACT_APP_COSTOKM);
    const [CostoScontato, setCostoScontato] = React.useState(0);
    const [CostoImponibile, setCostoImponibile] = React.useState(0);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoIva, setCostoIva] = React.useState(0);
    const [ApplicaSconto, setApplicaSconto] = React.useState(0);
    const [VisualizzaCheckSconto, setVisualizzaCheckSconto] = React.useState(false);
    
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    const handleTipoSpesa = (value,text) => {
        if (value!==""){
            var arrValue = value.split("|");
            setDescrizioneSpesa(text);  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,arrValue[1]);
            props.onChangeCostoSpesa(arrValue[1],arrValue[0]);
        }
        else
        {
            setDescrizioneSpesa("");  
            CalcolaPrezzi(PercSconto,PercIva,NumKm,0);
            props.onChangeCostoSpesa(0,0);
        }
    }; 
    const handleClickAltro  = () =>{
        setVisualizzaCheckSconto(!VisualizzaCheckSconto);
    }
    const handleApplicaSconto= (event) =>{
        setApplicaSconto(!ApplicaSconto);
        setPercSconto(0);
        CalcolaPrezzi(0,PercIva,NumKm, CostoSpesa);
        props.onChangeSconto(0);
    }
    const handlePercSconto= (event) =>{
        if (parseFloat(event.target.value)>100){
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value=0;

        }
        let _PercSconto=parseFloat(event.target.value);
        if (isNaN(_PercSconto)) _PercSconto=0;
        setPercSconto(_PercSconto);
        CalcolaPrezzi(_PercSconto,PercIva,NumKm, CostoSpesa);
        props.onChangeSconto(_PercSconto);
        
    }
    const handleFocusPercSconto= (event) =>{
        event.target.select();
    }
    const handlePercIva= (event) =>{
        if (parseFloat(event.target.value)>100){
            setMsgAlert("Valore massimo: 100");
            setOpenAlert(true);
            event.target.value=0;

        }
        let _PercIva=parseFloat(event.target.value);
        if (isNaN(_PercIva)) _PercIva=0;
        setPercIva(_PercIva);
        CalcolaPrezzi(PercSconto,_PercIva,NumKm, CostoSpesa);
        props.onChangeIva(_PercIva);
        
    }

    const handleNumKm= (event) =>{
        if (parseFloat(event.target.value)>10000){
            setMsgAlert("Valore massimo: 10000");
            setOpenAlert(true);
            event.target.value=0;

        }
        let _NumKm=parseFloat(event.target.value);
        if (isNaN(_NumKm)) _NumKm=0;
        setNumKm(_NumKm);
        CalcolaPrezzi(PercSconto,PercIva,_NumKm, CostoSpesa);
        props.onChangeNumKm(_NumKm);
        
    }

    const handleFocusPercIva= (event) =>{
        event.target.select();
    }
    const handleFocusNumKm= (event) =>{
        event.target.select();
    }
    const handleQuantita= (_num,_id) =>{
        if (props.rows.length>0){
            props.rows.map((item) => {
                if (item.id===_id){
                    item.Quantita=_num;
                }
                return true;
            });
        }
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa);
    }
    const CalcolaPrezzi = (_PercSconto,_PercIva,_NumKm, _CostoSpesa)=> {
        let newCostoImponibile=0;
        let newCostoScontato=0;
        let newCostoSpesa=parseFloat(_CostoSpesa);
        let newCostoImpianto=parseFloat(props.CostoImpianto);
        let newCostoImpiantoExp=parseFloat(props.CostoImpiantoExp);
        let newCostoSpeseKm=_NumKm*CostoPerKm;
        let newCostoTotale=0;
        let newCostoIva=0;
        if (props.rows.length>0){
            props.rows.map((item) => {
                
                newCostoImponibile +=(parseFloat(item.Prezzo)*parseFloat(item.Quantita)); 
                
                return newCostoImponibile;
            });
        }
        
        
        newCostoScontato=((newCostoImponibile- (newCostoImponibile*(_PercSconto/100))) +(newCostoImpianto- (newCostoImpianto*(_PercSconto/100))) +(newCostoImpiantoExp- (newCostoImpiantoExp*(_PercSconto/100))));
        
        newCostoScontato=parseFloat(newCostoScontato.toFixed(2));
        newCostoIva=newCostoScontato*(parseFloat(_PercIva)/100);
        newCostoIva=parseFloat(newCostoIva.toFixed(2));
        newCostoSpesa=parseFloat(newCostoSpesa.toFixed(2));
        newCostoSpeseKm=parseFloat(newCostoSpeseKm.toFixed(2));
        newCostoTotale=newCostoIva + newCostoScontato + newCostoSpeseKm  + newCostoSpesa;
        setCostoImponibile(newCostoImponibile);
        setSpeseKm(newCostoSpeseKm);
        setCostoSpesa(newCostoSpesa);
        setCostoScontato(newCostoScontato);
        setCostoIva(newCostoIva);
        setCostoTotale(newCostoTotale);
        props.onChangeCostoTotale(newCostoTotale);
    }
    React.useEffect(() => {
        setRows(props.rows);
        //alert("state rows");
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa);
    }, [props.rows]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa);
    }, [props.onRicalcola]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa);
    }, [props.CostoImpianto]);
    
    React.useEffect(() => {
        CalcolaPrezzi(PercSconto,PercIva,NumKm, CostoSpesa);
    }, [props.CostoImpiantoExp]);

    return (
        <>
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="spanning table" size="small">
            <TableHead>            
                <TableRow>
                    <TableCell className="grid-head"></TableCell>
                    <TableCell className="grid-head">Descrizione</TableCell>
                    <TableCell className="grid-head" align="right">Prezzo</TableCell>
                    <TableCell className="grid-head">Q.ta</TableCell>
                    <TableCell className="grid-head" align="right">Importo</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                { Rows.length>0? (Rows.map((row) => {
                    return (
                    <TableRow key={row.id} className="rt-tr-group">
                        <TableCell>
                            <Avatar className={"btn-mbg-cert " + classes.BtnEdit}  onClick={() => props.onDelete(row.id)}>
                                <DeleteIcon />
                            </Avatar>
                        </TableCell>
                        <TableCell>{row.Descrizione}</TableCell>
                        <TableCell align="right">{row.Prezzo}</TableCell>
                        <TableCell><MyNumInput otherparam={row.id} value={row.Quantita} onChange={handleQuantita}/></TableCell>
                        <TableCell align="right">{ccyFormat(parseFloat(row.Prezzo)*parseFloat(row.Quantita))}</TableCell>
                    </TableRow>
                    );}
                )):(
                    <TableRow>
                        <TableCell colSpan={5} style={{textAlign:"center"}}><h5>- Nessun Servizio di Scariche Atmosferiche Selezionato -</h5></TableCell>
                    </TableRow>
                )}
                <TableRow className="rt-tr-group">
                    <TableCell colSpan={5}>&nbsp;</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Totale Scariche Atmosferiche</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(parseFloat(CostoImponibile))}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Totale Verifica Impianto Messa a Terra</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(parseFloat(props.CostoImpianto))}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                        <b>Totale Verifica Maggior Rischio Esplosione</b>
                    </TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(parseFloat(props.CostoImpiantoExp))}</b></TableCell>
                </TableRow>
                
                { ApplicaSconto ?(
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell  style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)",display:"flex"}}>
                        <span style={{paddingTop:"4px",width:"200px"}}><b>Sconto</b></span> 
                        <Input type="number" name="txtSconto" min="0" max="99" autoComplete="new-password"
                            onChange={handlePercSconto} value={PercSconto}
                            onFocus={handleFocusPercSconto}
                            style={{width:"90px"}} bsSize="sm"
                        />
                        <span style={{paddingTop:"4px"}}>  <b>&nbsp;&nbsp;%</b></span> 
                    </TableCell>
                    <TableCell colSpan={2} align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(CostoScontato)}</b></TableCell>
                </TableRow>):('')}
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)",display:"flex"}}>
                        <span style={{paddingTop:"4px",width:"200px"}}><b>IVA</b></span> 
                        <Input type="number" name="txtIva" min="0" max="99" autoComplete="new-password"
                            onChange={handlePercIva} value={PercIva}
                            onFocus={handleFocusPercIva}
                            style={{width:"90px"}} bsSize="sm"
                        />
                        <span style={{paddingTop:"4px"}}>  <b>&nbsp;&nbsp;%</b></span> 
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(CostoIva)}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)",display:"flex"}}>
                        <span style={{paddingTop:"4px",width:"200px"}}><b>Rimborso Km {process.env.REACT_APP_COSTOKM} &euro;</b></span> 
                        <Input type="number" name="txtNumKm" min="0" max="9999" autoComplete="new-password"
                            onChange={handleNumKm} value={NumKm}
                            onFocus={handleFocusNumKm}
                            style={{width:"90px"}} bsSize="sm"
                        />
                        <span style={{paddingTop:"4px"}}>  <b>&nbsp;&nbsp;Km</b></span> 
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(SpeseKm)}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}/>
                    <TableCell style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}>
                        <MyAutoComplete id="txtTipoSpesa" name="txtTipoSpesa" 
                            url={process.env.REACT_APP_API_URL+'persspeseordinisinglelist'} 
                            optionname="Descrizione" optionvalue="Codice"
                            customvalue={DescrizioneSpesa} onChange={handleTipoSpesa}
                            placeholder="Nessuna Spesa selezionata"
                        /> 
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6"}} align="right"><b>{ccyFormat(CostoSpesa)}</b></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2}>
                        <div style={{display:"flex"}}>
                            <Button className="btn-mbg-cert btn-small" onClick={handleClickAltro}>Altro { VisualizzaCheckSconto ?('<<'):('>>')}</Button>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            { VisualizzaCheckSconto ?(
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" name="chkApplicaSconto" onChange={handleApplicaSconto} checked={ApplicaSconto> 0} />{' '}
                                        Applica Sconto
                                    </Label>
                                </FormGroup>
                            ):('')}  
                        </div>
                    </TableCell>
                    <TableCell colSpan={2} style={{background:"#deeaf6",borderLeft:"1px solid rgba(224, 224, 224, 1)"}}><b>Totale &euro;</b></TableCell>
                    <TableCell align="right" style={{background:"#deeaf6"}}><b>{ccyFormat(CostoTotale)}</b></TableCell>
                </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
        </>
    );
  }

export default function NewPreventivo (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const UserRole =GetRole(localStorage.getItem("token"));
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
     //alert object
     const [OpenAlert, setOpenAlert] = React.useState(false);
     const [OpenAlertSelServizio, setOpenAlertSelServizio] = React.useState(false);
     const [VerticalAlert, setVerticalAlert] = React.useState("top");
     const [SeverityAlert, setSeverityAlert] = React.useState("error");
     const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
     const onCloseAlert = (event) => {
         setOpenAlert(false);
     };
     const onCloseAlertSelServizio = (event) => {
        setOpenAlertSelServizio(false);
    };
     //------------
    const [CloseForm, setCloseForm] = React.useState(false);
    const [RagioneSociale, setRagioneSociale] = React.useState("");
    const [IDCommerciale, setIDCommerciale] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [Cellulare, setCellulare] = React.useState("");
    const [Sconto, setSconto] = React.useState(0);
    const [VerificaMt, setVerificaMt] = React.useState(0);
    const [VerificaExp, setVerificaExp] = React.useState(0);
    const [NumKm, setNumKm] = React.useState(0);
    const [CodiceIva, setCodiceIva] = React.useState(process.env.REACT_APP_IVA);
    const [OpenConfirmInvioPreventivo, setOpenConfirmInvioPreventivo] = React.useState(false);
    const [CostoTotale, setCostoTotale] = React.useState(0);
    const [CostoImpianto, setCostoImpianto] = React.useState(0);
    const [CostoImpiantoExp, setCostoImpiantoExp] = React.useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [Ricalcola, setRicalcola] = React.useState(false);
    const [RowsServizi, setRowsServizi] = React.useState([]);
    const [Note, setNote] = React.useState("");
    const [CostoSpesa, setCostoSpesa] = React.useState(0);
    const [IDSpesa, setIDSpesa] = React.useState(0);
    const [OpenSelServizio, setOpenSelServizio] = React.useState(false);
    const [NumCabine, setNumCabine] = React.useState("");
    const [Superficie, setSuperficie] = React.useState("");
    const [Indirizzo, setIndirizzo] = React.useState("");
    const [Comune, setComune] = React.useState("");
    const [NewImpianto, setNewImpianto] = React.useState(false);
    const [CodComune, setCodComune] = React.useState("");
    const [DescrizioneAttivita, setDescrizioneAttivita] = React.useState("");
    const [Tensione, setTensione] = React.useState("");
    const [SistDistribuzione, setSistDistribuzione] = React.useState("");
    const [PotenzaKw, setPotenzaKw] = React.useState("");
    const [AnniValidita, setAnniValidita] = React.useState("");
    const [IDPersVerifica, setIDPersVerifica] = React.useState("");
    const [IDTipoAmbiente, setIDTipoAmbiente] = React.useState("");
    const [selected, setSelected] = React.useState([]);
    const [TipoAmbiente, setTipoAmbiente] = React.useState("");
    const [IDImpianto, setIDImpianto] = React.useState(0);
    const [dataImpianti, setDataImpianti] = useState([]);
    //confirm object
    const [MsgConfirmDelServizio,setMsgConfirmDelServizio]=React.useState("Si desidera eliminare il servizio dalla lista dei selezionati?");
    const [OpenConfirmDelServizio, setOpenConfirmDelServizio] = React.useState(false);
    const [IdConfirmDelServizio, setIdConfirmDelServizio] = React.useState(false);
    const onCloseConfirmDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
    };
    const toggleDelServizio = (_Id) => {
        setIdConfirmDelServizio(_Id);
        setOpenConfirmDelServizio(true);
    };
    const handleNote = (event) => {  
        setNote(event.target.value);  
    };
     
    const handleTipoAmbiente = (value,text) => {
        var arrValue = value.split("|");
        setIDTipoAmbiente(arrValue[0]);
        setAnniValidita(arrValue[1]);
        setIDPersVerifica(arrValue[2]);
        setTipoAmbiente(text);  
    }; 
    const handleChangeImpianto = (event) => {
        setIDImpianto(parseFloat(event.target.value));
        let _item=null;
        if (dataImpianti.length>0){
            dataImpianti.map(function(item) {
                if (parseFloat(item.id) === parseFloat(event.target.value)){
                    _item=item;
                }
            });
        }
        if (VerificaExp>0 || VerificaMt>0){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(_item.PotenzaKw,VerificaMt,VerificaExp);
                setIsLoading(false);
            })(); 
        }
        
        impostaCampiImpianto(_item);
        setNewImpianto(false);
    };
    const impostaCampiImpianto = (item) => {
        setDescrizioneAttivita(item.DescrizioneAttivita);
        setPotenzaKw(item.PotenzaKw);
        setIndirizzo(item.Indirizzo);
        setSistDistribuzione(item.SistemaDistribuzione);
        setCodComune(item.CodComune);
        setComune(item.DesComune);
        setTensione(item.TensioneAlimentazione); 
        setNumCabine(item.NumeroCabine);
        setSuperficie(item.SuperficieLuogo);
        setIDTipoAmbiente(item.TipoAmbiente_id);
        setAnniValidita(item.AnniValidita);
        setIDPersVerifica(item.PersVerifica_id);
        setTipoAmbiente(item.DescAmbiente);  
    }
    const handleDescrizioneAttivita = (event) => {  
        setDescrizioneAttivita(event.target.value);  
    }; 
    const handlePotenzaKw = (event) => { 
         
        setPotenzaKw(event.target.value);  
        if (VerificaExp>0 || VerificaMt>0){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(event.target.value,VerificaMt,VerificaExp);
                setIsLoading(false);
            })(); 
        }
    }; 
    const handleIndirizzo = (event) => {  
        setIndirizzo(event.target.value);  
    }; 
    const handleSistDistribuzione = (event) => {  
        setSistDistribuzione(event.target.value);  
    }; 
    const handleComune = (value,text) => {
        setCodComune(value);
        setComune(text);  
    }; 
    const handleTensione = (event) => {  
        setTensione(event.target.value);  
    }; 
    const handleNumCabine = (event) => {  
        setNumCabine(event.target.value);  
    }; 
    const handleSuperficie = (event) => {  
        setSuperficie(event.target.value);  
    };   
    const ChangeCostoTotale = (_value) => {
        setCostoTotale(_value);
    };
    const handleVerificaMt= (event) =>{
        setVerificaMt(event.target.checked);
        if (event.target.checked){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(PotenzaKw,event.target.checked,VerificaExp);
                setIsLoading(false);
            })(); 
        }
        else {
            setCostoImpianto(0);
        }
    };
    const handleVerificaExp= (event) =>{
        setVerificaExp(event.target.checked);
        if (event.target.checked){
            (async () => { 
                setIsLoading(true);
                await LoadCostoImpianto(PotenzaKw,VerificaMt,event.target.checked);
                setIsLoading(false);
            })(); 
        }
        else {
            setCostoImpiantoExp(0);
        }
    };
    const onDelServizio = (event) => {
        setOpenConfirmDelServizio(false);
        
        let newRows = RowsServizi;
        let index = -1;
        let indexSel = -1;

        if (newRows.length>0){
            newRows.map((item) => {
                index +=1;
                if (item.id===IdConfirmDelServizio){
                    indexSel=index;
                }
                return indexSel;
            });
        }
        
        if (indexSel !== -1) {
            newRows.splice(indexSel, 1);
            setRowsServizi(newRows);
            setRicalcola(!Ricalcola);
        }

    };

    const handleNewImpianto = (event) => {  
        setNewImpianto(event.target.checked);  
        setCostoImpianto(0);
        setCostoImpiantoExp(0);
        if (event.target.checked) svuotaCampiImpianto();
    };
    
    const svuotaCampiImpianto = () => {
        setDescrizioneAttivita("");
        setPotenzaKw("");
        setIndirizzo("");
        setSistDistribuzione("");
        setCodComune("");
        setComune("");
        setTensione(""); 
        setNumCabine("");
        setSuperficie("");
        setIDImpianto(0);
        setIDTipoAmbiente(0);
        setAnniValidita("");
        setIDPersVerifica(0);
        setTipoAmbiente("");  
    }
    const handleOpenSelServizio = (event) => {  
        setOpenSelServizio(true);  
    };

    const CloseSelServizio = (event) => {  
        setOpenSelServizio(false);  
    };

    const onConfirmSelServizio = (RisArray) => {  

        let newArray;


        let id = RisArray[0].id;
        
        let res=false;

        if (RowsServizi.length>0){
            RowsServizi.map(function(item) {
                if (item.id === id){
                    res=true;
                    return true;
                }
                return res;
            });
        }
                

        if (!res){
            if (RowsServizi.length>0){
                newArray= RowsServizi.concat(RisArray);
            }
            else {
                newArray= RisArray;
            }
            //newArray.push(RisArray)
            setRowsServizi(newArray);
            setOpenSelServizio(false);  
        }
        else {
            setMsgAlert("Servizio già selezionato, per aumentare il quantitativo incrementare il campo quantità!");
            setOpenAlertSelServizio(true);

        }
    };
  

    const ChangeSconto = (_value) => {
        setSconto(_value); 
    };
    const ChangeCostoSpesa = (_value,_IDSpesa) => {
        setCostoSpesa(_value);
        setIDSpesa(_IDSpesa); 
    };
    const ChangeIva = (_value) => {
        setCodiceIva(_value); 
    };

    const ChangeNumKm = (_value) => {
        setNumKm(_value); 
    };
    
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    React.useEffect(() => {
        if (localStorage.getItem("idazienda")!==""){
            
            (async () => {
                setIsLoading(true); 
                await LoadDatiAzienda();
                await LoadElencoImpianti();
                setIsLoading(false);  
            })();
            
        }
    }, []);
    async function LoadCostoImpianto(_PotenzaKw,_verificaMt,_verificaExp) {
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        if (parseFloat(_PotenzaKw)>0){
            try{

                let url=process.env.REACT_APP_API_URL+'preventivocertcostobypotenza';
                const fd = new FormData();
                fd.append("PotenzaKw", _PotenzaKw);
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                result = await ax.post(url,fd,axiosConfig);
                if (_verificaMt>0) {
                    setCostoImpianto(result.data[0].CostoMessaTerra);
                }
                else {
                    setCostoImpianto(0);
                }
                if (_verificaExp>0) {
                    setCostoImpiantoExp(result.data[0].CostoRischioEsplosione);
                }
                else {
                    setCostoImpiantoExp(0);
                }
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("warning")
                setMsgAlert(msg);
                setVerticalAlert("center");
                setOpenAlert(true);
    
            }    
        }
        
    }
    async function LoadElencoImpianti() {
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        try{

            let url=process.env.REACT_APP_API_URL+'impiantoellist';
            const fd = new FormData();
            fd.append("Azienda_id", localStorage.getItem("idazienda"));
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            result = await ax.post(url,fd,axiosConfig);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setSeverityAlert("warning")
            setMsgAlert(msg);
            setVerticalAlert("center");
            setOpenAlert(true);

        }    
        if (result.data!==undefined)    {
            setDataImpianti(result.data);
            if (result.data.length===0){
                setNewImpianto(true);
            }
        }
        else {
            setDataImpianti([]);
        }
    }
 
    async function LoadDatiAzienda() {            
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        let result=[];
        try{
            let url=process.env.REACT_APP_API_URL+'customer/' + localStorage.getItem("idazienda");               
            result = await ax.post(url);
            setRagioneSociale(result.data[0].RagioneSociale);
            setEmail(result.data[0].Email);
            setCellulare(result.data[0].CellulareReferente);
            setIDCommerciale(result.data[0].Commerciale_id);
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setSeverityAlert("warning");
            setVerticalAlert("center");
            setMsgAlert(msg);
            setOpenAlert(true);
        }    
    }
    const handleOpenConfirmInvioPreventivo = (event) => {
        let datiOk=false;
        if (NewImpianto) 
        {
            datiOk=validator.current.allValid();
        }
        else 
        {
            datiOk=(IDImpianto>0);
        }

        if ( datiOk && (RowsServizi.length>0 || VerificaExp>0 || VerificaMt>0)) {
            setOpenConfirmInvioPreventivo(true);
        } else {
            if (RowsServizi.length>0 || VerificaExp>0 || VerificaMt>0){
                if (NewImpianto) 
                {
                    validator.current.showMessages();
                    setMsgAlert(MsgAlertDefault);
                }
                else 
                {
                    datiOk=(IDImpianto>0);
                    setMsgAlert("Non è stato selezionato nessun impianto.");
                }
                
                setSeverityAlert("error")
                setVerticalAlert("top")
                
                setOpenAlert(true);
            }
            else
            {
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert("Selezionare almeno un servizio!");
                setOpenAlert(true);
            }
        }
    };
    const onConfirmInvioPreventivo = (event) => {
        setOpenConfirmInvioPreventivo(false);
        onSubmitForm(true);
    };
    const onCloseConfirmInvioPreventivo = (event) => {
        setOpenConfirmInvioPreventivo(false);
        onSubmitForm(false);
    };
    const onCloseForm = (event) => {
        setCloseForm(true);
    };

    const onSubmitForm= (_invioPreventivo) => 
    {
        
        setisSubmit(true);
        let ParamServizi="";
        ParamServizi =RowsServizi.map(function(item) {
            return item.id + "|" + item.Quantita;
        });
        
        const fd = new FormData();
        fd.append("Azienda_id", localStorage.getItem("idazienda"));
        fd.append("Commerciale_id", IDCommerciale); 
        fd.append("Spesa_id", IDSpesa); 
        fd.append("CostoSpesa", CostoSpesa); 
        fd.append("Sconto", Sconto);
        fd.append("CodiceIva", CodiceIva);
        fd.append("Approvato", 0);
        fd.append("Indirizzo", Indirizzo);
        if (CodComune!=null){
            fd.append("CodComune", CodComune);    
        }
        else {
            fd.append("CodComune", 0);
        }
        fd.append("DesComune", Comune);
        fd.append("Superficie", Superficie);
        fd.append("SistemaDistribuzione", SistDistribuzione);
        fd.append("TensioneAlimentazione", Tensione);
        fd.append("NumeroCabine", NumCabine);
        fd.append("CostoPerKm", process.env.REACT_APP_COSTOKM);
        fd.append("DescrizioneAttivita", DescrizioneAttivita);
        fd.append("SuperficieLuogo", Superficie);
        fd.append("NumKm", NumKm);
        fd.append("CostoImpianto", CostoImpianto);
        fd.append("CostoImpiantoExp", CostoImpiantoExp);
        fd.append("Impianto_id", IDImpianto);
        fd.append("PotenzaKw", PotenzaKw);
        if (VerificaExp){
            fd.append("VerificaExp", 1);
        } 
        else {
            fd.append("VerificaExp", 0);
        }
        if (VerificaMt){
            fd.append("VerificaMt", 1);
        }
        else {
            fd.append("VerificaMt", 0);
        }

        if (_invioPreventivo){
            fd.append("InvioPreventivo",1)
        }
        else {
            fd.append("InvioPreventivo",0)
        }
        fd.append("TipoAmbiente_id", IDTipoAmbiente);
        fd.append("PersVerifica_id", IDPersVerifica);
        fd.append("Annullato", 0);
        fd.append("Note", Note);
        fd.append("ParamServizi", ParamServizi);
        const token =  GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            
        ax
        .post(process.env.REACT_APP_API_URL+'preventivocertinsert', fd,axiosConfig)
        .then((response) => {
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    setCloseForm(true);
                }
                else {
                    setisSubmit(false);
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante l'inserimento! " + ris;
                    setSeverityAlert("error")
                    setVerticalAlert("top")
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
            setisSubmit(false);    
            const msg=GetMessage(response.status,response.data.message);
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(msg);
            setOpenAlert(true);
            
            }
        })
        .catch((error) => {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(msg);
            setOpenAlert(true);
            setisSubmit(false);
        });
    }
    
    let urlRedirect="";
    
    if (CloseForm){
        urlRedirect="/preventivi";
    }
    
    if (urlRedirect!==""){
        return <Redirect to={urlRedirect} />;
    }
    const isSelected = (name) => selected.indexOf(name) !== -1;
    return (
        <div>
            <AppBar className={classes.appBar}>
                <Toolbar className="bg-head-form-cert">
                    <IconButton edge="start" color="inherit" onClick={onCloseForm} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Nuovo Preventivo - Servizi
                    </Typography>
                    <MatButton autoFocus color="inherit" onClick={handleOpenConfirmInvioPreventivo} disabled={isSubmit} >
                        Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                    </MatButton>
                </Toolbar>
            </AppBar>
            <div className="page-container bg-form">
                <div className="content-wrap">
                    <form autoComplete="off">
                        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
                        <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>
                        <MyConfirmInvioPreventivo title="Conferma invio preventivo" message="Si desidera inviare al cliente il preventivo? Premere il tasto annulla per salvare il preventivo e inviarlo in seguito." handleClose={onCloseConfirmInvioPreventivo} handleConfirm={onConfirmInvioPreventivo} open={OpenConfirmInvioPreventivo} textOk="Si" textAnnulla="No"/>
                        <ElencaServizio open={OpenSelServizio} handleClose={CloseSelServizio} handleConfirm={onConfirmSelServizio}/>
                        <Grid container spacing={3} className="custom-form">
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtRagioneSociale">Ragione Sociale</Label>
                                                <Input type="text" name="txtRagioneSociale" autoComplete="new-password" placeholder=""
                                                value={RagioneSociale} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>    
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                                <Label for="txtEmail">Email</Label>
                                                <Input type="text" name="txtEmail" autoComplete="new-password" placeholder=""
                                                value={Email} readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <FormGroup>
                                            <Label for="txtEmail">Cellulare</Label>
                                                <MyPhoneInput 
                                                    type="text" 
                                                    name="txtCellulare" 
                                                    autoComplete="new-password" 
                                                    placeholder=""
                                                    value={Cellulare} 
                                                    readOnly disabled
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Col md={12}>
                                <FormControlLabel
                                    control={<Switch checked={NewImpianto} 
                                    onChange={handleNewImpianto} />}
                                    label="Nuovo Impianto"
                                />
                            </Col>
                            { (dataImpianti.length>0 && !NewImpianto) && (
                                <>
                                    <Grid item xs={12}>
                                        <Paper>
                                            <RadioGroup aria-label="impianti" name="customized-radios" value={IDImpianto} onChange={handleChangeImpianto}>
                                                <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                                                    <TableBody>
                                                        {
                                                            dataImpianti.map((row) => {
                                                                
                                                                return (
                                                                    
                                                                <TableRow key={row.id} hover tabIndex={-1}>
                                                                    <TableCell style={{width:"5%"}}>
                                                                        <FormControlLabel value={row.id} control={<Radio />} label="" />
                                                                    </TableCell>
                                                                    <TableCell style={{width:"25%"}} >{row.DesComune}</TableCell>
                                                                    <TableCell style={{width:"25%"}}>{row.Indirizzo}</TableCell>
                                                                    <TableCell style={{width:"10%"}}>{row.DescAmbiente}</TableCell>
                                                                    <TableCell style={{width:"10%"}}>{row.PotenzaKw + ' KW'}</TableCell>
                                                                    <TableCell style={{width:"25%"}}>{row.DescrizioneAttivita}</TableCell>
                                                                </TableRow>
                                                                );
                                                            })
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </RadioGroup>
                                        </Paper>
                                    </Grid>
                                </>
                                )
                            }
                            { NewImpianto && (
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtDescrizioneAttivita">Tipo Attività</Label>
                                                <Input type="text" name="txtDescrizioneAttivita" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('DescrizioneAttivita')}
                                                value={DescrizioneAttivita} onChange={handleDescrizioneAttivita}
                                                />
                                                {validator.current.message('DescrizioneAttivita', DescrizioneAttivita, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="txtTipoAmbiente">Tipo Ambiente *</Label>
                                                <MyAutoComplete id="txtTipoAmbiente" name="txtTipoAmbiente" 
                                                url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                                                optionname="Descrizione" optionvalue="Codice"
                                                onBlur={() => validator.current.showMessageFor('TipoAmbiente')}
                                                customvalue={TipoAmbiente} onChange={handleTipoAmbiente}
                                                />
                                                {validator.current.message('TipoAmbiente', TipoAmbiente, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPersValidita">Periodicità *</Label>
                                                <Input type="text" name="txtPersValidita" 
                                                disabled={true}
                                                autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                value={AnniValidita}
                                                />
                                                {/* <MyAutoComplete id="txtPersValidita" name="txtPersValidita" 
                                                url={process.env.REACT_APP_API_URL+'persverificheimplist'} 
                                                optionname="AnniValidita" optionvalue="id"
                                                onBlur={() => validator.current.showMessageFor('AnniValidita')}
                                                customvalue={AnniValidita} onChange={handleAnniValidita}
                                                /> */}
                                                {validator.current.message('AnniValidita', AnniValidita, 'required|string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtPotenzaKw">Potenza Kw *</Label>
                                                <Input type="text" name="txtPotenzaKw" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('PotenzaKw')}
                                                value={PotenzaKw} onChange={handlePotenzaKw}
                                                />
                                                {validator.current.message('PotenzaKw', PotenzaKw, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="selSistDistribuzione">Categoria *</Label>
                                                <Input type="select" name="selSistDistribuzione" id="selSistDistribuzione" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('SistDistribuzione')}
                                                value={SistDistribuzione} onChange={handleSistDistribuzione}
                                                >
                                                    <option key=""></option>
                                                    <option key="0" value="0">TT</option>
                                                    <option key="1" value="1">TN</option>
                                                </Input>
                                                {validator.current.message('SistDistribuzione', SistDistribuzione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtTensione">Tensione V *</Label>
                                                <Input type="text" name="txtTensione" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Tensione')}
                                                value={Tensione} onChange={handleTensione}
                                                />
                                                {validator.current.message('Tensione', Tensione, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={2}>
                                            <FormGroup>
                                                <Label for="txtNumCabine">N. Cabine *</Label>
                                                <Input type="text" name="txtNumCabine" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('NumCabine')}
                                                value={NumCabine} onChange={handleNumCabine}
                                                />
                                                {validator.current.message('NumCabine', NumCabine, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="txtSuperficie">Superficie *</Label>
                                                <Input type="text" name="txtSuperficie" autoComplete="new-password" placeholder="" 
                                                onBlur={() => validator.current.showMessageFor('Superficie')}
                                                value={Superficie} onChange={handleSuperficie}
                                                />
                                                {validator.current.message('Superficie', Superficie, 'required|numeric', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtIndirizzo">Indirizzo *</Label>
                                                <Input type="text" name="txtIndirizzo" autoComplete="new-password" placeholder=""
                                                onBlur={() => validator.current.showMessageFor('Indirizzo')}
                                                value={Indirizzo} onChange={handleIndirizzo}
                                                />
                                                {validator.current.message('Indirizzo', Indirizzo, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="txtComune">Comune *</Label>
                                                <MyAutoComplete id="txtComune" name="txtComune" url={process.env.REACT_APP_API_URL+'comuni'} optionname="Comune" optionvalue="CodComune"
                                                onBlur={() => validator.current.showMessageFor('Comune')}
                                                customvalue={Comune} onChange={handleComune}
                                                />
                                                {validator.current.message('Comune', Comune, 'string|required', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row> 
                                </Paper>
                            </Grid>)
                            }
                            <Grid item xs={12}>
                                <Toolbar>
                                    <Button className="btn-mbg-cert" onClick={handleOpenSelServizio}>Aggiungi Servizio</Button>
                                </Toolbar> 
                                <MyAlertSelServizio message={MsgAlert} severity="warning" handleClose={onCloseAlertSelServizio} vertical="top" horizontal="center" open={OpenAlertSelServizio}/>
                                <MyConfirmDelServizio title="Conferma Cancellazione" message={MsgConfirmDelServizio} handleClose={onCloseConfirmDelServizio} handleConfirm={onDelServizio} open={OpenConfirmDelServizio}/>          
                                <CreateTableServizi 
                                    rows={RowsServizi}
                                    CostoImpianto={CostoImpianto} 
                                    CostoImpiantoExp={CostoImpiantoExp} 
                                    onChangeCostoTotale={ChangeCostoTotale} 
                                    onDelete={toggleDelServizio} 
                                    onRicalcola={Ricalcola} 
                                    onChangeSconto={ChangeSconto}
                                    onChangeCostoSpesa={ChangeCostoSpesa} 
                                    onChangeIva={ChangeIva}
                                    onChangeNumKm={ChangeNumKm}                                    
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <FormGroup style={{textAlign:"left"}}>
                                                <div>&nbsp;</div>
                                                <Label>
                                                    <Input type="checkbox" name="chkVerificaMt" onChange={handleVerificaMt} checked={VerificaMt> 0} />{' '}
                                                    Verifica Messa a terra
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={1}></Col>
                                        <Col md={11}>
                                            <FormGroup style={{textAlign:"left"}}>
                                                <Label>
                                                    <Input type="checkbox" name="chkVerificaExp" onChange={handleVerificaExp} checked={VerificaExp> 0} />{' '}
                                                    Verifica Rischio Esplosione
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper className={classes.paper}>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <Label for="txtNote">Note [max 200 caratteri]</Label>
                                                <Input type="textarea" name="txtNote" id="txtNote" autoComplete="new-password"
                                                onBlur={() => validator.current.showMessageFor('Note')}
                                                value={Note} onChange={handleNote} maxLength="200"
                                                />
                                                {validator.current.message('Note', Note, 'string', { className: 'text-danger' })}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Paper>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </div>
        </div>
    );
}
        