
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import {GetMessage} from "../Modules/Message";
import {GetToken} from "../Modules/Funzioni";
import MyAlert from './MyAlert';
import axios from 'axios';
//function sleep(delay = 0) {
//  return new Promise((resolve) => {
//    setTimeout(resolve, delay);
//  });
//}

export default function MyAutoComplete(props) {
  const [SelectedText, setSelectedText] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [IsLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [filter, setFilter] = React.useState("");

  const [forceLoading, setForceLoading] = React.useState(1);
  const loading = open && forceLoading;
  // obj alert
  const [MsgAlert,setMsgAlert]=React.useState("");
  const [OpenAlert, setOpenAlert] = React.useState(false);
  const onCloseAlert = (event) => {
    setOpenAlert(false);
  };
  //---------
  const handleFilter = (value) => {
    setFilter(value);
    //if (options.length==0 && open){
      
      setForceLoading(forceLoading+1);
    //}
  };  
 
  React.useEffect(() => {
    let active = true;
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;

    if (!loading || props.url==="") {
      
      return undefined;
    }
    (async () => {
        let results=[];
        try{
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }
            
          const fd = new FormData();
          fd.append("Nome", filter);
          if (props.idparent!==undefined && props.idparent!==null)
          {
            fd.append("Parent_id", props.idparent);
          }
         
          const response = await ax.post(props.url,fd,axiosConfig);
            
          if (response.status===200){
              results  = response.data;
          }
          else
          {
            const msg=GetMessage(response.status,response.data.message);
            setMsgAlert(msg);
            setOpenAlert(true);
            setSelectedText("");
            props.onChange("","");
          }
        }
        catch(error) {
          let msg=""
          if (error.response!==undefined) {
              msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
          }
          else
          {
              msg=GetMessage(undefined,"");
          }
          setMsgAlert(msg);
          setOpenAlert(true);
          setSelectedText("");
          props.onChange("","");
          
        }
        
        //await sleep(1e3); // For demo purposes.
        setIsLoading(false);
        if (active) {
            setOptions(results);

        }
    })();

    return () => {
      active = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  React.useEffect(() => {
      if (props.customvalue!==null){
        setSelectedText(props.customvalue);
        setFilter(props.customvalue)
      }
       
  }, [props.customvalue]);

  return (
    <div>
    <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>     
    <Autocomplete 
      autoComplete={true}
      placeholder=""
      id={props.id}
      name={props.name}
      open={open}
      disabled={props.disabled}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) =>{
        //if (option[props.optionvalue] === value[props.optionvalue])
        //{
        //  props.onChange(option[props.optionvalue],option[props.optionname]);
        //}
        if (props.optionvalue!=="" && props.optionvalue!==null){
          return option[props.optionvalue] === value[props.optionvalue];
        }
        else {
          return false;
        }
          
        }}
      getOptionLabel={(option) => {
        if (props.optionname!=="" && props.optionname!==null){
          return option[props.optionname]
        }
        else{
          return "";
        }
          
      }}
     
      options={options}
      loading={loading}
      multiple={false}
      onChange={(ev,option) => {
        if (option==null || props.optionvalue==="") {  
          props.onChange("","");
        }
        else {
          props.onChange(option[props.optionvalue],option[props.optionname]);
        }
        
      }}
      inputValue={SelectedText}
      onInputChange={(_,value,reason) => {
        
        // dont fire API if the user delete or not entered anything
        if (value.length>2 || value.length===0) {
            handleFilter(value);
        }  
        setSelectedText(value);        
      }}
      
      autoSelect={true}
      className={props.className}
      renderInput={(params) => (
        <TextField
          {...params}
          label=""
          autoComplete="new-password" 
          placeholder={props.placeholder}
          onChange={
            (event) => {
        
              // dont fire API if the user delete or not entered anything
              if (event.target.value.length===0) {
                props.onChange("","");
              }  
              
            }
          }
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {IsLoading ? <CircularProgress color="inherit" size={15} padding-left="10px"/> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
    </div>
  );
}