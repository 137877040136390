import React from 'react';  
import {Button,Col,Row,Input,FormGroup,Label} from "reactstrap";
import { makeStyles } from '@material-ui/core/styles';  
import Paper from '@material-ui/core/Paper';  
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TablePagination from '@material-ui/core/TablePagination';  
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from '@material-ui/core/FormControlLabel';  
import Switch from '@material-ui/core/Switch';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
//import { green, pink } from '@material-ui/core/colors';
import axios from 'axios';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Grid.css'; 
import { useState, useEffect } from 'react'   
import { Toolbar } from '@material-ui/core';
import MyAutoComplete from '../MyAutoComplete';
import {GetMessage} from "../../Modules/Message";
import {isDate,DateDiff, GetToken,GetRole, get_years, GetIDIspettore, GetItemStatoVerificaCert, GetTextStatoVerificaCert, GetItemTipoVerificaEstesa} from "../../Modules/Funzioni";
import MyAlert from '../MyAlert';
import imgSearch from "../../img/search.png";
import Avatar from '@material-ui/core/Avatar';
import MyDatePicker from '../MyDatePicker';
import { Redirect } from "react-router-dom";
import {Ruolo_Admin,  Ruolo_Ispettore, Ruolo_CommTecnico } from '../../Modules/Costanti';
import FilterListIcon from '@material-ui/icons/FilterList';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ModAppuntamentoVerifica from './ModAppuntamentoVerifica';
import RimandaAppuntamentoVerifica from './RimandaAppuntamentoVerifica';
import ReactExport from "react-export-excel";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '98%',
    },
    paper: {
        width: '98%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    /*pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },*/
    BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(41, 110, 156, 1) !important",
        '&:hover':{
            cursor:"pointer"
      },    
    },
    BtnEditRed: {
        color: '#f20f0f',
        backgroundColor:"rgba(41, 110, 156, 1) !important",
        '&:hover':{
            cursor:"pointer"
      },    
    }
}));

OperationDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelected: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  };
  
  function OperationDialog(props) {
  const { classes, onClose, onSelected,  open, fileNameVerbale, fileNameRapporto, isAdmin , DataAppuntamento, Rimandato } = props;
    
    const handleClose = () => {
        onClose();
    };
    const handleListItemClick = (value) => {
        onSelected(value);
    };
  
    return (
      <Dialog onClose={handleClose} aria-labelledby="operation-dialog-title" open={open}>
        <DialogTitle id="operation-dialog-title">Seleziona il tipo di operazione</DialogTitle>
        <List>
            {
                (isAdmin && (fileNameRapporto===null || fileNameRapporto==="")) && (
                <ListItem button onClick={() => handleListItemClick("Appuntamento")}>
                    <ListItemAvatar>
                        <Avatar className={classes.BtnEdit}>
                            <AccessAlarmIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>Fissa Appuntamento</ListItemText>
                </ListItem>
                )
            }
            {
                (Rimandato===0 && DataAppuntamento!=="" && DataAppuntamento!==null && (fileNameRapporto===null || fileNameRapporto==="")) && (
                <ListItem button onClick={() => handleListItemClick("RimandaAppuntamento")}>
                    <ListItemAvatar>
                        <Avatar className={classes.BtnEditRed}>
                            <AlarmOffIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText>Rimanda Appuntamento</ListItemText>
                </ListItem>
                )
            }
            <ListItem button onClick={() => handleListItemClick("Apri")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <FilterListIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Apri Verifica</ListItemText>
            </ListItem>
            {
            fileNameVerbale!=="" && fileNameVerbale!==null  &&
            (<ListItem button onClick={() => handleListItemClick("ScaricaVerbale")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica PDF Verbale</ListItemText>
            </ListItem>)
            }
            {
            fileNameRapporto!=="" && fileNameRapporto!==null  &&
            (<ListItem button onClick={() => handleListItemClick("ScaricaRapporto")}>
              <ListItemAvatar>
                <Avatar className={classes.BtnEdit}>
                    <DownloadIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Scarica PDF Rapporto</ListItemText>
            </ListItem>)
            }
            
        </List>
      </Dialog>
    );
  }

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'Ordine', string: false, disablePadding: true, label: 'Ordine' },
    { id: 'NumRapporto', string: false, disablePadding: true, label: 'N.Verifica' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Azienda' },
    { id: 'DescImpianto', string: false, disablePadding: true, label: 'Impianto' },
    { id: 'DataVerificaCast', string: false, disablePadding: true, label: 'D. Verifica' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
];

const headCellsAdmin = [
    { id: 'Oper', string: false, disablePadding: true, label: '' },
    { id: 'Ordine', string: false, disablePadding: true, label: 'Ordine' },
    { id: 'NumRapporto', string: false, disablePadding: true, label: 'N.Verifica' },
    { id: 'RagioneSociale', string: false, disablePadding: true, label: 'Azienda' },
    { id: 'DescImpianto', string: false, disablePadding: true, label: 'Impianto' },
    { id: 'Ispettore', string: false, disablePadding: true, label: 'Ispettore' },
    { id: 'DataVerificaCast', string: false, disablePadding: true, label: 'D. Verifica' },
    { id: 'Stato', string: false, disablePadding: true, label: 'Stato' },
];

function GetHeadCells(_userRole){
    if (parseInt(_userRole)===Ruolo_Admin){
        return headCellsAdmin;
    }
    else {
        return headCells;
    }
}
function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {(() => {
                let _UserRole =GetRole(localStorage.getItem("token"));
                let _headCells=GetHeadCells(_UserRole);
               
                return(_headCells.map((headCell) => (
                    <TableCell className="grid-head"
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                )));
                })()}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};
function ExportToExcel (props){
    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    return (
        //<ExcelFile element={<img src={ExcelIcon} style={{maxHeight:'20px'}} alt="Esporta in Excel" style={{cursor:'pointer'}}/>}>
        <ExcelFile element={<Button className="btn-mbg-cert">Esporta</Button>}>
            <ExcelSheet data={props.data} name="Verifiche">
                <ExcelColumn label="N.Ordine" value={(col) => col.NumOrdine + " - " + get_years(col.DataOrdine)}/>
                <ExcelColumn label="N.Rapporto" value="NumRapporto"/>
                <ExcelColumn label="Azienda" value="RagioneSociale"/>
                <ExcelColumn label="Impianto" value="DescImpianto"/>
                <ExcelColumn label="Ispettore" value="DesIspettore"/>
                <ExcelColumn label="D.Verifica" value="DataVerifica"/>
                <ExcelColumn label="Stato" value={(col) =>GetTextStatoVerificaCert(
                                       col.Approvato,
                                       col.DataVerifica,
                                       col.DataAppuntamento,
                                       col.Rimandato,
                                       col.Annullato)}/>
            </ExcelSheet>
        </ExcelFile>
    );
}
export default function MatPaginationTable() {  
  const classes = useStyles();  
  const [order, setOrder] = React.useState('desc');
  let tokenRicerca=localStorage.getItem("tokenRicercaVerifiche");
  let tmpRicStato="";
  let tmpRicTipoVerifica="";
  let tmpRicIDAzienda="";
  let tmpRicAzienda="";
  let tmpRicIDImpianto="";
  let tmpRicImpianto="";
  let tmpRicIDIspettore="";
  let tmpRicIspettore="";
  let tmpRicDataVerificaInizio="";
  let tmpRicDataVerificaFine="";
  let tmpRicNumRecord="100";
  let tmpPage=0;
  let tmpRicTipoLuogo="0";
  let tmpRicLuogo="";
  let tmpRicTipoAmbiente="";
  let tmpRicIDTipoAmbiente="";
  let tmpRicUrlLuogo=process.env.REACT_APP_API_URL+"province";
  let tmpRicOptionName="Provincia";
  let tmpRicOptionValue="CodProv";
    if (tokenRicerca!=="" && tokenRicerca!==null){
        let mtxTokenRicerca=tokenRicerca.split("|");
        tmpRicStato=mtxTokenRicerca[0];
        tmpRicIDAzienda=mtxTokenRicerca[1];
        tmpRicAzienda=mtxTokenRicerca[2];
        tmpRicIDImpianto=mtxTokenRicerca[3];
        tmpRicImpianto=mtxTokenRicerca[4];
        tmpRicIDIspettore=mtxTokenRicerca[5];
        tmpRicIspettore=mtxTokenRicerca[6];
        tmpRicDataVerificaInizio=mtxTokenRicerca[7];
        tmpRicDataVerificaFine=mtxTokenRicerca[8];
        tmpPage=parseInt(mtxTokenRicerca[9]);
        tmpRicNumRecord=mtxTokenRicerca[10];
        tmpRicTipoLuogo=mtxTokenRicerca[11];
        tmpRicLuogo=mtxTokenRicerca[12];
        tmpRicUrlLuogo=mtxTokenRicerca[13];
        tmpRicOptionName=mtxTokenRicerca[14];
        tmpRicOptionValue=mtxTokenRicerca[15];
        tmpRicTipoVerifica=mtxTokenRicerca[16];
        tmpRicTipoAmbiente=mtxTokenRicerca[17];
        tmpRicIDTipoAmbiente=mtxTokenRicerca[18];
    }
    const [RicTextLuogo, setRicTextLuogo] = React.useState(tmpRicLuogo);
    const [RicTipoLuogo, setRicTipoLuogo] = React.useState(tmpRicTipoLuogo);
    const [RicLuogo, setRicLuogo] = React.useState(tmpRicLuogo);
    const [RicOptionValue, setRicOptionValue] = useState(tmpRicOptionValue);
    const [RicOptionName, setRicOptionName] = useState(tmpRicOptionName);
    const [RicUrlLuogo, setRicUrlLuogo] = useState(tmpRicUrlLuogo);
    const [NewVerifica, setNewVerifica] = React.useState(false);
    const [RicAzienda, setRicAzienda] = React.useState(tmpRicAzienda);
    const [RicIDAzienda, setRicIDAzienda] = React.useState(tmpRicIDAzienda);
    const [RicImpianto, setRicImpianto] = React.useState(tmpRicImpianto);
    const [RicIDImpianto, setRicIDImpianto] = React.useState(tmpRicIDImpianto);
    const [RicIspettore, setRicIspettore] = React.useState(tmpRicIspettore);
    const [RicIDIspettore, setRicIDIspettore] = React.useState(tmpRicIDIspettore);
    const [RicIDTipoAmbiente, setRicIDTipoAmbiente] = React.useState(tmpRicIDTipoAmbiente);
    const [RicTipoAmbiente, setRicTipoAmbiente] = React.useState(tmpRicTipoAmbiente);
    const [RicNumRecord, setRicNumRecord] = React.useState(tmpRicNumRecord);
    const [RicStato, setRicStato] = React.useState(tmpRicStato);
    const [RicTipoVerifica, setRicTipoVerifica] = React.useState(tmpRicTipoVerifica);
    const [RicDataVerificaInizio, setRicDataVerificaInizio] = React.useState(tmpRicDataVerificaInizio);
    const [RicDataVerificaFine, setRicDataVerificaFine] = React.useState(tmpRicDataVerificaFine);
    const [orderBy, setOrderBy] = React.useState('id');
    const [page, setPage] = React.useState(tmpPage);  
    const [data, setData] = useState([]); 
    const [isLoading, setIsLoading] = useState(true);
    const [DisableFilter, setDisableFilter] = useState(true);
    const [isSending, setIsSending] = useState(false);   
    const [rowsPerPage, setRowsPerPage] = React.useState(20);  
    const [dense, setDense] = React.useState(false);
    const [ModVerifica, setModVerifica] = useState(false);
    const [SelIdVerifica, setSelIdVerifica] = useState("");
    const [SelTipoVerifica, setSelTipoVerifica] = useState("");
    const [SelIdAzienda, setSelIdAzienda] = useState("");
    const [SelIdImpianto, setSelIdImpianto] = useState("");
    const [SelNumRapporto, setSelNumRapporto] = useState("");
    const [SelDataAppuntamento, setSelDataAppuntamento] = useState("");
    const [SelRimandato, setSelRimandato] = useState("");
    const [SelVerbaleFileName,setSelVerbaleFileName]=useState("");
    const [SelRapportoFileName,setSelRapportoFileName]=useState("");
    const [IsOpenModAppuntamento,setIsOpenModAppuntamento] = useState(false);
    const [IsOpenRimandaAppuntamento,setIsOpenRimandaAppuntamento] = useState(false);
    const [OpenOperDialog,setOpenOperDialog]=useState(false);
    
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState("");
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };

const UserRole =GetRole(localStorage.getItem("token"));
let isAdmin=false;
if (parseFloat(UserRole)===Ruolo_Admin) isAdmin=true;
  
  const toggleOperDialog = (_IdVerifica,_TipoVerifica,_IdAzienda,_IdImpianto,_FileNameVerbale,_fileNameRapporto,_NumRapporto,_DataRapporto,_DataAppuntamento,_Rimandato) => {
    resetStorage();
    setSelIdVerifica(_IdVerifica);
    setSelTipoVerifica(_TipoVerifica);
    setSelIdAzienda(_IdAzienda);
    setSelIdImpianto(_IdImpianto);
    setSelVerbaleFileName(_FileNameVerbale);
    setSelRapportoFileName(_fileNameRapporto);
    setSelNumRapporto(_NumRapporto);
    setSelDataAppuntamento(_DataAppuntamento);
    setSelRimandato(_Rimandato);
    setOpenOperDialog(true);
  }
  
  
  const resetStorage = () =>{
    localStorage.setItem("idverifica", "");
    localStorage.setItem("tipoverifica", "");
    localStorage.setItem("tokenRicercaVerifiche", RicStato + "|" + RicIDAzienda + "|" + 
    RicAzienda + "|" + RicIDImpianto + "|" + RicImpianto + "|" + RicIDIspettore + "|" + 
    RicIspettore + "|" + RicDataVerificaInizio + "|" + 
    RicDataVerificaFine + "|" + page + "|" + RicNumRecord + "|" + RicTipoLuogo + "|" + RicLuogo + "|" +
    RicUrlLuogo + "|" + RicOptionName  + "|" + RicOptionValue+ "|" + RicTipoVerifica + "|" + RicTipoAmbiente + "|" + RicIDTipoAmbiente);
  }

  
  const DownloadDocumentoVerbale = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelVerbaleFileName);
            fd.append("id", SelIdVerifica);
            let url=process.env.REACT_APP_API_URL+'docverbaleverifica'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'verbale_' + SelNumRapporto + '.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }

  const DownloadDocumentoRapporto = () => {
    setIsLoading(true);
    const token = GetToken(localStorage.getItem("token"));
    const ax= axios;
    ax.defaults.headers.common['Authorization']="Bearer " + token;
    
    
    (async () => {
        try{
            const axiosConfig = {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            }
            const fd = new FormData();
            fd.append("File_Name", SelRapportoFileName);
            fd.append("id", SelIdVerifica);
            let url=process.env.REACT_APP_API_URL+'docrapportoverifica'
            await ax.post(url,fd,axiosConfig)
                .then((response) => {
                //Create a Blob from the PDF Stream
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                const downloadUrl = window.URL.createObjectURL(new Blob([file]));
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'rapporto_' + SelNumRapporto + '.pdf'); 
                document.body.appendChild(link);
                link.click();
                link.remove();
              });
        }
        catch(error) {
            let msg=""
            if (error.response!==undefined) {
                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
            }
            else
            {
                msg=GetMessage(undefined,"");
            }
            setMsgAlert(msg);
            setOpenAlert(true);

        }
        setIsLoading(false);    
    })(); 
  }


  useEffect(() => {    
        setIsLoading(true);
        const token = GetToken(localStorage.getItem("token"));
        const IDIspettore = GetIDIspettore(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        
        (async () => {
            let result=[];
            try{
                let url=process.env.REACT_APP_API_URL+'verifichecertlist'
                const axiosConfig = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                const fd = new FormData();
                if (parseFloat(IDIspettore)>0 && (UserRole===Ruolo_Ispettore || UserRole===Ruolo_CommTecnico)){
                    fd.append("Ispettore_id", IDIspettore);    
                }
                fd.append("RicIspettore_id", RicIDIspettore);
                fd.append("IDAzienda", RicIDAzienda);
                fd.append("IDImpianto", RicIDImpianto);
                fd.append("DataVerificaInizio", RicDataVerificaInizio);
                fd.append("DataVerificaFine", RicDataVerificaFine);
                fd.append("Stato", RicStato);
                fd.append("CodVerifica", RicTipoVerifica);
                fd.append("LastNumberData", RicNumRecord);
                fd.append("RicLuogo", RicLuogo);
                fd.append("IDTipoAmbiente", RicIDTipoAmbiente);
                fd.append("RicTipoLuogo", RicTipoLuogo);
                //idazienda/0/codfiscale/0/cognome/0
                result = await ax.post(url,fd,axiosConfig);
                localStorage.setItem("tokenRicercaVerifiche","");
            }
            catch(error) {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setMsgAlert(msg);
                setOpenAlert(true);

            }
            setIsLoading(false);    
            if (result.data!==undefined)    {
                setData(result.data);
            }
            else {
                setData([]);
            }
        })(); 
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [isSending]);   


  const handleChangePage = (event, newPage) => {  
    setPage(newPage);  
  };  
 
  const handleChangeRowsPerPage = event => {  
    setRowsPerPage(+event.target.value);  
    setPage(0);  
  };  
  
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
};

const onLoadData = () => {
    setIsSending(!isSending);
}; 

const onFilter = () => {
    setDisableFilter(true);
    setIsSending(!isSending);
    setPage(0);
}; 


const handleRicAzienda = (value,text) => {
    setRicAzienda(text);
    setRicIDAzienda(value);;
    setDisableFilter(false);
};

const handleRicImpianto = (value,text) => {
    setRicImpianto(text);
    setRicIDImpianto(value);;
    setDisableFilter(false);
};

const handleRicIspettore = (value,text) => {
    setRicIspettore(text);
    setRicIDIspettore(value);;
    setDisableFilter(false);
};
const handleRicTipoAmbiente = (value,text) => {
    var arrValue = value.split("|");
    setRicIDTipoAmbiente(parseInt(arrValue[0]));
    setRicTipoAmbiente(text);  
    setDisableFilter(false);
}; 
const handleRicDataVerificaInizio = (value) => {  
    setRicDataVerificaInizio(value); 
    if (isDate(RicDataVerificaFine)===""){
        if (DateDiff(value,RicDataVerificaFine)<0){
            setRicDataVerificaFine("");
        }
    }
    setDisableFilter(false);
};

const handleRicDataVerificaFine = (value) => {  
    setRicDataVerificaFine(value);  
    if (isDate(RicDataVerificaInizio)===""){
        if (DateDiff(RicDataVerificaInizio,value)<0){
            setRicDataVerificaInizio("");
        }
    }
    setDisableFilter(false);
};

const handleRicStato = (event) => {
    setRicStato(event.target.value);
    setDisableFilter(false);
};

const handleRicTipoVerifica = (event) => {
    setRicTipoVerifica(event.target.value);
    setDisableFilter(false);
};

const handleRicNumRecord = (event) => {
    setRicNumRecord(event.target.value);
    setDisableFilter(false);
};

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
};
const handleRicLuogo = (value,text) => {
    setRicTextLuogo(text);
    setRicLuogo(value);
    setDisableFilter(false);
};

const handleRicTipoLuogo = (event) => { 
    setRicTipoLuogo(event.target.value);
    
    setRicLuogo("");
    setRicTextLuogo("");
    if (event.target.value==="0"){
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"province");
        setRicOptionValue("CodProv");
        setRicOptionName("Provincia");
    }
    else
    {
        setRicUrlLuogo(process.env.REACT_APP_API_URL+"regioni");
        setRicOptionValue("CodRegione");
        setRicOptionName("NomeRegione");
    }
    
    setDisableFilter(false);
};
const onSelectedItemOperDialog = (_keyValue) => {
    setOpenOperDialog(false);

    switch (_keyValue){
        case "Apri":
            {
                setModVerifica(true);
                break;
            }
        case "Appuntamento":
            {
                setIsOpenModAppuntamento(true);
                break;
            }
        case "RimandaAppuntamento":
            {
                setIsOpenRimandaAppuntamento(true);
                break;
            }
        case "ScaricaVerbale":
            {
                DownloadDocumentoVerbale();
                break;
            }
        case "ScaricaRapporto":
            {
                DownloadDocumentoRapporto();
                break;
            }
        default:
    }
};
const onCloseOperDialog = () => {
    setOpenOperDialog(false);
};
const toggleModAppuntamento = () => {
    setIsOpenModAppuntamento(!IsOpenModAppuntamento);
  }
const toggleRimandaAppuntamento = () => {
    setIsOpenRimandaAppuntamento(!IsOpenRimandaAppuntamento);
  }
let emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage) -1;
    if (emptyRows>5){emptyRows=3}

    if (NewVerifica){
        const urlRedirect="/nuovaverifica";
        return <Redirect to={urlRedirect} />;
    }

    if (ModVerifica){
        const urlRedirect="/modificaverifica";
        localStorage.setItem("idazienda", SelIdAzienda);
        localStorage.setItem("idverifica", SelIdVerifica);
        localStorage.setItem("tipoverifica", SelTipoVerifica);
        localStorage.setItem("idimpianto", SelIdImpianto);
        return <Redirect to={urlRedirect} />;
    }
    
    let IDIspettore=GetIDIspettore(localStorage.getItem("token"));
    if (parseFloat(IDIspettore)===0){
        IDIspettore="";
    }
    
  return (  
    
    <div className={classes.root}>
        {IsOpenModAppuntamento && <ModAppuntamentoVerifica onUpdate={onLoadData} handleClose={toggleModAppuntamento} id={SelIdVerifica}/>}
        {IsOpenRimandaAppuntamento && <RimandaAppuntamentoVerifica onUpdate={onLoadData} handleClose={toggleRimandaAppuntamento} id={SelIdVerifica}/>}
        <OperationDialog 
            classes={classes} 
            onClose={onCloseOperDialog} 
            onSelected={onSelectedItemOperDialog} 
            isAdmin={isAdmin} 
            open={OpenOperDialog} 
            fileNameVerbale={SelVerbaleFileName} 
            fileNameRapporto={SelRapportoFileName} 
            Rimandato={SelRimandato} 
            DataAppuntamento={SelDataAppuntamento}
        />
        <MyAlert message={MsgAlert} severity="warning" handleClose={onCloseAlert} vertical="top" horizontal="right" open={OpenAlert}/>
        {isLoading ? ( <CircularProgress className="sp-center" /> ) : (<span></span>) }
        <div className="small" style={{width:"98%"}}>
            <Row>
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label for="selRicNumRecord">Ultime Verifiche</Label>
                        <Input type="select" className="search" name="selRicNumRecord" 
                        id="selRicNumRecord" placeholder="" bsSize="sm"
                            value={RicNumRecord} onChange={handleRicNumRecord}>
                                <option key ="100" value="100">100</option>
                                <option key ="250" value="250">250</option>
                                <option key ="500" value="500">500</option>
                                <option key ="750" value="750">750</option>
                                <option key ="1000" value="1000">1000</option>
                                <option key ="2000" value="2000">2000</option>
                                <option key ="3000" value="3000">3000</option>
                                <option key ="5000" value="5000">5000</option>
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={3} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicStato">Stato</Label>
                        <Input type="select" className="search" name="selRicStato" 
                        id="selRicStato" placeholder="" bsSize="sm"
                            value={RicStato} onChange={handleRicStato}>
                                <option key="Tutti" value="">Tutti</option>
                                {GetItemStatoVerificaCert().map((item) => (
                                    <option key={item.stato_value} value={item.stato_value}>{item.stato_desc}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={6} lg={7} md={6} xs={12}>
                    <FormGroup>
                        <Label for="txtRicAzienda">Azienda</Label>
                        <MyAutoComplete id="txtRicAzienda" name="txtRicAzienda" 
                        url={process.env.REACT_APP_API_URL+'customersinglelist'}
                        optionname="RagioneSociale" optionvalue="id"
                        customvalue={RicAzienda} onChange={handleRicAzienda} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicTipoAmbiente">Tipo Ambiente</Label>
                        <MyAutoComplete 
                            id="txtRicTipoAmbiente" 
                            name="txtRicTipoAmbiente" 
                            url={process.env.REACT_APP_API_URL+'tipoambientiimplist'} 
                            optionname="Descrizione" 
                            optionvalue="Codice"
                            customvalue={RicTipoAmbiente} 
                            onChange={handleRicTipoAmbiente}
                            className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                {
                    (UserRole===Ruolo_Admin) && (
                    <Col xl={4} lg={5} md={4} xs={12}>
                        <FormGroup>
                        <Label for="txtRicIspettore">Ispettore</Label>
                            <MyAutoComplete id="txtRicIspettore" name="txtRicIspettore" 
                            url={process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                            optionname="DesIspettore" optionvalue="id"
                            customvalue={RicIspettore} onChange={handleRicIspettore} 
                            className="MuiAutocomplete-inputsm-search"/>
                            <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                        </FormGroup>
                    </Col>
                    )
                }
                <Col xl={6} lg={7} md={8} xs={12}>
                    <FormGroup>
                        <Label for="txtRicImpianto">Impianto</Label>
                        <MyAutoComplete id="txtRicImpianto" name="txtRicImpianto" 
                        url={process.env.REACT_APP_API_URL+'impiantoeldescsinglelist'}
                        optionname="Descrizione" optionvalue="id"
                        customvalue={RicImpianto} onChange={handleRicImpianto} 
                        className="MuiAutocomplete-inputsm-search"/>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={4} lg={5} md={4} xs={12}>
                    <FormGroup>
                        <Row>
                           <Col md="6">
                                <label>
                                <input type="radio" name="radioRicLuogo" value="0" onClick={handleRicTipoLuogo} />
                                    &nbsp;Provincia
                                </label>
                           </Col> 
                           <Col md="6">
                            <label>
                                <input type="radio" name="radioRicLuogo" value="1" onClick={handleRicTipoLuogo}/>
                                &nbsp;Regione
                            </label>
                           </Col> 
                        </Row>
                        <MyAutoComplete id="txtRicLuogo" name="txtRicLuogo" 
                            url={RicUrlLuogo} 
                            optionname={RicOptionName} optionvalue={RicOptionValue}
                            customvalue={RicTextLuogo} 
                            onChange={handleRicLuogo} 
                            className="MuiAutocomplete-inputsm-search"
                        />
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataVerificaInizio">Da Data</Label>
                        <MyDatePicker name="txtRicDataVerificaInizio" className="form-control-sm"
                            value={RicDataVerificaInizio} onChange={handleRicDataVerificaInizio} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col>
                <Col xl={2} lg={4} md={3} xs={12}>
                    <FormGroup>
                        <Label for="txtRicDataVerificaFine">A Data</Label>
                        <MyDatePicker name="txtRicDataVerificaFine" className="form-control-sm" 
                            value={RicDataVerificaFine} onChange={handleRicDataVerificaFine} autoComplete="new-password"
                            />
                    </FormGroup>
                </Col> 
                <Col xl={2} lg={3} md={4} xs={12}>
                    <FormGroup>
                        <Label for="selRicTipoVerifica">Tipo</Label>
                        <Input type="select" className="search" name="selRicTipoVerifica" 
                        id="selRicTipoVerifica" placeholder="" bsSize="sm"
                            value={RicTipoVerifica} onChange={handleRicTipoVerifica}>
                                <option key="Tutti" value="">Tutti</option>
                                    {GetItemTipoVerificaEstesa().map((item) => (
                                    <option key={item.tipo_value} value={item.tipo_value}>{item.tipo_desc}</option>
                                ))}
                        </Input>
                        <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                    </FormGroup>
                </Col>
                <Col xl={2} lg={2} md={2} xs={12}>
                    <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button type="button" className="btn-mbg-cert btn-sm btn-block" disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                    </FormGroup>
                </Col>
            </Row>
        </div>
        <div>&nbsp;</div>
        <Paper className={classes.paper}> 
        <Toolbar>
            <Row>
                <Col xs={6} md={4} lg={2} xl={1}><ExportToExcel data={data}/></Col>
            </Row>
        </Toolbar> 
        <TableContainer>  
            <Table className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table" >  
            <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rowsPerPage}
                        />  
            <TableBody>  
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <TableRow
                                hover
                                tabIndex={-1}
                                key={`row-${index}`}
                                className="rt-tr-group"
                            >
                                <TableCell scope="row">
                                    <Avatar className={classes.BtnEdit}  onClick={() => toggleOperDialog(row.id,row.TipologiaVerifica,row.Azienda_id,row.Impianto_id,row.FileVerbale_Name,row.FileRapporto_Name,row.NumRapporto,row.DataRapporto,row.DataAppuntamento,row.Rimandato)}>
                                        <MoreVertIcon />
                                    </Avatar>
                                </TableCell>
                                <TableCell>{row.NumOrdine + " - " + get_years(row.DataOrdine)}</TableCell>
                                <TableCell>{row.NumRapporto}</TableCell>
                                <TableCell>{row.RagioneSociale}</TableCell>
                                <TableCell>{row.DescImpianto}</TableCell>
                                {
                                    UserRole===Ruolo_Admin && (
                                        <TableCell>{row.DesIspettore}</TableCell>
                                    )
                                }
                                <TableCell>{row.DataVerifica}</TableCell>
                                <TableCell>{GetTextStatoVerificaCert(
                                                               row.Approvato,
                                                               row.DataVerifica,
                                                               row.DataAppuntamento,
                                                               row.Rimandato,
                                                               row.Annullato)}</TableCell>
                            </TableRow>
                        );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                        <TableCell colSpan={UserRole===Ruolo_Admin ? 9 : 8} />
                    </TableRow>
                )}
            </TableBody>  
            </Table>  
        </TableContainer>  
        <TablePagination  
            rowsPerPageOptions={[20, 40, 60]}  
            component="div"  
            count={data.length}  
            rowsPerPage={rowsPerPage}  
            page={page}  
            onChangePage={handleChangePage}  
            onChangeRowsPerPage={handleChangeRowsPerPage}  
        />  
        </Paper>
        <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Riduci"
            />
    </div>  
  );  
} 