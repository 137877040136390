import React, {useState,useRef} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyAlert from '../MyAlert';
import {GetMessage} from "../../Modules/Message";
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {VerifyPDF,GetToken} from "../../Modules/Funzioni";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
    table: {
        minWidth: 750,
    },
    container: {
        maxHeight: 400,
        minHeight: 400,
      },
    FormPopup: {
        maxWidth: "900px !important",
        minWidth: "800px !important",
      },
    
      BtnEdit: {
        color: '#fff',
        backgroundColor:"rgba(18, 35, 95, 0.84) !important",
        '&:hover':{
            cursor:"pointer"
        }  
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function NewModuloNomina (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [Descrizione, setDescrizione] = React.useState("");
    const [FileModulo, setFileModulo] = React.useState("");
    const [FileErr, setFileErr] = React.useState(false);
    
   const handleFile= (event) => {
        const _file = event.target.files[0];
        const msg= VerifyPDF(_file);
        
        if (msg!==""){
            setSeverityAlert("warning")
            setVerticalAlert("center")
            setMsgAlert(msg);
            setOpenAlert(true);
            setFileErr(true);
        }
        else {
            setFileModulo(_file);
            setFileErr(false);
        }
        
    }

    const handleDescrizione = (event) => {
        setDescrizione(event.target.value);
    }; 
     
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  
    
    const submitForm= (event) => 
    {
        if (! FileErr) {
            if (validator.current.allValid()) {
                setisSubmit(true);
                const fd = new FormData();
                fd.append("Descrizione", Descrizione);
                
                fd.append('modulo', FileModulo);
                    const token = GetToken(localStorage.getItem("token"));
                        const ax= axios;
                        ax.defaults.headers.common['Authorization']="Bearer " + token;
                        const axiosConfig = {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                        
                        ax
                        .post(process.env.REACT_APP_API_URL+'modulonominainsert', fd,axiosConfig)
                        .then((response) => {
                            
                            if (response.status === 200) {
                                if (response.data.error==="OK"){
                                    props.handleClose();
                                    props.onInsert();
                                }
                                else {
                                    let msg="";
                                    let ris=JSON.stringify(response.data);
                                    ris=ris.replaceAll("\"", "");
                                    ris=ris.replaceAll("{", "");
                                    ris=ris.replaceAll("}", "");
                                    ris=ris.replaceAll("[", "");
                                    ris=ris.replaceAll("]", "");
                                    msg="errore durante la modifica! " + ris;
                                    setSeverityAlert("error")
                                    setVerticalAlert("top")
                                    setMsgAlert(msg);
                                    setOpenAlert(true);
                                }
                            }
                            else
                            { 
                            const msg=GetMessage(response.status,response.data.message);
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                            }
                        })
                        .catch((error) => {
                            let msg=""
                            if (error.response!==undefined) {
                                msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                            }
                            else
                            {
                                msg=GetMessage(undefined,"");
                            }
                            setSeverityAlert("error")
                            setVerticalAlert("top")
                            setMsgAlert(msg);
                            setOpenAlert(true);
                            
                        });
                        setisSubmit(false);

            } else {
                validator.current.showMessages();
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(MsgAlertDefault);
                //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
                setOpenAlert(true);
            }
        }
        else
        {
            setSeverityAlert("warning")
            setVerticalAlert("top")
            setMsgAlert("File selezionato non corretto, scegliere un altro file!");
            setOpenAlert(true);
        }
    }
 
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={classes.bgHead}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Inserisci Modulo
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        <form autoComplete="off">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtDescServizio">Descrizione *</Label>
                                                    <Input type="text" name="txtDescrizione" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Descrizione')}
                                                    value={Descrizione} onChange={handleDescrizione}
                                                    />
                                                    {validator.current.message('Descrizione', Descrizione, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtFileModulo">&nbsp;</Label>
                                                    <Input type="file" name="txtFileModulo" autoComplete="new-password"  onChange={handleFile} placeholder=""
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row> 
                                    </Paper>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}