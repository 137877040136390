import React, {useState,useRef,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {FormGroup, Label,Col,Input,Row} from "reactstrap";
import MyDatePicker from './MyDatePicker';
import MyAutoComplete from './MyAutoComplete';
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import axios from 'axios';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../css/Form.css';
import SimpleReactValidator from 'simple-react-validator';
import {GetToken} from "../Modules/Funzioni";
import {app_cpService} from "../Modules/Costanti";
import imgSearch from "../img/search.png";
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'fixed',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    bgHead: {
        backgroundColor: "#011e87 !important",
    },
    paper: {
        padding: theme.spacing(0.7),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      },
}));

  
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function NewCampaign (props) {
    const classes = useStyles();
    const MsgAlertDefault="Per confermare l'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    //const [ValueForceUpdate, setValueForceUpdate] = useState(0); // integer state
    const [isSubmit, setisSubmit] = React.useState(false);
    const [ForceUpdate, setForceUpdate] = React.useState(false);
    const [Rischio, setRischio] = React.useState("0");
    const [UtenzaAttiva, setUtenzaAttiva] = React.useState("0");
    const [OrdinePresente, setOrdinePresente] = React.useState("0");
    const [Description, setDescription] = React.useState("");
    const [Subject, setSubject] = React.useState("");
    const [SendDate, setSendDate] = React.useState("");
    const [IDAttachment1, setIDAttachment1] = React.useState("");
    const [DesAttachment1, setDesAttachment1] = React.useState("");
    const [IDAttachment2, setIDAttachment2] = React.useState("");
    const [DesAttachment2, setDesAttachment2] = React.useState("");
    const [DesBody, setDesBody] = React.useState("");
    const [DisableFilter, setDisableFilter] = useState(false);
    const [data, setData] = useState([]);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [VerticalAlert, setVerticalAlert] = React.useState("top");
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [isLoading, setIsLoading] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    
    const handleAttachment1 = (value,text) => {  
        setIDAttachment1(value); 
        setDesAttachment1(text); 
    };
    const handleAttachment2 = (value,text) => {  
        setIDAttachment2(value); 
        setDesAttachment2(text); 
    };
    const handleSendDate = (value) => {  
        setSendDate(value);  
    };
    const handleDesBody = (event) => {  
        setDesBody(event.target.value);  
    }; 
    const handleDescription = (event) => {  
        setDescription(event.target.value);  
    }; 
    const handleSubject = (event) => {  
        setSubject(event.target.value);  
    }; 
    const handleRischio = (event) => {
        setRischio(event.target.value);
        setDisableFilter(false);
    };
    const handleUtenzaAttiva = (event) => {
        setUtenzaAttiva(event.target.value);
        setDisableFilter(false);
    };
    const onFilter = () => {
        setDisableFilter(true);
        setIsFilter(true);
    }; 
    const handleOrdinePresente = (event) => {
        setOrdinePresente(event.target.value);
        setDisableFilter(false);
    };
    const validator = useRef(new SimpleReactValidator({
        messages: {
            default: 'obbligatorio o non corretto!'
          },
          validators: {
            cphone: {  // name the rule
              //message: 'obbligatorio o non corretto',
              rule: (val, params, validator) => {
                return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
              },
              //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
              //required: true  // optional
            }
          }
    }));  

    useEffect(() => {   
        if (isFilter){
            setIsLoading(true);
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            (async () => {
                let result=[];
                try{
                    let url=process.env.REACT_APP_API_URL+'customercampaignlist'
                    const axiosConfig = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                    const fd = new FormData();
                    fd.append("UtenzaAttiva", UtenzaAttiva);
                    fd.append("OrdinePresente", OrdinePresente);
                    fd.append("Rischio", Rischio);
                    
                    //idazienda/0/codfiscale/0/cognome/0
                    result = await ax.post(url,fd,axiosConfig);
                }
                catch(error) {
                    let msg=""
                    if (error.response!==undefined) {
                        msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                    }
                    else
                    {
                        msg=GetMessage(undefined,"");
                    }
                    setMsgAlert(msg);
                    setOpenAlert(true);
    
                }
                setIsLoading(false);    
                if (result.data!==undefined)    {
                    setData(result.data);
                }
                else {
                    setData([]);
                }
            })();
            setIsFilter(false); 
        }
        
        
    }, [isFilter]);   

    const submitForm= (event) => 
    {
        if (isSubmit) return;
        if (data.length===0){
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert("Per confermare è necessario eseguire la ricerca!");
            setOpenAlert(true);
            return;
        }
        if (validator.current.allValid()) {
            setisSubmit(true);
            const fd = new FormData();
            let ParamCustomer="";
            ParamCustomer =data.map(function(item) {
                return item.Customer_id + "|" + item.DoNotSend + "|" + item.Email;
            });
            fd.append("ParamCustomer", ParamCustomer);
            fd.append("Description", Description);
            fd.append("SendDate", SendDate);
            fd.append("Subject", Subject);
            fd.append("Body", DesBody);
            fd.append("Attachment_id", IDAttachment1);
            fd.append("Attachment2_id", IDAttachment2);
            fd.append("Rischio", Rischio);
            fd.append("State", 0);
            fd.append("UtenzaAttiva", UtenzaAttiva);
            fd.append("OrdinePresente", OrdinePresente);
            
            const token = GetToken(localStorage.getItem("token"));
            const ax= axios;
            ax.defaults.headers.common['Authorization']="Bearer " + token;
            const axiosConfig = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
                    
            ax
            .post(process.env.REACT_APP_API_URL+'campaigninsert', fd,axiosConfig)
            .then((response) => {
                
                if (response.status === 200) {
                    if (response.data.error==="OK"){
                        props.handleClose();
                        props.onInsert();
                    }
                    else {
                        let msg="";
                        let ris=JSON.stringify(response.data);
                        ris=ris.replaceAll("\"", "");
                        ris=ris.replaceAll("{", "");
                        ris=ris.replaceAll("}", "");
                        ris=ris.replaceAll("[", "");
                        ris=ris.replaceAll("]", "");
                        msg="errore durante l'inserimento! " + ris;
                        setSeverityAlert("error")
                        setVerticalAlert("top")
                        setMsgAlert(msg);
                        setOpenAlert(true);
                    }
                }
                else
                { 
                const msg=GetMessage(response.status,response.data.message);
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
                }
            })
            .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setVerticalAlert("top")
                setMsgAlert(msg);
                setOpenAlert(true);
                
            });
            setisSubmit(false);

        } else {
            validator.current.showMessages();
            setSeverityAlert("error")
            setVerticalAlert("top")
            setMsgAlert(MsgAlertDefault);
            //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
            setOpenAlert(true);
        }
    };
    const handleEscludeRowClick = (value,_id) => {
        if (data.length>0){
            let _data=[];
            data.map((item) => {
              if (item.Customer_id===_id){
                  item.DoNotSend=value? 1:0;
              }
              _data=_data.concat(item);
            });
            setData(_data);
            setForceUpdate(!ForceUpdate)
        }
    };

    let ButtonClassName="";
    let HeadClassName="";
    const app_selected =process.env.REACT_APP_APPSELECT;
    if (parseInt(app_selected)===app_cpService) {
        ButtonClassName="btn-mbg";
        HeadClassName="bg-head-form";
    }
    else{
        ButtonClassName ="btn-mbg-cert";
        HeadClassName ="bg-head-form-cert";
    }
    return (
        <div>
            <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical={VerticalAlert} horizontal="right" open={OpenAlert}/>     
            <Dialog fullScreen open={true} onClose={props.handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar className={HeadClassName}>
                        <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Inserisci Campagna Marketing
                        </Typography>
                        <Button autoFocus color="inherit" onClick={submitForm}>
                            Salva {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                        </Button>
                    </Toolbar>
                </AppBar>
                <div className="page-container bg-form">
                    <div className="content-wrap">
                        <form autoComplete="off">
                            <Grid container spacing={2} className="custom-form">
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtDescription">Descrizione *</Label>
                                                    <Input type="text" name="txtDescription" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Description')}
                                                    value={Description} onChange={handleDescription}
                                                    />
                                                    {validator.current.message('Description', Description, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtAttachment1">Allegato 1</Label>
                                                    <MyAutoComplete id="txtAttachment1" name="txtAttachment1" 
                                                        url={process.env.REACT_APP_API_URL+'allegatoemailsinglelist'} 
                                                        optionname="Descrizione" optionvalue="id"
                                                        onBlur={() => validator.current.showMessageFor('Attachment1')}
                                                        customvalue={DesAttachment1} onChange={handleAttachment1} 
                                                    
                                                        />
                                                    {validator.current.message('Attachment1', IDAttachment1, 'alpha_num', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtAttachment2">Allegato 2</Label>
                                                    <MyAutoComplete id="txtAttachment2" name="txtAttachment2" 
                                                        url={process.env.REACT_APP_API_URL+'allegatoemailsinglelist'} 
                                                        optionname="Descrizione" optionvalue="id"
                                                        onBlur={() => validator.current.showMessageFor('Attachment2')}
                                                        customvalue={DesAttachment2} onChange={handleAttachment2} 
                                                    
                                                        />
                                                    {validator.current.message('Attachment2', IDAttachment2, 'alpha_num', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={8}>
                                                <FormGroup>
                                                    <Label for="txtsubject">Oggetto Email *</Label>
                                                    <Input type="text" name="txtsubject" autoComplete="new-password" placeholder="" 
                                                    onBlur={() => validator.current.showMessageFor('Subject')}
                                                    value={Subject} onChange={handleSubject}
                                                    />
                                                    {validator.current.message('Subject', Subject, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <Label for="txtDataInvio">D. Invio *</Label>
                                                    <MyDatePicker name="txtDataInvio" 
                                                    onBlur={() => validator.current.showMessageFor('SendDate')}
                                                    value={SendDate} onChange={handleSendDate} autoComplete="new-password"
                                                    />
                                                    {validator.current.message('SendDate', SendDate, 'required|data', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="txtBody">Testo Email * [max 1500 caratteri]</Label>
                                                    <Input type="textarea" name="txtBody" id="txtBody" autoComplete="new-password"
                                                    onBlur={() => validator.current.showMessageFor('DesBody')}
                                                    value={DesBody} onChange={handleDesBody} maxLength="1500"
                                                    />
                                                    {validator.current.message('DesBody', DesBody, 'required|string', { className: 'text-danger' })}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        <Row>
                                            <Col md={3}>
                                                <FormGroup>
                                                    <Label for="selRicRischio">Rischio</Label>
                                                    <Input type="select" className="search" name="selRicRischio" 
                                                    id="selRicRischio" placeholder="" bsSize="sm"
                                                        value={Rischio} onChange={handleRischio}>
                                                            <option key="0" value="0">Indifferente</option>
                                                            <option key="1" value="1">Alto</option>
                                                            <option key="2" value="2">Medio</option>
                                                            <option key="3" value="3">Basso</option>
                                                    </Input>
                                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                </FormGroup>
                                            </Col> 
                                            <Col md={3}>
                                                <FormGroup>
                                                <Label for="selRicUtenzaAttiva">Utenza Attiva</Label>
                                                    <Input type="select" className="search" name="selRicUtenzaAttiva" 
                                                    id="selRicUtenzaAttiva" placeholder="" bsSize="sm"
                                                        value={UtenzaAttiva} onChange={handleUtenzaAttiva}>
                                                            <option key="0" value="0">Indifferente</option>
                                                            <option key="2" value="2">Si</option>
                                                            <option key="1" value="1">No</option>
                                                    </Input>
                                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={3}>
                                                <FormGroup>
                                                <Label for="selRicOrdiniPresenti">Ordini Presenti</Label>
                                                    <Input type="select" className="search" name="selRicOrdiniPresenti" 
                                                    id="selRicOrdiniPresenti" placeholder="" bsSize="sm"
                                                        value={OrdinePresente} onChange={handleOrdinePresente}>
                                                            <option key="0" value="0">Indifferente</option>
                                                            <option key="2" value="2">Si</option>
                                                            <option key="1" value="1">No</option>
                                                    </Input>
                                                    <img style={{height:"15pt"}} src={imgSearch} className="input-icon" alt=""/>
                                                </FormGroup>
                                            </Col>
                                            <Col md={2}>
                                                <FormGroup>
                                                    <Label>&nbsp;</Label>
                                                    <Button type="button" className={ButtonClassName + " btn-sm btn-block"} disabled={DisableFilter} onClick={onFilter} >Ricerca</Button>
                                                </FormGroup>
                                            </Col>
                                            <Col md={1}>
                                                <FormGroup>
                                                    <Label>Totale</Label>
                                                    <Input type="text" name="txtTotal" autoComplete="new-password" placeholder="" 
                                                    value={data.length} 
                                                    readOnly disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>  
                                    {isLoading && ( <CircularProgress className="sp-center" size={150} />)}         
                                    <TableContainer component={Paper} className={classes.container}>
                                        <Table stickyHeader className={classes.table} aria-label="spanning table" size="small">
                                            <TableHead>            
                                                <TableRow>
                                                    <TableCell className="grid-head" align="center">Escludi</TableCell>
                                                    <TableCell className="grid-head" align="center">Ragione Sociale</TableCell>
                                                    <TableCell className="grid-head" align="center">Email</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                { data.length>0? (
                                                    data.map((row) => {
                                                        const isItemSelected = row.DoNotSend;
                                                        const labelId = `row-${row.id}`;
                                                        
                                                        return (
                                                            
                                                        <TableRow key={row.id} hover tabIndex={-1} onClick={(event) => handleEscludeRowClick(!isItemSelected,row.Customer_id)}>
                                                            <TableCell>
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                    style={{transform: "scale(1.2)"}}
                                                                />
                                                            </TableCell>
                                                            <TableCell>{row.RagioneSociale}</TableCell>
                                                            <TableCell>{row.Email}</TableCell>
                                                        </TableRow>
                                                        );
                                                    })
                                                ):(
                                                    <TableRow>
                                                        <TableCell colSpan={3} style={{textAlign:"center"}}><h7>- Clienti non trovati -</h7></TableCell>
                                                    </TableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}
        