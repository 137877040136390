import React, {useRef} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import {FormGroup, Label,Col,Input,Row,Button} from "reactstrap";
import MyAlert from './MyAlert';
import {GetMessage} from "../Modules/Message";
import {GetToken} from "../Modules/Funzioni";
import { app_cpCert, app_cpService } from "../Modules/Costanti";
import axios from 'axios';
import SimpleReactValidator from 'simple-react-validator';
import '../css/Form.css';
import MyAutoComplete from './MyAutoComplete';
export default function InsUser(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const MsgAlertDefault="Per confermare l\'inserimento controllare i campi obbligatori e correggere eventuali errori!";
    const [isSubmit, setisSubmit] = React.useState(false);
    //alert object
    const [OpenAlert, setOpenAlert] = React.useState(false);
    const [MsgAlert,setMsgAlert]=React.useState(MsgAlertDefault);
    const onCloseAlert = (event) => {
        setOpenAlert(false);
    };
    //------------
    const [SeverityAlert, setSeverityAlert] = React.useState("error");
    const [Name, setName] = React.useState("");
    const [Email, setEmail] = React.useState("");
    const [RiceviEmail, setRiceviEmail] = React.useState("0");
    const [IdCommerciale, setIdCommerciale] = React.useState("");
    const [DesCommerciale, setDesCommerciale] = React.useState("");
    const [IdIspettore, setIdIspettore] = React.useState("");
    const [DesIspettore, setDesIspettore] = React.useState("");
    const [IdIspettoreEmulato, setIdIspettoreEmulato] = React.useState("");
    const [DesIspettoreEmulato, setDesIspettoreEmulato] = React.useState("");
    const handleName = (event) => {  
        setName(event.target.value);  
    }; 
    const handleEmail = (event) => {  
        setEmail(event.target.value); 
    }; 
    const handleRiceviEmail = (event) => {  
        setRiceviEmail(event.target.value); 
    }; 
    const handleCommerciale = (value,text) => {
        setIdCommerciale(value);
        setDesCommerciale(text);  
    }; 
    const handleIspettore = (value,text) => {
        setIdIspettore(value);
        setDesIspettore(text);  
    }; 
    const handleIspettoreEmulato = (value,text) => {
        setIdIspettoreEmulato(value);
        setDesIspettoreEmulato(text);  
    };

const validator = useRef(new SimpleReactValidator({
    messages: {
        default: 'obbligatorio o non corretto!'
      },
      validators: {
        cphone: {  // name the rule
          //message: 'obbligatorio o non corretto',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val,/^[0-9]{2,5}-[0-9]{5,10}$/i) && params.indexOf(val) === -1
          },
          //messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),  // optional
          //required: true  // optional
        }
      }
}));  

const submitForm= (event) => 
{
    if (validator.current.allValid()) {
        setisSubmit(true);
        const fd = new FormData();
        fd.append("name", Name);
        fd.append("Email", Email);
        fd.append("RiceviEmail", RiceviEmail);
        fd.append("Commerciale_id", IdCommerciale);
        fd.append("Ispettore_id", IdIspettore);
        fd.append("EmulazioneIspettore_id", IdIspettoreEmulato);

        const token = GetToken(localStorage.getItem("token"));
        const ax= axios;
        ax.defaults.headers.common['Authorization']="Bearer " + token;
        const axiosConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
            
        ax
        
        .post(process.env.REACT_APP_API_URL+'userinsert', fd,axiosConfig)
        .then((response) => {
            
            
            if (response.status === 200) {
                if (response.data.error==="OK"){
                    props.handleClose();
                    props.onInsert();
                }
                else {
                    let msg="";
                    let ris=JSON.stringify(response.data);
                    ris=ris.replaceAll("\"", "");
                    ris=ris.replaceAll("{", "");
                    ris=ris.replaceAll("}", "");
                    ris=ris.replaceAll("[", "");
                    ris=ris.replaceAll("]", "");
                    msg="errore durante l\'inserimento! " + ris;
                    setSeverityAlert("error");
                    setMsgAlert(msg);
                    setOpenAlert(true);
                }
            }
            else
            { 
            const msg=GetMessage(response.status,response.data.message);
            setSeverityAlert("error")
            setMsgAlert(msg);
            setOpenAlert(true);
            }
        })
        .catch((error) => {
                let msg=""
                if (error.response!==undefined) {
                    msg=GetMessage(error.response.status,error.response.data.message || error.response.message);
                }
                else
                {
                    msg=GetMessage(undefined,"");
                }
                setSeverityAlert("error")
                setMsgAlert(msg);
                setOpenAlert(true);
            });
            setisSubmit(false);
    } else {
        validator.current.showMessages();
        setSeverityAlert("error")
        setMsgAlert(MsgAlertDefault);
        //setValueForceUpdate(ValueForceUpdate => ++ValueForceUpdate);
        setOpenAlert(true);
    }
}
let ButtonClassName="";
const app_selected =process.env.REACT_APP_APPSELECT;
if (parseInt(app_selected)===app_cpService) {
    ButtonClassName="btn-mbg";
}
else{
    ButtonClassName ="btn-mbg-cert";
}
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={true} 
        onClose={props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Inserimento Utente</DialogTitle>
        <DialogContent className="form-popup">
          <DialogContentText>
          <MyAlert message={MsgAlert} severity={SeverityAlert} handleClose={onCloseAlert} vertical="top" horizontal="center" open={OpenAlert}/>  
            <Row md={12}>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtName">Nome *</Label>
                        <Input type="text" name="txtName" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('Name')}
                        value={Name} onChange={handleName}
                        />
                        {validator.current.message('Name', Name, 'required|string', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label for="txtEmail">Email *</Label>
                        <Input type="email" name="txtEmail" autoComplete="new-password" placeholder=""
                        onBlur={() => validator.current.showMessageFor('Email')}
                        value={Email} onChange={handleEmail}
                        />
                        {validator.current.message('Email', Email, 'required|email', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormGroup>
                        <Label for="selRiceviEmail">Ricevi Email Alert *</Label>
                        <Input type="select" name="selRiceviEmail" id="selRiceviEmail" placeholder=""
                        onBlur={() => validator.current.showMessageFor('RiceviEmail')}
                        value={RiceviEmail} onChange={handleRiceviEmail}
                        >
                            <option key="0" value="0">No</option>
                            <option key="1" value="1">Si</option>
                        </Input>
                        {validator.current.message('RiceviEmail', RiceviEmail, 'required|numeric', { className: 'text-danger' })}
                    </FormGroup>
                </Col>
                <Col md={8}>
                    <FormGroup>
                        <Label for="txtCommerciale">Commerciale</Label>
                        <MyAutoComplete id="txtCommerciale" name="txtCommerciale" 
                            url={process.env.REACT_APP_API_URL+'commercialesinglelist'} 
                            optionname="DesCommerciale" optionvalue="id"
                            customvalue={DesCommerciale} onChange={handleCommerciale}
                            />
                    </FormGroup>
                </Col>
                {
                    parseInt(app_selected)===app_cpCert && (
                        <>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="txtIspettore">Ispettore</Label>
                                <MyAutoComplete id="txtIspettore" name="txtIspettore" 
                                    url={process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                    optionname="DesIspettore" optionvalue="id"
                                    customvalue={DesIspettore} onChange={handleIspettore}
                                    />
                            </FormGroup>
                        </Col>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="txtIspettoreEmulato">Emulazione Ispettore</Label>
                                <MyAutoComplete id="txtIspettoreEmulato" name="txtIspettoreEmulato" 
                                    url={process.env.REACT_APP_API_URL+'ispettoresinglelist'} 
                                    optionname="DesIspettore" optionvalue="id"
                                    customvalue={DesIspettoreEmulato} onChange={handleIspettoreEmulato}
                                    />
                            </FormGroup>
                        </Col>
                        </>
                    )
                }
                
            </Row> 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <div style={{width:"50%"}}>
                <Button  onClick={submitForm} className={ButtonClassName + " btn-block"}>
                    Conferma {isSubmit ? ( <span className="spinner-border spinner-border-sm ml-5" role="status" aria-hidden="true"></span> ) : (<span></span>) }
                </Button>
            </div>
            <div style={{width:"50%"}}>
                    
                <Button onClick={props.handleClose} className="btn-block">
                    Annulla
                </Button>

            </div>            
        </DialogActions>
      </Dialog>
    </div>
  );
}